import { useContext } from "react";

import { Button, WizardStepperContext, Icons } from "@unchained/component-library";

type StepHeaderProps = {
  isBackButtonShowing: boolean;
  title: string;
  className?: string;
};
export const StepHeader = ({ isBackButtonShowing = false, title, className }: StepHeaderProps) => {
  const { goToPrev } = useContext(WizardStepperContext);
  return (
    <div className={className}>
      {isBackButtonShowing && (
        <Button
          startIcon={<Icons.ChevronLeft />}
          className="mb-2 mt-4"
          variant="text"
          onClick={goToPrev}
        >
          Back
        </Button>
      )}
      <p className="text-d-sm font-med text-gray-900">{title}</p>
    </div>
  );
};
