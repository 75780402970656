import { useState } from "react";

import { AddressElement, Elements, useElements } from "@stripe/react-stripe-js";

import { countryRegionData } from "Components/FormFields";
import { StepColumn } from "Components/Shared/Layouts/FullPageWizard";
import { useTriggerFirstReadyStep } from "Components/Shared/Layouts/FullPageWizard/utils";
import { useLoadingContext } from "Contexts/LoadingContext";
import { CheckoutHeader } from "Routes/inheritance/(shared)/CheckoutHeader";
import { Invoice } from "Shared/api/v2";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { camelCaseKeys } from "Utils/objects";
import { stripeShippingCountryCodes, useStripeElementProps } from "Utils/stripe";

import { GetSetObj } from "../(utils)";

export interface ShippingInfo {
  address: {
    line1: string;
    line2?: string;
    line3?: string;
    city: string;
    state: string;
    zip: string;
    country: string;
  };
  name: string;
}

const ShippingAddressInsideElements = ({
  org,
  shippingInfo,
}: {
  org: CompleteOrg;
  shippingInfo: GetSetObj<ShippingInfo | undefined>;
}) => {
  const elements = useElements();
  const triggerNext = useTriggerFirstReadyStep();
  const loading = useLoadingContext();
  const [isInputValid, setIsInputValid] = useState(false);

  // Prefill shipping address by default using personal org person physical address
  const prefilledAddress = camelCaseKeys(org.person?.physical_address || {});
  // need to map country name to 2-letter country code
  const defaultCountryCode =
    !!prefilledAddress.country && countryRegionData.find(v => v[0] === prefilledAddress.country)[1];
  const addressWithCountryCode = defaultCountryCode
    ? { ...prefilledAddress, country: defaultCountryCode }
    : prefilledAddress;

  const shipping = shippingInfo?.value || { address: addressWithCountryCode, name: "" };

  const handleChange = event => {
    setIsInputValid(event.complete);
  };

  const handleNext = async () => {
    try {
      const addressElement = elements.getElement("address");
      const { complete, value } = await addressElement.getValue();

      if (complete) {
        shippingInfo
          .set({
            address: {
              line1: value.address?.line1,
              line2: value.address?.line2 ?? "",
              city: value.address?.city,
              state: value.address?.state,
              zip: value.address?.postal_code,
              country: value.address?.country,
            },
            name: value.name,
          })
          .then(triggerNext);
      }
    } catch (err) {
      // This error can be silently ignored
    }
  };

  return (
    <div className="flex max-h-screen max-w-3xl">
      <StepColumn
        className="md:w-[500px]"
        loading={loading.value}
        width="full"
        actions={[
          {
            children: "Continue",
            onClick: () => handleNext(),
            disabled: !isInputValid,
          },
        ]}
      >
        <CheckoutHeader title="Shipping address" subtitle="What is your shipping address?" />
        <AddressElement
          onChange={handleChange}
          options={{
            allowedCountries: stripeShippingCountryCodes,
            mode: "shipping",
            blockPoBox: true,
            defaultValues: {
              address: {
                line1: shipping.address.line1,
                line2: shipping.address.line2,
                city: shipping.address.city,
                state: shipping.address.state,
                postal_code: shipping.address.zip,
                country: shipping.address.country, //must be two letter country code
              },
              name: shipping?.name || "",
            },
            fields: { phone: "never" },
          }}
        />
      </StepColumn>
    </div>
  );
};

export const ShippingAddressStep = ({
  org,
  shippingInfo,
}: {
  org?: CompleteOrg;
  shippingInfo: GetSetObj<ShippingInfo | undefined>;
}) => {
  const elementProps = useStripeElementProps(undefined);

  return (
    <Elements {...elementProps}>
      <ShippingAddressInsideElements org={org} shippingInfo={shippingInfo} />
    </Elements>
  );
};
