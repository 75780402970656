import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { ChangeProposalAPI } from "Shared/api/changeProposalApi";
import {
  GetCashBalancesActivity200,
  GetAllPendingCashBalanceWithdrawal200,
  GetACashBalanceWithdrawal200,
  DeleteACashBalanceWithdrawal200,
  AdminGetCashBalanceForOrg200,
} from "Specs/v2";
import {
  CreateCashBalanceWithdrawalRequest,
  CreateCashBalanceWithdrawalResponse,
} from "Specs/v2/components";
import { GetCashBalancesActivityQueryParams } from "Specs/v2/getCashBalancesActivity/params/query";

import { CashBalanceAPI } from "../cashBalance";

export const cashBalanceKeys = {
  getCashBalance: (
    orgUuid: string,
    page: GetCashBalancesActivityQueryParams["page"],
    perPage: GetCashBalancesActivityQueryParams["perPage"],
    sortType: GetCashBalancesActivityQueryParams["sortType"],
    sortDirection?: GetCashBalancesActivityQueryParams["sortDirection"]
  ) => ["getCashBalance", page, perPage, sortType, sortDirection, orgUuid],
  adminGetCashBalance: "adminGetCashBalance",
  adminForceResyncCashBalance: (orgUuid: string) => ["adminForceResyncCashBalance", orgUuid],
  getCashBalanceWithdrawals: "getCashBalanceWithdrawals",
  getCashBalanceWithdrawal: (withdrawalId: string) => ["getCashBalanceWithdrawal", withdrawalId],
  cancelCashBalanceWithdrawal: (withdrawalId: string) => [
    "cancelCashBalanceWithdrawal",
    withdrawalId,
  ],
};
export const useGetCashBalanceQuery = (
  orgUuid: string,
  requestQueryParams: GetCashBalancesActivityQueryParams,
  handleOnSuccess?: (data: GetCashBalancesActivity200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    cashBalanceKeys.getCashBalance(
      orgUuid,
      requestQueryParams.page,
      requestQueryParams.perPage,
      requestQueryParams.sortType,
      requestQueryParams.sortDirection
    ),
    () => CashBalanceAPI.GetCashBalance(orgUuid, requestQueryParams),
    {
      refetchOnWindowFocus: false,
      enabled: !!orgUuid,
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useAdminGetCashBalanceQuery = (
  orgUuid: string,
  isEnabled: boolean,
  handleOnSuccess?: (data: AdminGetCashBalanceForOrg200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    cashBalanceKeys.adminGetCashBalance,
    () => CashBalanceAPI.AdminGetCashBalance(orgUuid),
    {
      refetchOnWindowFocus: false,
      enabled: isEnabled,
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useAdminForceResyncCashBalanceMutation = (
  orgUuid: string,
  handleOnSuccess?: (data: AdminGetCashBalanceForOrg200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    cashBalanceKeys.adminForceResyncCashBalance(orgUuid),
    () => CashBalanceAPI.AdminForceResyncCashBalance(orgUuid),
    {
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
          queryClient.invalidateQueries(cashBalanceKeys.adminGetCashBalance);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useCreateCashWithdrawMutation = (
  orgUuid: string,
  createCashWithdrawBody: CreateCashBalanceWithdrawalRequest,
  handleOnSuccess?: (data: CreateCashBalanceWithdrawalResponse) => void,
  onError?: (err: AxiosError) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => CashBalanceAPI.CreateCashWithdrawal(orgUuid, createCashWithdrawBody), {
    onError: (err: AxiosError) => {
      if (onError) {
        onError(err);
      }
    },
    onSuccess: data => {
      queryClient.invalidateQueries("getCashBalance");
      if (handleOnSuccess) {
        handleOnSuccess(data);
      }
    },
  });
};

export const useGetCashBalanceWithdrawalsQuery = (
  orgUuid: string,
  handleOnSuccess?: (data: GetAllPendingCashBalanceWithdrawal200["data"]) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    cashBalanceKeys.getCashBalanceWithdrawals,
    () => CashBalanceAPI.GetCashBalanceWithdrawals(orgUuid),
    {
      refetchOnWindowFocus: false,
      enabled: !!orgUuid,
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data?.data);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useGetCashBalanceWithdrawalQuery = (
  orgUuid: string,
  withdrawalId: string,
  handleOnSuccess?: (data: GetACashBalanceWithdrawal200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    cashBalanceKeys.getCashBalanceWithdrawal(withdrawalId),
    () => CashBalanceAPI.GetCashBalanceWithdrawal(orgUuid, withdrawalId),
    {
      refetchOnWindowFocus: false,
      enabled: !!orgUuid && !!withdrawalId,
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useCancelCashWithdrawMutation = (
  orgUuid: string,
  withdrawalId: string,
  handleOnSuccess?: (data: DeleteACashBalanceWithdrawal200) => void,
  onError?: (err: AxiosError) => void
) => {
  return useMutation(() => CashBalanceAPI.CancelCashBalanceWithdrawal(orgUuid, withdrawalId), {
    onError: (err: AxiosError) => {
      if (onError) {
        onError(err);
      }
    },
    onSuccess: data => {
      if (handleOnSuccess) {
        handleOnSuccess(data);
      }
    },
  });
};

export const useUpdateCashWithdrawApprovalMutation = (
  orgUuid: string,
  changeProposalGroupId: string,
  handleOnSuccess?: (data: string) => void,
  onError?: (err: AxiosError) => void
) => {
  return useMutation(
    (approvalStatus: "approve" | "deny") => {
      if (approvalStatus === "approve") {
        return ChangeProposalAPI.ApproveChangeProposalGroup(orgUuid, changeProposalGroupId);
      } else {
        return ChangeProposalAPI.RejectChangeProposalGroup(orgUuid, changeProposalGroupId);
      }
    },

    {
      onError: (err: AxiosError) => {
        if (onError) {
          onError(err);
        }
      },
      onSuccess: data => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    }
  );
};
export const useDeleteCashWithdrawApprovalMutation = (
  orgUuid: string,
  changeProposalGroupId: string,
  handleOnSuccess?: (data: string) => void,
  onError?: (err: AxiosError) => void
) => {
  return useMutation(
    () => ChangeProposalAPI.RevokeChangeProposalGroup(orgUuid, changeProposalGroupId),
    {
      onError: (err: AxiosError) => {
        if (onError) {
          onError(err);
        }
      },
      onSuccess: (data: string) => {
        if (handleOnSuccess) {
          handleOnSuccess(data);
        }
      },
    }
  );
};
