import React from "react";

import { Button } from "@unchained/component-library";

type MobileUnavailableProps = {
  onClose: () => void;
};
export const MobileUnavailableStep = ({ onClose }: MobileUnavailableProps) => {
  return (
    <div className="px-6">
      <h2>Selling unavailable on mobile</h2>
      <p className="mb-4.5 text-sm font-reg">
        Selling bitcoin is not available on mobile devices. Please try again from a desktop.
      </p>
      <Button className="!mt-0 !h-12" onClick={onClose} fullWidth={true} color="secondary">
        Close
      </Button>
    </div>
  );
};
