import { RadioButton } from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { Formik, FormikDropdown, FormikRadioGroup, FormikTextField } from "Components/FormFields";
import { employmentIndustries, ManifestStep, SectionHeader } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { usePutPersonEmployment } from "Shared/api";
import { PutPersonEmploymentRequest } from "Specs/v1";
import { IraOrg } from "Specs/v1/getIraOrg/200";
import { CompleteOrg, employmentStatusEnum } from "Specs/v1/getOrg/200";
import { pascalCase, sentenceCase } from "Utils/strings";

import { initEmployment, validateEmployment } from "../../individualOrgValsAndValidations";

export const EmploymentStatus = ({ org }: { org: CompleteOrg | IraOrg }) => {
  let { uuid } = useParams<{ uuid: string }>();
  if (!uuid) uuid = (org as CompleteOrg).uuid;

  const isIra = "iraPlan" in org;
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: uuid, isIra: "iraPlan" in org });

  const person = isIra ? org.entity.principals[0].person : org.person;

  const initVals = initEmployment(org);
  const putPersonEmployment = wrapManifestMutation(usePutPersonEmployment(person.uuid));

  const onSubmit = (employment: typeof initVals) =>
    putPersonEmployment.mutate(employment as PutPersonEmploymentRequest);

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={onSubmit}
      validationSchema={validateEmployment}
      debugName="EmploymentStatus"
    >
      {({ handleSubmit, values }) => {
        const employmentTime = ["employed", "self_employed"].includes(values.status)
          ? "Current"
          : "Previous";

        return (
          <ManifestStep
            width="wide"
            actions={[
              {
                children: "Continue",
                onClick: handleSubmit,
                type: "submit",
              },
            ]}
            title="Employment status"
            description="Enter your employment status to gain access to our entire suite of financial services"
          >
            <div className="flex w-full flex-col">
              <SectionHeader>Employment status</SectionHeader>
              <FormikRadioGroup name="status" className="grid gap-4 sm:grid-cols-2" showError>
                {employmentStatusEnum.map(status => (
                  <RadioButton key={status} label={sentenceCase(status)} value={status} />
                ))}
              </FormikRadioGroup>

              <SectionHeader>Employment industry</SectionHeader>
              <FormikDropdown
                name="industry"
                fullWidth
                options={employmentIndustries.map(label => ({ label, value: pascalCase(label) }))}
              />

              {values.status ? (
                <>
                  <SectionHeader>{`${employmentTime} job title`}</SectionHeader>
                  <FormikTextField name="jobTitle" hideLabel />

                  <SectionHeader>{employmentTime} employer</SectionHeader>
                  <FormikTextField name="employer" hideLabel />
                </>
              ) : null}
            </div>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
