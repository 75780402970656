import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import { pushLocation } from "Reducers/navigationReducer";

export const NavigationTracker = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(pushLocation(location.pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return null;
};
