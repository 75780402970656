import { ReactNode, useContext } from "react";

import { Icons, WizardStepperContext } from "@unchained/component-library";
import cn from "classnames";

/** Common header styles for onboarding flow */
export const SectionHeader = ({
  children,
  className,
  subtitle,
  big,
}: {
  children: ReactNode;
  className?: string;
  subtitle?: string;
  big?: boolean;
}) => (
  <div className="my-4">
    <h3 className={cn("font-med text-gray-700", big ? "text-md" : "text-sm", className)}>
      {children}
    </h3>
    {subtitle ? (
      <h4 className={cn("mt-2 font-semi text-gray-600", big ? "text-sm" : "text-xs")}>
        {subtitle}
      </h4>
    ) : null}
  </div>
);

/** Navigable header styles for sections in onboarding review pages */
export const ReviewSectionHeader = ({
  children,
  stepName,
  goToChildrenSubstep = true,
}: {
  children: string;
  stepName: string;
  goToChildrenSubstep?: boolean;
}) => {
  const { goTo } = useContext(WizardStepperContext);

  return (
    <a
      className="mt-4 flex cursor-pointer items-center text-md font-semi text-primary-700 transition-colors hover:text-primary-800"
      onClick={() => (goToChildrenSubstep ? goTo(stepName, children) : goTo(stepName))}
    >
      {children} <Icons.Pencil className="ml-2 pb-px" size="xs" />
    </a>
  );
};
