import { useContext, useState } from "react";

import { Avatar, Button, ModalContent, ModalFooter } from "@unchained/component-library";

import import_sd from "Assets/images/ColdcardScreens/import_from_sd.png";
import ms_wallet from "Assets/images/ColdcardScreens/multisig_wallets.png";
import ccSettings from "Assets/images/ColdcardScreens/settings.png";
import { GetWalletConfig200 } from "Specs/v1";
import { DownloadColdcardConfig } from "Utils/downloadColdcardConfig";
import { useErrorToast } from "Utils/toasts";

import { WalletRegistrationContext } from "../WalletRegistrationWizard";

interface ColdcardRegistrationModalContentProps {
  walletConfig: GetWalletConfig200;
  productUuid: string;
  onDownloadConfig?: () => void;
}

/**
 * Content for a coldcard registration modal so it can
 * stand on its own outside of a registration wizard
 */
export const ColdcardRegistrationModalContent = ({
  walletConfig,
  productUuid,
  onDownloadConfig,
}: ColdcardRegistrationModalContentProps) => {
  const showErrorToast = useErrorToast();
  const [downloaderEnabled, setDownloaderEnabled] = useState(true);
  const downloader = new DownloadColdcardConfig({
    walletUuid: walletConfig.uuid,
    productUUID: productUuid,
    walletConfig,
  });

  const handleDownload = async () => {
    try {
      await downloader.handleDownloadConfigClick();
      if (onDownloadConfig) onDownloadConfig();
      setDownloaderEnabled(false);
    } catch (e) {
      showErrorToast(e, {
        title: "Something went wrong.",
        description: e.message,
      });
    }
  };

  const steps = [
    {
      title: "Download your Coldcard file",
      subtitle: "So your device knows about this wallet.",
      children: (
        <Button color={downloaderEnabled ? "primary" : "secondary"} onClick={handleDownload}>
          Download Coldcard file
        </Button>
      ),
    },
    {
      title: "Move file to Coldcard",
      subtitle: "Use the microSD card (or USB, with Mk4), move the file to your device.",
    },
    {
      title: "Import file on Coldcard",
      subtitle:
        "On your device, go to Settings > Multisig Wallets > Import from File/SD, choose the file and save it.",
      children: (
        <>
          <p className="text-gray-500"></p>
          <div className="flex justify-around">
            <img src={ccSettings} alt="Coldcard settings" />
            <img src={ms_wallet} alt="Coldcard multisig wallets" />
            <img src={import_sd} alt="Coldcard import SD" />
          </div>
        </>
      ),
    },
    {
      title: "You’re done!",
      subtitle: `Select “Next” once done to finish the registration process.`,
    },
  ];

  return (
    <ModalContent>
      {steps.map((step, index) => (
        <div className="mt-6 flex items-start gap-4" key={index}>
          <Avatar shape="circle" initials={(index + 1).toString()} color="lightGray" />
          <div>
            <p className="text-md font-semi">{step.title}</p>
            <p className="text-sm text-gray-500">{step.subtitle}</p>
            <div className="mt-3">{step?.children}</div>
          </div>
        </div>
      ))}
    </ModalContent>
  );
};

export const ColdcardRegistrationStep = () => {
  const [nextEnabled, setNextEnabled] = useState(false);
  const { setStepIndex, stepIndex, walletConfig, productUuid } =
    useContext(WalletRegistrationContext);

  return (
    <>
      <ColdcardRegistrationModalContent
        walletConfig={walletConfig}
        productUuid={productUuid}
        onDownloadConfig={() => setNextEnabled(true)}
      />
      <ModalFooter
        actions={[
          { children: "Next", disabled: !nextEnabled, onClick: () => setStepIndex(stepIndex + 1) },
        ]}
      />
    </>
  );
};
