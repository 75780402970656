import { differenceInDays } from "date-fns";
import { useQuery, UseQueryOptions } from "react-query";
import { useDispatch } from "react-redux";

import { setBtcCurrentPriceAction } from "Actions/cryptoActions/priceActions";

import { CryptoAPI } from "..";
import { PricePoint } from "../cryptoApi";

interface PricesResponse {
  BTC: PricePoint;
}

const priceRequestKeys = {
  current: ["prices", "current"] as const,
  dailyPrices: ["prices", "daily"] as const,
  hourlyPrices: ["prices", "hourly"] as const,
};

const oneMinute = 1 * 60 * 1000;
export const useGetBtcPriceQuery = () => {
  const dispatch = useDispatch();
  return useQuery(priceRequestKeys.current, () => CryptoAPI.GetCryptoPrices(), {
    refetchInterval: oneMinute,
    select: (data: PricesResponse) => data.BTC.value,
    onSuccess: data => {
      // TODO: This is only needed as a stopgap while we maintain
      // use of the store for storing the btc price
      dispatch(setBtcCurrentPriceAction(data));
    },
  });
};

export const useGetBtcPrice = () => useGetBtcPriceQuery()?.data || 0;

const dayBitcoinWasCreated = new Date("2009-01-03");
const daysSinceBitcoinWasCreated = differenceInDays(new Date(), dayBitcoinWasCreated);

/** Bitcoin prices by day, for as many days as requested. (Full history default) */
export const useDailyHistoricalBitcoinPrices = (
  daysAgo: number = daysSinceBitcoinWasCreated + 10,
  opts: UseQueryOptions<PricePoint[]> & { retries?: number } = {}
) => {
  const { retries = 3, ...options } = opts;
  return useQuery({
    staleTime: oneMinute,
    ...options,
    queryKey: priceRequestKeys.dailyPrices,
    queryFn: () => CryptoAPI.GetHistoricalBitcoinPrices(retries, daysAgo),
  });
};

/** Bitcoin prices by hour, for as many days as requested. (7 days default/max) */
export const useHourlyHistoricalBitcoinPrices = (
  daysAgo: 1 | 2 | 3 | 4 | 5 | 6 | 7 = 7,
  opts: UseQueryOptions<PricePoint[]> & { retries?: number } = {}
) => {
  const { retries = 3, ...options } = opts;
  return useQuery({
    staleTime: oneMinute,
    ...options,
    queryKey: priceRequestKeys.hourlyPrices,
    queryFn: () => CryptoAPI.GetIntradayBitcoinPrices(retries, daysAgo),
  });
};
