import { SellBitcoinStepperWizard } from "Components/TradingDesk/sell/SellBitcoinStepperWizard";
import { AppModalManager } from "Shared/components/Modals";
import { ProvideAggregatedNotifications200 } from "Specs/v2";
import { fullDateString } from "Utils/dates";
import { btcOperationTypes } from "Utils/enums";
import { pluralize } from "Utils/strings";
import { FullOperationType, generateOperationVerbClause } from "Utils/verbs";

import { BizMembershipInviteModal } from "./BizMembershipInviteModal";
import { BaseNotification } from "./NotificationRow";

type OrgAction = ProvideAggregatedNotifications200["orgActions"][number];
export type V1Notification = ProvideAggregatedNotifications200["orgActions"][number]["org"][number];

const openSellBitcoinFlow = () => AppModalManager.open(SellBitcoinStepperWizard);

const capStart = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
// Indicate that text should be bold by wrapping within a very-unlikely-to-occur string of asterisks
const b = (text: string) => `***${text}***`;

export const getV1NotificationDetails = (
  orgUuid: string,
  action: OrgAction["org"][number],
  extra: {
    ownedVaultIds?: string[];
  } = {}
): BaseNotification | null => {
  if (!action) return null;
  const {
    operationType: fullOperationType,
    operationUuid,
    productUuid,
    actionType,
    productType: givenProductType,
    productName,
    accountKeyName,
    uuid,
    dueAt,
    name,
  } = action;

  if (!actionType) return null;

  try {
    const isSale = fullOperationType === btcOperationTypes.vault_sale_transaction;
    const smTxText = isSale ? "sell transaction" : "transaction";

    const opVerb = generateOperationVerbClause(fullOperationType as FullOperationType);

    const [derivedProductType, simpleOpType] = (() => {
      const matchingProduct = ["loan", "vault", "batch"].find(prodType =>
        fullOperationType?.startsWith(prodType)
      );

      const opType = matchingProduct ? fullOperationType.split(`${matchingProduct}_`)[1] : null;

      return [matchingProduct === "batch" ? "vault" : matchingProduct, opType];
    })();

    const productType = givenProductType || derivedProductType;

    const [products, ops] = [productType, simpleOpType].map(s => s && pluralize(s));

    const due = new Date(dueAt);
    const isPastDue = due < new Date();

    const base = orgUuid ? `/accounts/${orgUuid}` : "";

    const opPath = `${base}/${products}/${productUuid}/${ops}/${operationUuid}`;
    const prodPath = `${base}/${products}/${uuid}`;

    const nonSaleOpProductDescription = ({ short }: { short?: boolean }) =>
      short || !opVerb
        ? `transaction ${b(productName)}`
        : `transaction for ${opVerb} ${b(productName)}`;

    const opProductDescription = ({ isSale, short }: { isSale?: boolean; short?: boolean }) =>
      isSale ? `sell transaction from ${b(productName)}` : nonSaleOpProductDescription({ short });

    const key = `key ${b(accountKeyName)}`;
    const product = `${productType} ${b(uuid)}`;

    switch (actionType) {
      case "accept_org_membership": {
        return {
          text: "Accept business membership invitation",
          onClick: () => {
            AppModalManager.open(() => <BizMembershipInviteModal name={name} uuid={uuid} />);
          },
        };
      }

      case "approve_btc_transaction": {
        const text = `Approve pending ${smTxText}`;

        if (isSale) {
          return { text, onClick: openSellBitcoinFlow };
        } else {
          return { text, to: opPath };
        }
      }

      case "sign_btc_transaction": {
        const text = `${capStart(smTxText)} is approved and ready to sign`;
        if (isSale) {
          return { text, onClick: openSellBitcoinFlow };
        } else {
          return { text, to: opPath };
        }
      }

      case "approve_signature_request": {
        return {
          text: isSale
            ? `Approve pending ${opProductDescription({ isSale })}`
            : `Approve signing with ${key} for ${opVerb} ${b(productName)}`,
          to: `/admin/btc-transaction-requests`,
        };
      }

      case "broadcast_transaction": {
        if (isSale) {
          return {
            text: `${capStart(opProductDescription({ isSale }))} is ready to broadcast`,
            onClick: openSellBitcoinFlow,
          };
        } else {
          return {
            text: `Broadcast ${opProductDescription({ isSale })}`,
            to: opPath,
          };
        }
      }

      case "deposit_collateral": {
        return {
          text: `Deposit collateral for ${product}`,
          to: prodPath,
        };
      }

      case "key_check": {
        return {
          text: `Device check for ${key} is due`,
          to: `/keys/${uuid}`,
        };
      }

      case "pending_deposit": {
        return {
          text: `Pending deposit for ${product}`,
          to: prodPath,
        };
      }

      case "set_return_address": {
        return {
          text: `Set redemption address for ${product}`,
          to: prodPath,
        };
      }

      case "sign_legal": {
        return {
          text: `Sign documents for ${product}`,
          to: prodPath,
        };
      }

      case "sign_transaction": {
        const isVaultTransactionFromOwnedVault = extra.ownedVaultIds
          ? extra.ownedVaultIds.includes(productUuid)
          : undefined;

        if (isSale && isVaultTransactionFromOwnedVault) {
          return {
            text: `Sign ${smTxText} from ${b(productName)} with ${key}`,
            onClick: openSellBitcoinFlow,
          };
        } else {
          return {
            text: `Sign with ${key} for ${opProductDescription({ isSale: false, short: true })}`,
            to: opPath,
            loading: isVaultTransactionFromOwnedVault === undefined,
          };
        }
      }

      case "payment": {
        if (isPastDue) {
          return {
            to: prodPath,
            text: `Payment past due for ${product}`,
          };
        }
        return {
          to: prodPath,
          text: `Payment due on ${fullDateString(due)} for ${product}`,
        };
      }

      case "provide_basic_info": {
        return {
          to: `/onboard/${orgUuid}`,
          text: `Resume application`,
        };
      }

      default:
        return null;
    }
  } catch (error) {
    if (error) console.error(error);
    console.error(
      `Failure to extract notification details given args:`,
      JSON.stringify({ orgUuid, ...action }, null, 2)
    );
    return null;
  }
};
