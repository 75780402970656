import { useState } from "react";

import { Button, Slideout, Spinner } from "@unchained/component-library";
import { AxiosError } from "axios";
import { useSelector } from "react-redux";

import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { useGetAccount } from "Shared/api";
import { useGetOrgLoans } from "Shared/api/v2/hooks/loans";
import { useGetWireInstructionsQuery } from "Shared/api/v2/hooks/wireInstructions";
import { AppSlideoutManager } from "Shared/components/SlideoutManager";
import { CompleteOrg } from "Specs/v1/getAccount/200";
import { useCurrentOrgUuid } from "Utils/hooks/useCurrentOrg";
import { stateIs } from "Utils/states";
import { formatCurrency } from "Utils/strings";
import { useEasyToasts } from "Utils/toasts";

import { WireInstructions, WireNotes } from "./WireInstructions";

export const CashDepositSlideout = () => {
  const getAccountQuery = useGetAccount();
  const currentOrg = useSelector(getCurrentOrg);

  const { showErrorToast } = useEasyToasts();
  const handleGetWireInstructionsError = (err: AxiosError) => {
    const errorTitle = "Error fetching wire instructions";
    showErrorToast(errorTitle, { title: errorTitle });
  };
  const getWireInstructionsQuery = useGetWireInstructionsQuery(
    currentOrg?.uuid,
    handleGetWireInstructionsError
  );
  const cashBalance = getWireInstructionsQuery?.data?.cashBalance
    ? Number(getWireInstructionsQuery.data?.cashBalance)
    : undefined;
  const availableBalance = getWireInstructionsQuery?.data?.availableBalance
    ? Number(getWireInstructionsQuery.data?.availableBalance)
    : undefined;
  const minimumWireAmount = getWireInstructionsQuery.data?.minimumWireAmount
    ? Number(getWireInstructionsQuery.data?.minimumWireAmount)
    : undefined;

  const minimumBuyAmount = getWireInstructionsQuery.data?.minimumBuyAmount
    ? `${formatCurrency(getWireInstructionsQuery.data.minimumBuyAmount)}`
    : "---";
  const isMinimumBuyAmountAboveZero = Number(getWireInstructionsQuery.data?.minimumBuyAmount) > 0;

  const [doesUserHaveActiveLoan, setDoesUserHaveActiveLoan] = useState(false);

  const handleGetLoansSuccess = (loans: { state: string; [key: string]: any }[] = []) => {
    const inactiveLoanStates = ["failed", "denied", "abandoned", "closed"];
    for (let loan of loans) {
      const isActive = !stateIs(loan, ...inactiveLoanStates);
      if (isActive) {
        setDoesUserHaveActiveLoan(true);
        // we only need to know if the user has atleast one active loan
        // therefore, once one if found, end the loop.
        break;
      } else {
        setDoesUserHaveActiveLoan(false);
      }
    }
  };

  useGetOrgLoans(currentOrg?.uuid, handleGetLoansSuccess);

  // If there is an error a toast will be displayed , therefore we don't want
  // clicking close on that toast to close the slideout
  const dismissOnBlur = getWireInstructionsQuery.isError ? false : true;

  return (
    <Slideout
      open={true}
      disableEnforceFocus={true}
      dismissOnBlur={dismissOnBlur}
      dismissOnEscape={true}
      onDismiss={AppSlideoutManager.close}
    >
      {!getWireInstructionsQuery.isLoading && !getAccountQuery.isLoading ? (
        <div className="flex h-screen flex-col">
          <h1 className="mb-2 text-[1.875rem] font-bold">Wire instructions</h1>
          <p className="mb-1">Send a wire transfer to increase your cash balance.</p>
          {isMinimumBuyAmountAboveZero && (
            <p>Note: the minimum trade amount is ${minimumBuyAmount}.</p>
          )}
          {doesUserHaveActiveLoan && (
            <p className="mt-1 text-red-600">
              Your cash balance cannot be used for loan interest payments.
            </p>
          )}
          <WireInstructions
            org={currentOrg as CompleteOrg}
            cashBalance={cashBalance}
            availableBalance={availableBalance}
            minimumWireAmount={minimumWireAmount}
          />
          <div className="mt-6">
            <WireNotes />
          </div>
          <div className=" mt-auto">
            <Button
              fullWidth
              onClick={AppSlideoutManager.close}
              className="mb-6 mt-4 !h-12 w-full"
              color="secondary"
            >
              Close
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex h-full flex-col justify-center">
          <Spinner className=" ml-auto mr-auto" size={140} />
        </div>
      )}
    </Slideout>
  );
};
