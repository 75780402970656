import React from "react";

import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "../AccountKeys/ExportPubKeyContent.module.scss";

class MessagesList extends React.Component {
  render = () => {
    const { messages } = this.props;
    if (
      messages.length === 1 &&
      messages[0].text === "Make sure your Trezor device is plugged in."
    ) {
      return this.renderTrezorPluggedInMessage();
    }
    return <div>{messages.map((msg, indx) => this.renderMessageBody(msg, indx))}</div>;
  };

  renderMessageBody = (message, index) => {
    const { size } = this.props;
    return (
      <div
        key={index}
        className={classnames({
          [styles.smWalletBefore]: size === "sm",
          [styles.lgWalletBefore]: size !== "sm",
        })}
      >
        {message.text}
      </div>
    );
  };

  renderTrezorPluggedInMessage = () => {
    return (
      <>
        {this.renderMessageBody(
          {
            text: "Ensure the Trezor Suite application is open on your computer and your Trezor device is unlocked with your PIN.",
          },
          0
        )}
        {this.renderMessageBody(
          {
            text: (
              <>
                If you're using a new computer or are missing the Trezor Suite application, download
                it{" "}
                <a href="https://trezor.io/trezor-suite" target="_blank" rel="noreferrer">
                  here
                </a>
                .
              </>
            ),
          },
          1
        )}
      </>
    );
  };
}

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.string.isRequired,
};

class InteractionMessages extends React.Component {
  render = () => {
    const { messages } = this.filteredMessages();
    const { size } = this.props;
    return <MessagesList messages={messages} size={size} />;
  };

  filteredMessages = () => {
    const { messages, excludeCodes } = this.props;
    const filteredMessages = [];
    messages.forEach(message => {
      for (let i = 0; i < excludeCodes.length; i += 1) {
        const excludeCode = excludeCodes[i];
        if ((message.code || "").includes(excludeCode)) {
          return;
        }
      }
      //FIXME - tweak UI to better support messages of differing product versions
      if (
        !message.version ||
        (message.version && ["One", ">=1.6.0", ">=2.1.0", ">=2.1.1"].includes(message.version))
      ) {
        filteredMessages.push(message);
      }
    });
    return { messages: filteredMessages };
  };
}

InteractionMessages.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.object),
  excludeCodes: PropTypes.arrayOf(PropTypes.string),
};

InteractionMessages.defaultProps = {
  messages: [],
  excludeCodes: [],
};

export default InteractionMessages;
