import { useMutation } from "react-query";

import { Formik, FormikDropdown } from "Components/FormFields";
import { FormikCheckboxGroup } from "Components/FormFields/FormikCheckboxGroup";
import { Link } from "Components/Link";
import { ManifestStep } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { ProfileAPI } from "Shared/api";
import {
  CompleteOrg,
  completeOrgPersonEstimatedWealthEnum,
  completeOrgPersonEstimatedWealthNotesEnum,
} from "Specs/v1/getOrg/200";

import {
  addDollarSignsToMoneyRange,
  initWealth,
  validateWealth,
} from "../../individualOrgValsAndValidations";

export const WealthDigitalAssets = ({ org }: { org: CompleteOrg }) => {
  const wrapManifestMutation = useWrapManifestMutation({
    orgUuid: org.uuid,
    isIra: "iraPlan" in org,
  });
  const initVals = initWealth(org);
  const putAssets = wrapManifestMutation(
    useMutation(({ estimated_wealth, estimated_wealth_notes }: typeof initVals) =>
      ProfileAPI.UpdateAdvanced(org.uuid, {
        estimated_wealth,
        estimated_wealth_notes: estimated_wealth_notes.join(", "),
      })
    )
  );

  const onSubmit = ({ estimated_wealth, estimated_wealth_notes }: typeof initVals) =>
    putAssets.mutate({ estimated_wealth, estimated_wealth_notes });

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={onSubmit}
      validationSchema={validateWealth}
      debugName="WealthDigitalAssets"
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="narrow"
            actions={[
              {
                children: "Continue",
                onClick: handleSubmit,
                type: "submit",
              },
            ]}
            title="Wealth and digital assets"
            description={
              <span>
                To adhere to federal regulations and to prevent the creation of fraudulent accounts,
                this information is required to verify your account. Read more{" "}
                <Link to="https://unchained.com/terms-of-service">here</Link>.
              </span>
            }
          >
            <div className="flex w-full flex-col">
              <h3 className="mb-4 text-sm font-med text-gray-700">Estimated net worth</h3>
              <FormikDropdown
                name="estimated_wealth"
                options={completeOrgPersonEstimatedWealthEnum.map(value => ({
                  label: addDollarSignsToMoneyRange(value),
                  value,
                }))}
                fullWidth
              />

              <h3 className="mb-4 mt-8 text-sm font-med text-gray-700">
                Source of wealth (select all that apply)
              </h3>
              <FormikCheckboxGroup
                showError
                options={completeOrgPersonEstimatedWealthNotesEnum}
                name="estimated_wealth_notes"
                className="grid gap-4 sm:grid-cols-2"
              />
            </div>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
