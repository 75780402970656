import { API, API_V2, REQUEST_STATUS } from "./api";
import { AuthAPIFactory } from "./authApi";
import { FeaturesAPIFactory } from "./featuresApi";
import { InheritanceAPIFactory } from "./inheritanceApi";
import { IraPlanAPIFactory } from "./iraPlanApi";
import { OrgAPIFactory } from "./orgApi";
import { PersonAPIFactory } from "./personApi";
import { NotificationsAPIV2Factory } from "./v2/notifications";
import { OrgAPIV2Factory } from "./v2/orgs";
import { PersonAPIV2Factory } from "./v2/person";
import { TradingAPIV2Factory } from "./v2/trading";

export type { RequestStatus } from "./api";
export { BankAccountAPI } from "./bankaccountApi";
export { BitcoinAssetsAPI } from "./bitcoinAssetsApi";
export { CredentialsAPI } from "./credentialsApi";
export { CryptoAPI } from "./cryptoApi";
export { EntityAPI } from "./entityApi";
export { FeesAPI } from "./feesApi";
export * from "./hooks";
export { KeyAPI } from "./keyApi";
export { LoanAPI } from "./loanApi";
export { NotificationsAPI } from "./notificationsApi";
export { OperationAPI } from "./operationApi";
export { MFAResetsApi } from "./MFAResetsApi";
export { PasswordApi } from "./passwordApi";
export { PrincipalAPI } from "./principalApi";
export { ProfileAPI } from "./profileApi";
export { RekeysApi } from "./rekeysApi";
export { SliceAPI } from "./sliceApi";
export { SupportPinsAPI } from "./supportPinsApi";
export { TradingAPI } from "./tradingApi";
export { TransactionAPI } from "./transactionApi";
export { TrustAPI } from "./trustApi";
export { UsdAPI } from "./usdApi";
export { UserAPI } from "./userApi";
export * from "./utils";
export { BankAccountApiV2 } from "./v2/bankAccounts";
export { VaultAPI } from "./vaultApi";
export { VerificationsApi } from "./verificationsApi";
export { WalletAPI } from "./walletApi";
export { REQUEST_STATUS, API, API_V2 };

// TODO: (Sasha's opinion) - separate v1 and v2 of OrgAPI. We could overwrite one with another without realizing it!
export const OrgAPI = { ...OrgAPIFactory(API), ...OrgAPIV2Factory(API_V2) };
export const AuthAPI = AuthAPIFactory(API);
export const FeaturesAPI = FeaturesAPIFactory(API);
export const InheritanceAPI = InheritanceAPIFactory(API);
export const PersonAPI = PersonAPIFactory(API);
export const IraPlanAPI = IraPlanAPIFactory(API);
export const PersonAPIV2 = PersonAPIV2Factory(API_V2);
export const NotificationsAPIV2 = NotificationsAPIV2Factory(API_V2);
export const TradingAPIV2 = TradingAPIV2Factory(API_V2);
