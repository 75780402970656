import { useState } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { Add, HelpOutline } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import {
  Banner,
  Button,
  Dropdown,
  EmailField,
  Icons,
  Loader,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
  Tooltip,
} from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { orgQueryKeys, useGetOrg } from "Shared/api";
import { ChangeProposalAPI } from "Shared/api/changeProposalApi";
import { AppModalManager } from "Shared/components/Modals";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { useEasyToasts } from "Utils/toasts";

import { RoleTooltip } from "../RoleTooltip";
import { ROLE_OPTIONS } from "../utils";
import AcknowledgeQuorumEnablementModal from "./AcknowledgeQuorumEnablementModal";

const viewersOnlyOptions = ROLE_OPTIONS.filter(option => option.value === "viewer");

export function OrgInviteMembersWithRoles({
  org,
  submit,
}: {
  org: CompleteOrg;
  submit: (invites) => void;
}) {
  const existingEmails = [
    ...org.accepted_members.map(m => m.user.email),
    ...org.pending_members_emails,
  ];
  const existingNonViewerInviteCount = org.pending_members_emails_with_roles.filter(invite =>
    invite.roles.some(r => r !== "viewer")
  ).length;
  const maxInvites = org.available_memberships - existingNonViewerInviteCount;
  const [invites, setInvites] = useState([]);
  const [activeUserEmail, setActiveUserEmail] = useState("");
  const [activeUserRole, setActiveUserRole] = useState(undefined);
  const [disableAddButton, setDisableAddButton] = useState(true);

  const unlimitedViewerInvites = org?.business_tier !== "basic";
  const nonViewerInviteCount = invites.filter(i => i.roles.some(r => r !== "viewer")).length;
  const canAddNonViewers = nonViewerInviteCount < maxInvites;

  const validateActiveUser = (email: string, role: string) => {
    let valid = true;

    if ([...invites.map(i => i.email), ...existingEmails].some(e => e === email)) {
      valid = false;
    }

    if (!email || !role) {
      valid = false;
    }

    setActiveUserEmail(email);
    setActiveUserRole(role);
    setDisableAddButton(!valid);
  };

  const addUser = () => {
    setInvites([...invites, { email: activeUserEmail, roles: [activeUserRole] }]);
    setActiveUserEmail("");
    setActiveUserRole(undefined);
    setDisableAddButton(true);
  };

  const removeUser = (i: number) => {
    const newInvites = [...invites];
    newInvites.splice(i, 1);
    setInvites(newInvites);
  };

  return (
    <div>
      <div className="mb-4">
        {maxInvites ? (
          <Banner title="Add additional users" type="info">
            You can invite {maxInvites > 10 ? "additional " : `up to ${maxInvites} more `}
            active users and an unlimited number of additional viewers.
          </Banner>
        ) : unlimitedViewerInvites ? (
          <Banner title="You have no more available active memberships." type="info">
            You can invite an unlimited number of additional viewers.
          </Banner>
        ) : (
          <Banner title="You have no more available memberships." type="warning">
            Contact us to increase the number of members in this account.
          </Banner>
        )}
      </div>
      {invites.map((invite, i) => (
        <div className="mb-2 flex rounded bg-gray-100 p-2" key={i.toString()}>
          <div className="flex flex-grow flex-col">
            <div className="text-md font-semi">{invite.email}</div>
            <div className="text-sm text-gray-500">{invite.roles.join(" | ")}</div>
          </div>
          <IconButton
            className="shrink-0"
            size="large"
            data-testid={`remove-user-${i}`}
            onClick={() => removeUser(i)}
          >
            <Icons.Trash className="shrink-0 text-primary-600" />
          </IconButton>
        </div>
      ))}
      {(invites.length < maxInvites || unlimitedViewerInvites) && (
        <div key={"new user"} className="min-h-[266px]">
          <EmailField
            value={activeUserEmail}
            data-testid="new-user-email"
            key={"new user email"}
            label="Email"
            className="mb-3"
            onChange={(e: any) => validateActiveUser(e.target.value, activeUserRole)}
          />

          <div className="mb-1 text-sm font-med text-gray-700">
            Role{" "}
            <Tooltip content={<RoleTooltip />}>
              <span className="text-md">
                <HelpOutline fontSize="inherit" />
              </span>
            </Tooltip>
          </div>
          <Dropdown
            data-testid="new-user-role"
            className="mb-8 w-full"
            selectedLabels={[ROLE_OPTIONS.find(r => r.value === activeUserRole)?.label]}
            onSelect={(e: any) => {
              validateActiveUser(activeUserEmail, e.value);
            }}
            options={canAddNonViewers ? ROLE_OPTIONS : viewersOnlyOptions}
          ></Dropdown>
          <Button
            color="primary"
            data-testid="add-user-btn"
            onClick={addUser}
            disabled={disableAddButton}
            className="mt-16 w-full"
          >
            Add user
          </Button>
        </div>
      )}

      <Button
        variant="text"
        data-testid="add-user-btn"
        onClick={() => submit(invites)}
        disabled={invites.length === 0}
        className="mt-4 w-full"
      >
        Submit invites
      </Button>
    </div>
  );
}

export function InviteMembers({ disabled, org }: { disabled: boolean; org: CompleteOrg }) {
  const { uuid } = org;
  const { showApiSuccessToast, showErrorToast } = useEasyToasts();
  const queryClient = useQueryClient();
  const orgQuery = useGetOrg(uuid);

  const submitInvites = useMutation((invites: { email: string; roles: string[] }[]) =>
    ChangeProposalAPI.CreateChangeProposalGroup(org.uuid, {
      changeProposals: invites.map(invite => ({
        type: "OrgMemberInviteChangeProposal",
        ...invite,
      })),
    })
  );

  const quorumEnabled = !!org.quorum_config && org.quorum_config?.min_approvals > 1;
  const successMessage = quorumEnabled
    ? "Change proposal submitted and approvers have been notified"
    : "Invited users successfully";

  const submit = invites => {
    submitInvites.mutate(invites, {
      onError: err => showErrorToast(err),
      onSuccess: () => {
        showApiSuccessToast({ message: successMessage });
        queryClient.refetchQueries(orgQueryKeys.changeProposals(uuid));
        queryClient.refetchQueries(orgQueryKeys.show(uuid));
      },
      onSettled: () => AppModalManager.close(),
    });
  };

  const checkForQuorumEnablement = invites => {
    const quorumEnabled = !!org.quorum_config && org.quorum_config?.min_approvals > 1;

    const addingApprovers = invites.some(invite => {
      return invite.roles.some(role => ["admin", "approver"].includes(role));
    });

    if (!quorumEnabled && addingApprovers) {
      AppModalManager.open(() => (
        <AcknowledgeQuorumEnablementModal
          onClose={val => {
            if (val) {
              submit(invites);
            } else {
              AppModalManager.close();
            }
          }}
        />
      ));
    } else {
      submit(invites);
    }
  };

  const openAddMembersModal = () =>
    AppModalManager.open(() => (
      <Modal onDismiss={AppModalManager.close}>
        <ModalHeader>
          <ModalTitle>Invite users</ModalTitle>
        </ModalHeader>
        <ModalContent className="-mx-1 px-1">
          {orgQuery.isLoading ? (
            <Loader />
          ) : (
            <OrgInviteMembersWithRoles org={orgQuery.data} submit={checkForQuorumEnablement} />
          )}
        </ModalContent>
      </Modal>
    ));

  return (
    <Button
      color="primary"
      disabled={disabled}
      endIcon={<Add key={"icon"} />}
      onClick={() => openAddMembersModal()}
    >
      Invite member
    </Button>
  );
}
