import React from "react";

import { BuyBitcoinOutOfServiceModal } from ".";

import { CashDepositSlideout } from "Components/PaymentMethods/CashDepositSlideout";
import { useBuyBitcoinStore } from "Contexts/BuyBitcoin";
import { AppModalManager } from "Shared/components/Modals";
import { AppSlideoutManager } from "Shared/components/SlideoutManager";

import styles from "./AwaitingSettlementModal.module.scss";

/**
 * Modal Component which displays awaiting settlement info.
 * @returns {React.Component}
 */
const AwaitingSettlementModal = () => {
  const { bitcoinPrice, offlineTitle, offlineDescription, offlineButtonText } =
    useBuyBitcoinStore();

  const closeModalButton = {
    variant: "text" as const,
    classes: { root: styles.secondaryButtonRoot },
    onClick: AppModalManager.close,
    children: "Close",
  };

  return (
    <BuyBitcoinOutOfServiceModal
      title={offlineTitle}
      descriptionText={offlineDescription}
      onButtonClick={() => AppSlideoutManager.open(() => <CashDepositSlideout />)}
      buttonText={offlineButtonText}
      bitcoinPrice={bitcoinPrice}
      secondaryButton={closeModalButton}
    />
  );
};

export { AwaitingSettlementModal };
