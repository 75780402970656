import { useEffect } from "react";

import { Navigate } from "react-router-dom";

import { useBackRoute } from "Components/Link/LinkBack";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { useEasyToasts, type ErrorToastOptions } from "Utils/toasts";

const fourOhFour = {
  title: "Page not found",
  description: "The page you are looking for does not exist.",
  showClientReportLink: false,
} as ErrorToastOptions;

export const NavigateWithToast = ({
  to = "/home",
  toast = fourOhFour,
}: {
  to: string;
  toast?: ErrorToastOptions;
}) => {
  const { showErrorToast } = useEasyToasts();
  useEffect(() => {
    showErrorToast(undefined, toast);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Navigate to={to} />;
};

/**
 * Navigate back or "home", without displaying a toast.
 * */
export const NavigateBack = withWorkspaceData<{
  homeOverwrite?: string;
}>(({ homeOverwrite }) => {
  const to = useBackRoute(homeOverwrite);

  return <Navigate to={to} />;
});

/**
 * The default alternative for most blocked routes.
 * Simply navigates back/home with a 404 style toast.
 * */
export const NavigateBackWithToast = ({
  toast = fourOhFour,
  homeOverwrite,
}: {
  toast?: ErrorToastOptions | false;
  homeOverwrite?: string;
}) => {
  const { showErrorToast } = useEasyToasts();

  useEffect(() => {
    if (toast) {
      showErrorToast(undefined, toast);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NavigateBack homeOverwrite={homeOverwrite} />;
};
