import {
  RESET_LOAN_SHOW_USER,
  SET_LOAN_SHOW_ACCOUNT_KEYS,
  SET_LOAN_SHOW_CLOSE_TYPE,
  SET_LOAN_SHOW_LENDERS,
  SET_LOAN_SHOW_LENDERS_STATUS,
  SET_LOAN_SHOW_LOAN_DATA,
  SET_LOAN_SHOW_OLD_RETURN_ADDRESS,
  SET_LOAN_SHOW_STATUS,
  SET_LOAN_SHOW_UPDATE_LENDER,
  SET_LOAN_SHOW_UPDATE_NOTE,
} from "Actions/loanActions/loanShowActions";
import { REQUEST_STATUS } from "Shared/api/api";
import { formatNumber } from "Utils/strings";

const initialState = {
  loan: {
    confirmedValue: null,
    valueWithUnit: "",
    wallet: {},
    state: "",
    uuid: "",
    allowed_actions: [],
  },
  status: REQUEST_STATUS.PENDING,
  viewerRole: "",
  loanKeys: {},
  closeType: "",
  returnAddress: "",

  lenders: [],
  lendersStatus: REQUEST_STATUS.PENDING,
};

const updatedLender = (possibleLenders, payload) => {
  const lender = possibleLenders.find(possibleLender => possibleLender.uuid === payload.uuid);
  let spread = null;
  try {
    spread = formatNumber(parseFloat(payload.spread_percentage) / 100.0, 4);
  } catch (e) {}
  return { lender, spread };
};

export const loanShowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SET_LOAN_SHOW_LOAN_DATA: {
      return {
        ...state,
        loan: payload,
      };
    }

    case SET_LOAN_SHOW_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case SET_LOAN_SHOW_ACCOUNT_KEYS: {
      return {
        ...state,
        loanKeys: payload,
      };
    }

    case RESET_LOAN_SHOW_USER: {
      return {
        ...initialState,
      };
    }

    case SET_LOAN_SHOW_CLOSE_TYPE: {
      return {
        ...state,
        ...{ closeType: payload },
      };
    }

    case SET_LOAN_SHOW_OLD_RETURN_ADDRESS: {
      return {
        ...state,
        ...{
          loan: {
            ...state.loan,
            ...{
              wallet: {
                ...state.loan.wallet,
                ...{ return_address: payload },
              },
            },
          },
        },
      };
    }

    case SET_LOAN_SHOW_LENDERS: {
      return {
        ...state,
        lenders: payload,
      };
    }

    case SET_LOAN_SHOW_LENDERS_STATUS: {
      return {
        ...state,
        lendersStatus: payload,
      };
    }

    case SET_LOAN_SHOW_UPDATE_LENDER: {
      return {
        ...state,
        loan: {
          ...state.loan,
          ...updatedLender(state.lenders, payload),
        },
      };
    }

    case SET_LOAN_SHOW_UPDATE_NOTE: {
      return {
        ...state,
        loan: {
          ...state.loan,
          note: payload,
        },
      };
    }

    default:
      return state;
  }
};
