import { useBuyBitcoinStore } from "Contexts/BuyBitcoin";

import { VaultSelectDropdown } from "./VaultSelectDropdown";

export interface SourceAndDestinationValues {
  destination: string;
}

export const SourceAndDestinationForm = () => {
  const {
    destinations: { vaults, loans },
  } = useBuyBitcoinStore();

  const isMoreThanOneDestination = vaults.length + loans.length > 1;

  return (
    <VaultSelectDropdown
      label="Destination"
      isSelectable={isMoreThanOneDestination}
      vaults={vaults}
      loans={loans}
    />
  );
};
