import { Button, Card, Spinner, Table } from "@unchained/component-library";
import cn from "classnames";

import { Link } from "Components/Link";
import { useGetOrg, useGetWalletConfigsForOrg } from "Shared/api";
import { useOrgSubscriptionBySku } from "Shared/api/v2/hooks/subscriptions";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { GetWalletConfig200 } from "Specs/v1";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { annualFeeSkuForOrg, useGoToOrgPayment } from "Utils/billing";
import { downloadTextFile } from "Utils/download";

import { LockedForPaymentImage } from "./LockedForPaymentImage";

const RedirectToPayment = ({ org }) => {
  const [goToPayment, goToPaymentLoading] = useGoToOrgPayment(org, { from: "/home" });

  if (!goToPayment) return null;

  return (
    <div className="mt-6 w-full">
      <Button
        className="mx-auto px-8"
        color="primary"
        onClick={goToPayment}
        type="button"
        disabled={goToPaymentLoading}
        endIcon={goToPaymentLoading && <Spinner size={24} className="my-[-16px]" />}
      >
        Pay invoice
      </Button>
    </div>
  );
};

const VaultWalletConfigTable = ({ org, unifiedIA }: { org: CompleteOrg; unifiedIA?: boolean }) => {
  const walletConfigQuery = useGetWalletConfigsForOrg(org.uuid);

  const download = (walletConfig: GetWalletConfig200) => {
    const body = JSON.stringify(walletConfig, null, 2);
    const filename = `wallet-config-${walletConfig.name}.json`;
    downloadTextFile(body, filename);
  };

  if (walletConfigQuery.isLoading || !walletConfigQuery.data?.walletConfigs?.length) return null;

  interface Row extends GetWalletConfig200, Record<string, unknown> {}
  const data = walletConfigQuery.data?.walletConfigs as Row[];

  const getColumns = () => [
    {
      header: "Vault name",
      accessorKey: "name",
      Cell: ({ cell }) => cell.row.original.name,
    },
    {
      header: "Wallet config file",
      Cell: ({ cell }) => (
        <Button onClick={() => download(cell.row.original)} type="button" variant="text">
          Download
        </Button>
      ),
    },
  ];

  return (
    <Card className={cardClasses(unifiedIA)}>
      <div className="p-4">
        <h1>Vault info</h1>
        <Table data={data || []} columns={getColumns()}></Table>
        <p className="px-16 py-4">
          Dont want to pay? You can move funds without Unchained.{" "}
          <Link
            to="https://help.unchained.com/how-to-vault-external-recovery-caravan"
            target="_blank"
          >
            Learn More
          </Link>
        </p>
      </div>
    </Card>
  );
};

const cardClasses = unifiedIA =>
  cn(
    "m-4 flex flex-col items-center justify-center p-10",
    unifiedIA ? "w-full max-w-2xl" : "md:m-12 md:max-w-lg"
  );

export const _LockedForPayment = ({
  org,
  unifiedIA,
  unifiedIATitle,
}: {
  org: CompleteOrg;
  unifiedIA?: boolean;
  unifiedIATitle: string;
}) => {
  const existingSubscriptionSku = annualFeeSkuForOrg(org);
  const existingSubscriptionQuery = useOrgSubscriptionBySku(org.uuid, existingSubscriptionSku);

  if (existingSubscriptionQuery.isLoading) return null;

  const existingActiveSubscription = existingSubscriptionQuery?.data?.data?.find(
    sub => sub.status === "active"
  );

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center",
        unifiedIA ? "my-6" : ""
      )}
    >
      <Card className={cardClasses(unifiedIA)}>
        <LockedForPaymentImage className="mb-8 mt-6" />
        <div className="prose text-center">
          {existingActiveSubscription ? (
            <>
              <h1>Payment required</h1>
              <p>Your account is locked but has an active subscription.</p>
              <p>
                Please contact Client Services at{" "}
                <Link to="mailto:clientservices@unchained.com">clientservices@unchained.com</Link>
              </p>
            </>
          ) : (
            <>
              <h1>Payment required</h1>
              <p>
                Your Bitcoin is secure and under your control. However, due to an overdue invoice,
                you currently have limited access to{" "}
                {unifiedIA ? unifiedIATitle || "this account" : "the Unchained platform"}. To regain
                full access to your account, please make the necessary payment.
              </p>
              <p>
                If you have questions please contact Client Services at{" "}
                <Link to="mailto:clientservices@unchained.com">clientservices@unchained.com</Link>
              </p>

              <RedirectToPayment org={org} />

              <p className="mt-4">
                Alternatively, you can close your account on the{" "}
                <Link to={"/settings?tab=my-accounts"}>settings page</Link>
              </p>
            </>
          )}
        </div>
      </Card>
      <VaultWalletConfigTable unifiedIA={unifiedIA} org={org} />
    </div>
  );
};

export const LockedForPayment = ({
  orgUuid,
  unifiedIA,
}: {
  orgUuid: string;
  unifiedIA?: boolean;
}) => {
  const org = useGetOrg(orgUuid);
  const workspaceData = useWorkspaceData({
    enabled: unifiedIA,
  });

  if (org.isLoading) return null;
  if (workspaceData.isLoading && unifiedIA) return null;

  const unifiedIATitle = unifiedIA
    ? workspaceData.data?.orgs?.find(o => o.id === orgUuid)?.title
    : "";
  return <_LockedForPayment org={org.data} unifiedIA={unifiedIA} unifiedIATitle={unifiedIATitle} />;
};
