import { get } from "lodash";
import { useSelector } from "react-redux";

import { AccountPersonalOrg, AccountUser, CompleteOrg } from "Specs/v1/getAccount/200";
import { OrgForListStateEnum } from "Specs/v1/listVaults/200";

export const useMemoizedState = <T = any>(statePath: string): T => {
  const selector = state => get(state, statePath);
  return useSelector(selector);
};

export const useCurrentUser = (): AccountUser => useMemoizedState("account.user");

export const isLensable = ({ name, state }: { name?: string; state?: OrgForListStateEnum }) =>
  name && state !== "pending_activation";

/**
 * DEPRECATED: Use useAccountCurrentOrg instead. (Or useAccount, withAccount, all in account.tsx file.)
 * This pulls from the Redux store, and depends on external/previous requests.
 * */
export const useCurrentOrgDeprecated = () => {
  const org = useMemoizedState("account.orgs.current");
  return {
    ...org,
    lensable: isLensable(org),
  } as (AccountPersonalOrg | CompleteOrg) & {
    /* Whether the org is good to interact with -- ie, it's no longer pending and has a name */
    lensable: boolean;
  };
};
