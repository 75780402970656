import { SignIcon } from "Components/Shared/Icons/SignIcon";
import { SellBitcoinStepperWizard } from "Components/TradingDesk/sell/SellBitcoinStepperWizard";
import { VaultWithAmounts } from "Redux/selectors";
import { AppModalManager } from "Shared/components/Modals";
import { btcOperationTypes } from "Utils/enums";
import { operationVerbClauseMap } from "Utils/index";
import { splitOperationTypePlural } from "Utils/operationTypes";

const generateOperationVerbClause = operationType => {
  if (operationVerbClauseMap[operationType]) {
    return operationVerbClauseMap[operationType];
  }

  // Error?
  return operationType;
};

const SignTransactionNotification = ({
  operation_type,
  operation_uuid,
  product_name,
  product_uuid,
  account_key_name,
  ownedVaults = [],
}: {
  operation_type: string;
  operation_uuid: string;
  product_name: string;
  product_uuid: string;
  account_key_name?: string;
  ownedVaults?: VaultWithAmounts[];
}) => {
  const isSaleTransaction = operation_type === btcOperationTypes.vault_sale_transaction;
  const [products, operations] = splitOperationTypePlural(operation_type);
  const isVaultTransactionFromOwnedVault =
    products === "vaults" && ownedVaults.some(vault => vault.uuid === product_uuid);

  const link =
    isSaleTransaction && isVaultTransactionFromOwnedVault
      ? ""
      : `/${products}/${product_uuid}/${operations}/${operation_uuid}`;

  const operationVerbClause = generateOperationVerbClause(operation_type);

  const openSellBitcoinFlow = () =>
    AppModalManager.open(() => {
      return <SellBitcoinStepperWizard />;
    });

  // if the sale transaction is not from the org's owned vault, they will
  // not have access to the sellStepper. Therefore don't call openSellBitcoinFlow.
  const additionalAction =
    isSaleTransaction && isVaultTransactionFromOwnedVault ? openSellBitcoinFlow : undefined;

  const title = isSaleTransaction
    ? `Sign sell transaction from ${product_name} with key ${account_key_name}`
    : `Sign with key ${account_key_name} for ${operationVerbClause} ${product_name}`;

  return {
    to: link,
    title: title,
    Icon: SignIcon,
    additionalAction: additionalAction,
  };
};

export { SignTransactionNotification };
