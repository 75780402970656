import { MASKS } from "./mask";

const {
  PERMIT,
  STRING_W_COUNT,
  STRING,
  NUMBER_W_COUNT,
  NUMBER,
  OMIT_STRING_IF_PRESENT,
  LIST_ALL_KEYS,
  LIST_TRUTHY_KEYS,
} = MASKS;

export const addressMask = {
  country: PERMIT,
  line_1: OMIT_STRING_IF_PRESENT,
  created_at: PERMIT,
  line_2: OMIT_STRING_IF_PRESENT,
  state: OMIT_STRING_IF_PRESENT,
  role: PERMIT,
  updated_at: PERMIT,
  zip: STRING_W_COUNT,
  city: OMIT_STRING_IF_PRESENT,
};

export const docMask = {
  created_at: PERMIT,
  dangerous: PERMIT,
  updated_at: PERMIT,
  name: STRING_W_COUNT,
  size: NUMBER_W_COUNT,
  title: PERMIT,
};

export const personMask = {
  ssn: STRING_W_COUNT,
  dob: STRING_W_COUNT,
  updated_at: PERMIT,
  org_uuid: PERMIT,
  nationality: STRING_W_COUNT,
  advanced_profile_complete: PERMIT,
  state_id: docMask,
  physical_address: addressMask,
  last_name: STRING,
  profile_complete: PERMIT,
  created_at: PERMIT,
  middle_name: STRING,
  income: STRING,
  first_name: STRING,
  uuid: PERMIT,
};

export const userMask = {
  allowed_actions: PERMIT,
  name: STRING_W_COUNT,
  phone: STRING_W_COUNT,
  codename: PERMIT,
  has_otp: PERMIT,
  has_passkey: PERMIT,
  accepted_org_types: PERMIT,
  email: STRING,
  username: STRING,
  signature: PERMIT,
  person: personMask,
  uuid: PERMIT,
};

export const memberMask = {
  is_removeable: PERMIT,
  user: userMask,
  state: PERMIT,
};

export const listOrgMask = {
  name: STRING,
  subscribed_skus: LIST_ALL_KEYS,
  uuid: PERMIT,
};

export const groupMask = {
  name: PERMIT,
  orgs: listOrgMask,
  users: userMask,
  managed: PERMIT,
  uuid: PERMIT,
  dedicated: PERMIT,
};

export const getOrgMask = (light = false) => ({
  allowed_actions: PERMIT,
  name: STRING,
  subscribed_skus: LIST_ALL_KEYS,
  uuid: PERMIT,
  advanced_profile_state: PERMIT,
  concierge_onboarding: PERMIT,
  entity: light
    ? undefined
    : {
        name: STRING,
        principals: light
          ? undefined
          : {
              person: personMask,
              title: STRING,
            },
        uuid: STRING,
        proof_of_business_address: docMask,
        type: PERMIT,
        advanced_profile_complete: PERMIT,
        purpose: STRING,
        created_at: PERMIT,
        articles_of_incorporation: docMask,
        business_address: addressMask,
        certificate_of_good_standing: docMask,
        updated_at: PERMIT,
        profile_complete: PERMIT,
      },
  bank_accounts: light
    ? undefined
    : {
        name: STRING,
        official_name: STRING,
        account_type: PERMIT,
        created_at: PERMIT,
        routing_number: STRING_W_COUNT,
        uuid: PERMIT,
        account_number: STRING_W_COUNT,
        wire_routing_number: STRING_W_COUNT,
        valid_non_plaid: PERMIT,
        state: PERMIT,
        updated_at: PERMIT,
      },
  multi_institution_vaults: PERMIT,
  accepted_members: light ? undefined : memberMask,
  pending_members_emails: STRING,
  ignore_payments: PERMIT,
  pending_members_emails_with_roles: light
    ? undefined
    : {
        email: STRING,
      },
  business_tier: PERMIT,
  state: PERMIT,
  max_members: NUMBER,
  partnership_token: STRING_W_COUNT,
  account_type: PERMIT,
  all_features: LIST_TRUTHY_KEYS,
  available_memberships: PERMIT,
  type: PERMIT,
  devices_requested: PERMIT,
  non_plaid_bank_accounts_allowed: PERMIT,
  personal: PERMIT,
  locked_for_payment: PERMIT,
  owner: userMask,
  codename: PERMIT,
});

const baseBennieMask = {
  uuid: PERMIT,
  percentage: PERMIT,
  email: STRING_W_COUNT,
  spouse: PERMIT,
  primary: PERMIT,
};

const iraEntityMask = {
  uuid: PERMIT,
  name: STRING,
  ein: STRING_W_COUNT,
  orgUuid: PERMIT,
};

export const iraBennieMask = { ...baseBennieMask, person: personMask, entity: iraEntityMask };
