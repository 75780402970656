import { GetAdminSupportPin200, GetClientSupportPin200, VerifyAdminSupportPin200 } from "Specs/v1";
import {
  UnchainedSupportPins200,
  VerifyFetchSupportPin200,
  VerifyFetchSupportPinRequest,
} from "Specs/v2";
import { VerifyFetchSupportPin409 } from "Specs/v2/verifyFetchSupportPin/409";

import { API, API_V2 } from "./api";

class SupportPinsAPI {
  static GetSupportPin = async (userUuid: string) =>
    (await API.Get<GetClientSupportPin200>(`/users/${userUuid}/support-pin`)).data;
  static GetAdminSupportPin = async (userUuid: string) =>
    (await API.Get<GetAdminSupportPin200>(`/users/${userUuid}/admin-support-pin`)).data;
  static VerifyAdminSupportPin = async data =>
    (await API.Post<VerifyAdminSupportPin200>("/support-pins/admin-pin/verify", data)).data;

  static VerifyFetchSupportPin = async (userUuid: string, data: VerifyFetchSupportPinRequest) =>
    (
      await API_V2.Post<VerifyFetchSupportPin409 | VerifyFetchSupportPin200>(
        `/users/${userUuid}/verify-fetch-support-pin`,
        data
      )
    ).data;
  static GetUnchainedSupportPins = async (userUuid: string) =>
    (await API_V2.Get<UnchainedSupportPins200>(`/users/${userUuid}/unchained-support-pins`)).data;
}

export { SupportPinsAPI };
