import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ArrowBack, HelpOutline } from "@mui/icons-material";
import { Button, Tooltip } from "@unchained/component-library";

type SellHeaderProps = {
  onBack?: () => void;
  title: string;
  titleToolTip?: string;
  subTitle: string | ReactNode;
  className?: string;
};

export const SellHeader = ({
  onBack,
  title,
  subTitle,
  className = "mb-6",
  titleToolTip,
}: SellHeaderProps) => {
  return (
    <div className={className}>
      {onBack ? (
        <Button startIcon={<ArrowBack />} className="mb-2 mt-4" variant="text" onClick={onBack}>
          Back
        </Button>
      ) : null}
      <div>
        <h2 data-testid="sellHeader" className="mb-2 inline text-d-sm text-gray-900">
          {title}
        </h2>
        {titleToolTip ? (
          <Tooltip arrow placement="top" compact content={titleToolTip}>
            <HelpOutline className="relative top-[-4px] ml-[3px]  text-lg text-gray-400" />
          </Tooltip>
        ) : null}
      </div>
      <span className="text-md text-gray-700">{subTitle}</span>
    </div>
  );
};
