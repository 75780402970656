import { API } from "./api";

export type PricePoint = {
  exchange: string;
  asset: string;
  unit: string;
  value: number;
  time: string;
};

export class CryptoAPI {
  static GetCryptoPrices = async (maxTries = 3) => {
    try {
      const response = await API.Get(`/prices`);
      return response.data;
    } catch (e) {
      if (maxTries === 1) {
        throw e;
      }
      return await CryptoAPI.GetCryptoPrices(maxTries - 1);
    }
  };

  static GetHistoricalCryptoPrices = async (
    maxTries = 3,
    daysSince = 30
  ): Promise<{
    BTC: PricePoint[];
  }> => {
    try {
      const response = await API.Get(`/prices/historical?days_since=${daysSince}`);
      return response.data as { BTC: PricePoint[] };
    } catch (e) {
      if (maxTries === 1) {
        throw e;
      }
      return await CryptoAPI.GetHistoricalCryptoPrices(maxTries - 1, daysSince);
    }
  };

  /** Bitcoin prices by day, for as many days as requested. (30 days default) */
  static GetHistoricalBitcoinPrices = async (maxTries = 3, daysSince = 30): Promise<PricePoint[]> =>
    (await CryptoAPI.GetHistoricalCryptoPrices(maxTries, daysSince)).BTC;

  /** Bitcoin prices by hour, for as many days as requested. (1 day default, 7 days max) */
  static GetIntradayBitcoinPrices = async (maxTries = 3, daysSince = 1): Promise<PricePoint[]> => {
    try {
      const response = await API.Get<{ prices: PricePoint[] }>(
        `/prices/intraday?days_since=${daysSince}`
      );
      return response.data.prices;
    } catch (e) {
      if (maxTries === 1) {
        throw e;
      }
      return await CryptoAPI.GetIntradayBitcoinPrices(maxTries - 1, daysSince);
    }
  };
}
