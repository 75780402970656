import { useEffect, useState } from "react";

import { useQuery, UseQueryResult } from "react-query";

import { UnchainedSupportPins200 } from "Specs/v2";

import { SupportPinsAPI } from "../supportPinsApi";

export const supportPinsRequestKeys = {
  userAdminPins: (uuid: string) => ["userAdminPins", uuid] as const,
};

export const useGetAdminPins = (userUuid: string) =>
  useQuery(supportPinsRequestKeys.userAdminPins(userUuid), () =>
    SupportPinsAPI.GetUnchainedSupportPins(userUuid)
  );

export const useRefreshSupportPins = (
  query: UseQueryResult<UnchainedSupportPins200>,
  pick: string
) => {
  const [timeLeft, setTimeLeft] = useState(0);
  const { data: result, refetch } = query;
  const data = result?.[pick];

  useEffect(() => {
    const calcDelay = (validUntil: string) => Date.parse(validUntil) - Date.now();

    if (data) {
      const delay = calcDelay(data.validUntil);

      const timer = setTimeout(() => {
        refetch();
      }, delay);

      setTimeLeft(delay);

      const interval = setInterval(() => {
        setTimeLeft(calcDelay(data.validUntil));
      }, 1000);

      return () => {
        clearTimeout(timer);
        clearInterval(interval);
      };
    }
  }, [data, refetch]);

  return { query, timeLeft, pin: data?.pin };
};

export const useRefreshGetUnchainedPin = (userUuid: string) => {
  return useRefreshSupportPins(useGetAdminPins(userUuid), "unchained");
};

export const useRefreshGetClientPin = (userUuid: string) => {
  return useRefreshSupportPins(useGetAdminPins(userUuid), "user");
};
