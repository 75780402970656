import { Button } from "@unchained/component-library";

import { FundIRAStepperWizard } from "Components/IRAs/FundIra/FundIraStepper";
import { useCurrentOrgDeprecated } from "Redux/selectors/hooks";
import { AppModalManager } from "Shared/components/Modals";

type FundIraButtonProps = {
  disabled?: boolean;
};
export const FundIraButton = ({ disabled }: FundIraButtonProps) => {
  const org = useCurrentOrgDeprecated();

  if (org.state !== "live") return null;

  const handleClick = () => {
    AppModalManager.open(() => {
      return <FundIRAStepperWizard />;
    });
  };

  return (
    <Button disabled={disabled} onClick={handleClick} fullWidth color="darkSecondary">
      Fund
    </Button>
  );
};
