import { AdminShowMfaReset200, CompleteMfaReset200, DeleteMfaReset200 } from "Specs/v1";

import { API, resolveData } from "./api";

class MFAResetsApi {
  static GetMFAResets = () => resolveData(API.Get(`/mfa/resets`));
  static GetMFAReset = token => resolveData(API.Get(`/mfa/resets/${token}`));
  static GetMFAResetAdmin = async token =>
    (await API.Get<AdminShowMfaReset200>(`/mfa/resets/${token}/admin`)).data;
  static CompleteMFAReset = async (uuid: string) =>
    (await API.Post<CompleteMfaReset200>(`/mfa/resets/${uuid}/completed`)).data;
  static DeleteMFAReset = async (uuid: string) =>
    (await API.Delete<DeleteMfaReset200>(`/mfa/resets/${uuid}`)).data;
  static SubmitMFAReset = async (uuid: string) => (await API.Post(`/users/${uuid}/mfa/reset`)).data;
}

export { MFAResetsApi };
