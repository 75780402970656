import { Navigate, useLocation } from "react-router-dom";

import PublicApp from "Components/PublicApp";
import UserApp from "Components/UserApp";

/**
 * Determines whether to show the PublicApp or UserApp (authenticated),
 * as well as whether/where to redirect based on login status.
 **/
export const RootAuthHandler = () => {
  const location = useLocation();
  const { pathname, search } = location;

  // Not logged in
  if (!window.TREFOIL_USER?.uuid) {
    if (
      !pathname.startsWith("/login") &&
      !pathname.startsWith("/logout") &&
      !pathname.startsWith("/sign_up") &&
      !pathname.startsWith("/mfa") &&
      !pathname.startsWith("/credentials")
    ) {
      if (pathname !== "/") {
        const searchParams = search || "";
        return <Navigate to={`/login?next=${encodeURIComponent(pathname + searchParams)}`} />;
      } else {
        return <Navigate to="/login" />;
      }
    }

    return <PublicApp />;

    // If authenticated but on auth route
  } else if (["/login", "/sign_up"].some(route => pathname.startsWith(route))) {
    return <Navigate to="/home" />;
  }

  // Logged in on logged-in route
  return <UserApp />;
};
