import React, { useState } from "react";

import { Fade } from "@mui/material";
import { Icons, useBreakpoint, Button } from "@unchained/component-library";

import { FullPageWizard } from "Components/Shared/Layouts/FullPageWizard";
import { CloseButton } from "Components/Shared/Layouts/FullPageWizard/components";
import { AppModalManager } from "Shared/components/Modals/AppModalManager";

import { WithdrawalIraTypes } from "../types";
import { CompleteFormStep } from "./CompleteFormStep";
import { SelectTypeStep } from "./SelectTypeStep";

const fadeOutAnimationTimeout = 250;

type WithdrawalIRAStepperWizardBaseProps = {
  closeStepper: () => void;
  initialStep: number;
};
const WithdrawalIRAStepperWizardBase = React.memo(
  ({ closeStepper, initialStep }: WithdrawalIRAStepperWizardBaseProps) => {
    const [iraWithdrawalType, setIraWithdrawalType] = useState<WithdrawalIraTypes>(undefined);
    const wizardSteps = React.useMemo(() => {
      const baseSteps = [
        {
          title: "Select type",
          Component: () => (
            <SelectTypeStep
              selectedIraWithdrawalType={iraWithdrawalType}
              setSelectedIraWithdrawalType={(iraWithdrawalType: WithdrawalIraTypes) =>
                setIraWithdrawalType(iraWithdrawalType)
              }
            />
          ),

          complete: iraWithdrawalType,
        },
        {
          title: "Complete form",
          Component: () => <CompleteFormStep selectedIraWithdrawalType={iraWithdrawalType} />,
          complete: false,
          disabled: !iraWithdrawalType,
        },
      ];

      return baseSteps;
    }, [iraWithdrawalType]);

    const handleCloseButtonClick = React.useCallback(() => {
      closeStepper();
    }, []);

    const StepperFooter = () => <Icons.X onClick={closeStepper} />;
    const CloseFooter = () => {
      return (
        <Button
          fullWidth={true}
          color="destructive"
          className="w-full !rounded-none"
          onClick={AppModalManager.close}
        >
          Close
        </Button>
      );
    };

    const manifest = React.useMemo(() => {
      return [
        {
          title: "IRA withdrawal request",
          AlternateIcon: () => <CloseButton onClose={handleCloseButtonClick} />,
          steps: wizardSteps,
        },
      ];
    }, [wizardSteps, handleCloseButtonClick]);

    const { widthIsBelow } = useBreakpoint();
    const isMobile = widthIsBelow("sm");
    const Stepper = React.memo(() => (
      <>
        <FullPageWizard
          startingIndices={"firstIncomplete"}
          isShowUnchainedLogo={false}
          stepperFooter={isMobile ? <StepperFooter /> : <CloseFooter />}
          footerStyles={isMobile ? undefined : "w-full"}
          manifest={manifest}
          headerBackgroundColor={"bg-primary-100"}
        />
      </>
    ));
    return <Stepper />;
  }
);

export const WithdrawalRAStepperWizard = React.memo(
  ({ initialStep = 0 }: { initialStep?: number }) => {
    const [isShowing, setIsShowing] = useState(true);

    const closeStepper = React.useCallback(() => {
      setIsShowing(false);
      setTimeout(() => {
        AppModalManager.close();
      }, fadeOutAnimationTimeout);
    }, []);

    return (
      <Fade in={isShowing} timeout={fadeOutAnimationTimeout}>
        <div>
          <WithdrawalIRAStepperWizardBase closeStepper={closeStepper} initialStep={initialStep} />;
        </div>
      </Fade>
    );
  }
);
