import React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import GlobalErrorBoundary from "Components/ErrorBoundaries/GlobalErrorBoundary";
import { API, API_V2 } from "Shared/api/api";
import { usePublicConfigData } from "Shared/api/v2/hooks/config";
import { setCsrfToken } from "Utils/ajax";

import styles from "./PublicApp.module.scss";

const PublicApp = () => {
  const location = useLocation();
  const publicConfig = usePublicConfigData();

  // Auth pages need to break out of app layout
  const authPages = [
    /sign_up/,
    /login/,
    /credentials\/password\/reset/,
    /sign_ups\/(\w+)\/confirm/,
    /mfa\/resets/,
  ];
  const isAuthPage = authPages.some(pathRegex => pathRegex.test(location.pathname));

  React.useEffect(() => {
    if (publicConfig.data === undefined) return;
    let csrfToken = publicConfig.data.csrfToken;
    API.setCsrfToken(csrfToken);
    API_V2.setCsrfToken(csrfToken);
    setCsrfToken(csrfToken);
  }, [publicConfig]);

  if (isAuthPage) {
    return (
      <div id="main">
        <GlobalErrorBoundary userAuthenticated={false}>
          <div className={styles.fullPageContent}>
            <Outlet />
          </div>
        </GlobalErrorBoundary>
      </div>
    );
  }

  return <Navigate to="login" />;
};

export default PublicApp;
