import { useMemo } from "react";

import { AmountDisplayWithTooltip } from ".";
import { Button } from "@unchained/component-library";
import Big from "big.js";
import { useSelector } from "react-redux";

import { FundIRAStepperWizard } from "Components/IRAs/FundIra/FundIraStepper";
import { AvatarRowLeft } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/AvatarRows";
import { orgDisplayInfo } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { Link } from "Components/Link";
import { CashDepositSlideout } from "Components/PaymentMethods/CashDepositSlideout";
import { AmountDisplay } from "Components/TradingDesk/components/AmountDisplay";
import { useBuyBitcoinStore } from "Contexts/BuyBitcoin";
import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { AppModalManager } from "Shared/components/Modals";
import { AppSlideoutManager } from "Shared/components/SlideoutManager";
import { CompleteOrg } from "Specs/v1/getAccount/200";
import { formatCurrency } from "Utils/strings";

import styles from "../modals/BuyBitcoinModal/BuyBitcoinModal.module.scss";

export const BuyPositionSummary = () => {
  const {
    cash: { cashBalanceUsd, availableBalanceUsd },
    maximumPurchaseAmount,
  } = useBuyBitcoinStore();

  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg?.account_type === "ira";

  const openCashDepositSlideout = () => {
    AppSlideoutManager.open(() => <CashDepositSlideout />);
  };

  const pendingCashDeposits = useMemo(() => {
    try {
      return formatCurrency(Big(cashBalanceUsd).minus(availableBalanceUsd).toString());
    } catch (e) {
      return "N/A";
    }
  }, [cashBalanceUsd, availableBalanceUsd]);

  const cashBalanceLabel = isIraOrg ? "IRA cash balance" : "Cash balance";

  const cashBalanceTooltipText = isIraOrg ? (
    <>
      Submit an{" "}
      <Link type="text" onClick={() => AppModalManager.open(() => <FundIRAStepperWizard />)}>
        {" "}
        IRA funding form request{" "}
      </Link>
      to increase your IRA cash balance.
    </>
  ) : (
    `Your cash balance includes $${pendingCashDeposits} in pending deposits. Any purchase above $${formatCurrency(
      availableBalanceUsd
    )} may impact how quickly you receive your bitcoin.`
  );

  const isAvailableCashBalanceAboveMaxPurchaseAmount = useMemo(() => {
    try {
      return Big(availableBalanceUsd).gt(maximumPurchaseAmount.USD);
    } catch (e) {
      return false;
    }
  }, [availableBalanceUsd, maximumPurchaseAmount.USD]);

  const isAvailableCashBalanceAndCashBalanceEqual = cashBalanceUsd === availableBalanceUsd;

  const CashBalanceDisplay = () =>
    (isAvailableCashBalanceAndCashBalanceEqual || isAvailableCashBalanceAboveMaxPurchaseAmount) &&
    !isIraOrg ? (
      <AmountDisplay amount={cashBalanceUsd} label={cashBalanceLabel} />
    ) : (
      <AmountDisplayWithTooltip
        amount={cashBalanceUsd}
        label={cashBalanceLabel}
        tooltipContent={cashBalanceTooltipText}
      />
    );

  const { Avatar: OrgAvatar, color } = orgDisplayInfo(currentOrg as CompleteOrg);

  return (
    <div className="mt-4 flex flex-row items-center justify-between">
      <AvatarRowLeft
        avatar={<OrgAvatar title={currentOrg.title} className={`bg-[${color}]`} />}
        title={currentOrg.title}
        subtitle={currentOrg.subtitle}
        className="-m-2 !py-0 !pl-2"
      />
      <div className="flex flex-col items-end">
        <CashBalanceDisplay />
        {!isIraOrg && (
          <Button
            className={styles.instructionsButton}
            onClick={openCashDepositSlideout}
            variant="text"
          >
            Wire instructions
          </Button>
        )}
      </div>
    </div>
  );
};
