import cx from "classnames";

import { BuyPositionSummary } from "Components/TradingDesk/components/BuyPositionSummary";
import { TradingServiceDisclosure } from "Components/TradingDesk/components/TradingServiceDisclosure";
import { DestinationVault } from "Contexts/BuyBitcoin/types";
import { BuyFlowOrgSelectModal } from "Routes/accounts/all/(components)/OpenBuyFlowModal";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { AppModalManager } from "Shared/components/Modals";

import { TradeModalContainer } from "../TradeModalContainer";
import tradeModalContainerStyles from "../TradeModalContainer.module.scss";
import { BuyBitcoinForm } from "./BuyBitcoinForm";
import styles from "./BuyBitcoinModal.module.scss";

interface BuyBitcoinModalProps {
  next: () => void;
  defaultVault: DestinationVault;
}

/**
 * Component modal that allows a user to select an amount to buy, and a destination vault.
 * @param {func} next - Function to handle BuyBitcoinModal proceeding to the next modal.
 * @param {string} defaultVault - Initial selected vault in the vault select drop down.
 * @returns {React.Component}
 */
const BuyBitcoinModal = ({ next, defaultVault }: BuyBitcoinModalProps) => {
  const workspaceData = useWorkspaceData();
  const isUnifiedIA = workspaceData.data?.user?.canViewUnifiedIA;

  return (
    <div
      className={cx(
        tradeModalContainerStyles.container,
        styles.container,
        styles.container_withoutWireAmountDue
      )}
    >
      <TradeModalContainer
        title="Buy bitcoin"
        containerClassName={styles.showModal}
        dismissButtonClassName={styles.closeModalButton}
        showBackArrow={isUnifiedIA}
        onBack={() => {
          AppModalManager.open(() => <BuyFlowOrgSelectModal />);
        }}
      >
        <BuyPositionSummary />
        <BuyBitcoinForm defaultVault={defaultVault} next={next} />
        <TradingServiceDisclosure />
      </TradeModalContainer>
    </div>
  );
};

export { BuyBitcoinModal };
