import { BeginFido2Authentication200 } from "Specs/v2/beginFido2Authentication/200";
import { CompleteRegistrationResponse, ListPasskeysResponse } from "Specs/v2/components";

import { API_V2 } from "../api";

export class Fido2API {
  static BeginRegistration = async (name?: string) => {
    const response = await API_V2.Post<{ options: { publicKey: unknown } }>(
      "/fido2/begin-registration",
      { name }
    );
    return response.data;
  };

  static CompleteRegistration = async (setupData: object) => {
    const response = await API_V2.Post<CompleteRegistrationResponse>(
      "/fido2/complete-registration",
      { setupData }
    );
    return response.data;
  };

  static ListUserPasskeys = async () => {
    const response = await API_V2.Get<ListPasskeysResponse>("/fido2/passkeys");
    return response.data;
  };

  static RemovePasskey = async (passkeyId: string, authData: object) => {
    const response = await API_V2.Delete(`/fido2/passkeys/${passkeyId}`, { authData });
    return response.data;
  };

  static BeginAuthentication = async (passkeyId: string) => {
    const response = await API_V2.Post<BeginFido2Authentication200>(
      `/fido2/passkeys/${passkeyId}/begin-authentication`
    );
    return response.data;
  };
}
