import { ChangeEvent, useState, useContext } from "react";

import {
  RadioGroup,
  RadioButton,
  Button,
  WizardStepperContext,
} from "@unchained/component-library";

import { StepHeader } from "../components/StepHeader";
import { FundIraTypes } from "../types";

type SelectTypeStepProps = {
  selectedIraFundingType?: FundIraTypes;
  setSelectedIraFundingType: (iraFundingType: FundIraTypes) => void;
};
export const SelectTypeStep = ({
  selectedIraFundingType,
  setSelectedIraFundingType,
}: SelectTypeStepProps) => {
  const { goToNext } = useContext(WizardStepperContext);
  const [iraFundingType, setIraFundingType] = useState<undefined | FundIraTypes>(
    selectedIraFundingType
  );

  const handleSelectedType = (event: ChangeEvent<HTMLInputElement>) => {
    setIraFundingType(event.target.value as FundIraTypes);
  };
  const openLearnMore = (url: string) => {
    window.open(url, "_blank");
  };

  const navigateToNextStep = () => {
    setSelectedIraFundingType(iraFundingType);
    goToNext();
  };

  const Description = ({ content, url }: { content: string; url: string }) => {
    return (
      <div className="max-w-md px-4">
        <p className="mb-4 max-w-sm text-md font-reg">{content}</p>
        <Button onClick={() => openLearnMore(url)} variant="text">
          Learn more
        </Button>
      </div>
    );
  };

  return (
    <div className="p-3">
      <StepHeader title="Select type" isBackButtonShowing={false} className="mb-6" />
      <RadioGroup
        onChange={handleSelectedType}
        value={iraFundingType}
        name="numbers"
        className="flex flex-col gap-3"
      >
        <RadioButton
          label="Annual contribution"
          className="mb-3 px-4 pb-4 pt-1"
          description={
            <Description
              content="Speak to your tax advisor about your contribution limits. Per IRS guidelines, only USD contributions are permitted."
              url="https://help.unchained.com/how-do-i-fund-my-unchained-ira-with-an-annual-contribution"
            />
          }
          value={FundIraTypes.ANNUAL_CONTRIBUTION}
        />
        <RadioButton
          label="Employer plan rollover"
          className="mb-3 px-4 pb-4 pt-1"
          value={FundIraTypes.ROLLOVER}
          description={
            <Description
              content="Rollover a 401(k), 403(b), 457, pension, TSP or others."
              url="https://help.unchained.com/how-do-i-fund-my-unchained-ira-with-a-401k-to-ira-rollover"
            />
          }
        />
        <RadioButton
          label="IRA-to-IRA transfer"
          className="mb-3 px-4 pb-4 pt-1"
          value={FundIraTypes.IRA_TO_IRA_TRANSFER}
          description={
            <Description
              content="Transfer USD or BTC from another IRA into your Unchained IRA. Note: not all releasing custodians support in-kind BTC transfers."
              url="https://help.unchained.com/how-do-i-fund-my-ira"
            />
          }
        />
      </RadioGroup>

      <Button
        disabled={!iraFundingType}
        fullWidth
        onClick={navigateToNextStep}
        color="primary"
        className="mt-6"
      >
        Continue
      </Button>
    </div>
  );
};
