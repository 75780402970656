import { useContext } from "react";

import { ButtonProps, formatDollars, Loader, PricingTable } from "@unchained/component-library";
import { Navigate } from "react-router-dom";

import { useSwitchCurrentOrg } from "Components/Layout/Nav/Sidebar/OldSidebar/hooks";
import { useNavigate } from "Components/Link";
import { ManifestStep } from "Routes/onboard/(shared)";
import { OnboardBasicContext } from "Routes/onboard/(utils)";
import { useOnboardNewOrg } from "Routes/onboard/(utils)/createAccountUtils";
import { SKUS } from "Shared/api/v2";
import { getPurchaseable, usePurchasables } from "Shared/api/v2/hooks/purchasables";
import { useEasyToasts } from "Utils/toasts";

const basicHeaders = {
  individual: "Personal",
  ira: "Basic IRA",
  trust: "Basic Trust",
};

export default function UpgradeOptions() {
  const { showErrorToast } = useEasyToasts();
  const purchasablesQuery = usePurchasables();
  const onboardNewOrg = useOnboardNewOrg();
  const { accountType, signatureUpgrade, personalOrg } = useContext(OnboardBasicContext);
  const navigate = useNavigate();
  const switchOrg = useSwitchCurrentOrg();

  if (purchasablesQuery.isLoading) return <Loader className="h-screen w-full" />;
  if (purchasablesQuery.isError) {
    showErrorToast(purchasablesQuery.error);
    return <Navigate to="/home" />;
  }

  const { purchasables } = purchasablesQuery.data;
  const individualAcctPurchasable = getPurchaseable(purchasables, SKUS.INDIVIDUAL_ACCOUNT);
  const iraFee = getPurchaseable(purchasables, SKUS.IRA_ACCOUNT);
  const trustFee = getPurchaseable(purchasables, SKUS.TRUST_ACCOUNT);
  const sigPurchaseable = getPurchaseable(purchasables, SKUS.SIGNATURE);

  const prices = {
    individual: individualAcctPurchasable?.usdPrice,
    ira: iraFee?.usdPrice,
    trust: trustFee?.usdPrice,
  };

  const type = accountType.value;

  const goNext = (upgrade: boolean) => {
    if (upgrade) {
      switchOrg(personalOrg.uuid);
      let from = `/onboard/${personalOrg.uuid}`;
      if (type !== "individual") from = `/onboard?accountType=${type}`;
      navigate(`/checkout/signature?from=${from}`);
    } else onboardNewOrg(type);
  };

  const sigMonthly = parseFloat(sigPurchaseable?.usdPrice) / 12;

  const opts = [
    {
      header: "Signature",
      amountTitle: formatDollars(sigMonthly),
      description: `${formatDollars(sigPurchaseable?.usdPrice)} total (paid annually)`,
      amountDescription: "/month",
      features: [
        "Account management",
        "Reduced trading fees",
        "Multiple accounts",
        "Exclusive events",
      ],
      className: "bg-primary-900 border-primary-700 w-[420px]",
      textColor: "text-white",
      titleColor: "text-white",
      accentColor: "text-yellow-400",
      actions: [{ children: "Select", onClick: () => goNext(true) }] as [ButtonProps],
    },
    {
      header: basicHeaders[type],
      amountTitle: formatDollars(prices[type]),
      amountDescription: "/year",
      features: [
        "Cold storage multi-sig security",
        "Access to client support",
        "Support for inheritance plans",
        "Access to trading, loans, and IRA",
      ],
      actions: [{ children: "Continue", onClick: () => goNext(false) }] as [ButtonProps],
      className: "w-[420px]",
    },
  ];
  return (
    <ManifestStep
      title="Upgrade options"
      description="Continue with the basic plan or become a member of our new Signature program"
    >
      <div className="grid max-w-6xl grid-cols-1 gap-4 lg:grid-cols-2">
        {opts.map(tableData => (
          <PricingTable key={tableData.header} {...tableData} />
        ))}
      </div>
    </ManifestStep>
  );
}
