import { useContext } from "react";

import { InputLabel } from "@unchained/component-library";
import { useMutation } from "react-query";

import { Formik, FormikSplitDateSelector } from "Components/FormFields";
import { FormikNameRow } from "Components/FormFields/FormikNameRow";
import { yearsAgo } from "Components/FormFields/FormikSplitDateSelector/FormikSplitDateSelector";
import { ManifestStep } from "Routes/onboard/(shared)";
import {
  initNameAndDob,
  OnboardBasicContext,
  useWrapManifestMutation,
  validateNameAndDob,
} from "Routes/onboard/(utils)";
import { PersonAPI, ProfileAPI } from "Shared/api";
import { toISODatestring } from "Utils/dates";
import { camelCaseKeys } from "Utils/objects";

export const NameAndDob = () => {
  const info = useContext(OnboardBasicContext);
  const { personalOrg, person } = info;
  const wrapManifestMutation = useWrapManifestMutation({ orgUuid: personalOrg.uuid });

  const initVals = initNameAndDob(info);
  const updatePersonInfo = wrapManifestMutation(
    useMutation((values: typeof initVals) =>
      // IRA-constructed API expects camel-cased keys
      PersonAPI.Patch(person.uuid, camelCaseKeys(values))
    )
  );

  return (
    <Formik
      initialValues={initVals}
      onSubmit={async values => {
        // In the edge-case where this hasn't yet activated, try and then update the name etc
        if (personalOrg.state === "pending_activation") {
          ProfileAPI.ActivatePersonal(personalOrg.uuid)
            .then(() => updatePersonInfo.mutate(values))
            .catch(e => {
              console.error(e);
              updatePersonInfo.mutate(values);
            });
        } else {
          updatePersonInfo.mutate(values);
        }
      }}
      validationSchema={validateNameAndDob}
      debugName="NameAndDob"
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="narrow"
            actions={[
              {
                onClick: handleSubmit,
                children: "Continue",
                type: "submit",
              },
            ]}
            title="Legal name and date of birth"
            description="We're required to collect and verify this information."
          >
            <div className="flex w-full flex-col items-start gap-6">
              <FormikNameRow unifiedLabel="Legal name" />

              <div className="w-full" data-testid="date-selector">
                <InputLabel>Date of birth</InputLabel>
                <FormikSplitDateSelector
                  className="mt-1.5"
                  name="dob"
                  prepareDate={toISODatestring}
                  maxDate={yearsAgo(16)}
                />
              </div>
            </div>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
