import {
  REPLACE_ORG_SHOW,
  UPDATE_ORG_BANK_ACCOUNT,
  SET_ORG_SHOW_STATUS,
  RESET_ORG_SHOW,
  UPDATE_ORG_MULTI_INSTITUTION_VAULTS,
  UPDATE_ORG_NOTE,
  UPDATE_ORG_CODENAME,
  UPDATE_ORG_DENIED_REASON,
} from "Actions/orgActions/orgShowActions";
import { REQUEST_STATUS } from "Shared/api/api";

const initialState = {
  org: {
    uuid: "",
    name: "",
    personal: false,
    codename: "",
    type: "client",
    denied_reason: "",
    state: "pending_basic_info",
    advanced_profile_state: "pending_submission",
    multi_institution_vaults: false,

    accepted_members: [],
    pending_members_emails: [],
    pending_members: [],
    pending_signups: [],
    max_members: 1,
    available_memberships: 0,

    child_roles: [],

    bank_accounts: [],

    entity: {
      principals: [],
    },

    allowed_actions: [],
  },
  accountKeys: {},
  vaults: {},
  loans: [],
  basicProfileStatus: REQUEST_STATUS.SUCCESS,
  status: REQUEST_STATUS.PENDING,
};

const updateBankAccount = (state, payload) => {
  let bank_accounts;
  if (state.org.bank_accounts.map(bank_account => bank_account.uuid).includes(payload.uuid)) {
    bank_accounts = state.org.bank_accounts.map(bank_account => {
      return bank_account.uuid === payload.uuid ? payload : bank_account;
    });
  } else {
    bank_accounts = state.org.bank_accounts;
    bank_accounts.push(payload);
  }
  return {
    ...state,
    org: {
      ...state.org,
      bank_accounts,
    },
  };
};

const updatePrincipal = (state, principal) => {
  let newPrincipals = [];
  let updatedPrincipal = false;
  for (let i = 0; i < state.org.entity.principals.length; i++) {
    let existingPrincipal = state.org.entity.principals[i];
    if (existingPrincipal.person && existingPrincipal.person.uuid === principal.person.uuid) {
      newPrincipals.push(principal);
      updatedPrincipal = true;
    } else {
      newPrincipals.push(existingPrincipal);
    }
  }
  if (!updatedPrincipal) {
    newPrincipals.push(principal);
  }
  return {
    ...state,
    org: {
      ...state.org,
      entity: {
        ...state.org.entity,
        principals: newPrincipals,
      },
    },
  };
};

const deletePrincipal = (state, uuid) => {
  let newPrincipals = [];
  for (let i = 0; i < state.org.entity.principals.length; i++) {
    let principal = state.org.entity.principals[i];
    if (principal.person && principal.person.uuid !== uuid) {
      newPrincipals.push(principal);
    }
  }
  return {
    ...state,
    org: {
      ...state.org,
      entity: {
        ...state.org.entity,
        principals: newPrincipals,
      },
    },
  };
};

export const orgShowReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case REPLACE_ORG_SHOW: {
      return {
        ...state,
        org: { ...initialState.org, ...payload },
      };
    }

    case UPDATE_ORG_BANK_ACCOUNT: {
      return updateBankAccount(state, payload);
    }

    case SET_ORG_SHOW_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case RESET_ORG_SHOW: {
      return initialState;
    }

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/STEP/NEXT/SUCCESS": {
      return updatePrincipal(state, action.principal);
    }

    case "PROFILE_CREATE_BUSINESS/PRINCIPAL/DELETE/SUCCESS": {
      return deletePrincipal(state, action.uuid);
    }

    case UPDATE_ORG_MULTI_INSTITUTION_VAULTS: {
      return {
        ...state,
        org: {
          ...state.org,
          multi_institution_vaults: payload,
        },
      };
    }

    case UPDATE_ORG_NOTE: {
      return {
        ...state,
        org: {
          ...state.org,
          note: payload,
        },
      };
    }

    case UPDATE_ORG_CODENAME: {
      return {
        ...state,
        org: {
          ...state.org,
          codename: payload,
        },
      };
    }

    case UPDATE_ORG_DENIED_REASON: {
      return {
        ...state,
        org: {
          ...state.org,
          denied_reason: payload,
        },
      };
    }

    default:
      return state;
  }
};
