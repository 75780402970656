import { AuthorizerName, Authorizers } from "../Authorize/Authorize";
import { mutuallyExclusiveAuthorizers } from "../Authorize/authorizerTemplates";
import { Route } from "./Route";
import { RouteGroup } from "./RouteGroup";

const _mutuallyExclusiveAuthorizersMap = mutuallyExclusiveAuthorizers.reduce(
  (acc, arrayOfAuthorizers) => {
    arrayOfAuthorizers.forEach(authorizer => {
      acc[authorizer] = acc[authorizer] || {};
      arrayOfAuthorizers.forEach(otherAuthorizer => {
        if (authorizer !== otherAuthorizer) {
          acc[authorizer][otherAuthorizer] = true;
        }
      });
    });
    return acc;
  },
  {} as Partial<Record<AuthorizerName, Partial<Record<AuthorizerName, boolean>>>>
);

const _isMutuallyExclusive = (authorizer1: AuthorizerName, authorizer2: AuthorizerName) => {
  return _mutuallyExclusiveAuthorizersMap[authorizer1]?.[authorizer2];
};

/**
 * Returns a list of authorizers that are mutually exclusive with the given authorizer.
 * This is used to determine if a route's authorization is improper.
 */
const _getMutuallyExclusiveAuthorizerNameObjects = (authorizers: Authorizers) => {
  const authorizerNames = Object.keys(authorizers) as AuthorizerName[];
  const allClashes = {};

  while (authorizerNames.length > 0) {
    const authorizer = authorizerNames.shift();
    const clashes = authorizerNames.filter(otherAuthorizer =>
      _isMutuallyExclusive(authorizer, otherAuthorizer)
    );

    if (clashes.length > 0) {
      clashes.forEach(clash => {
        authorizerNames.splice(authorizerNames.indexOf(clash), 1);
      });
      allClashes[authorizer] = clashes;
    }
  }

  return allClashes;
};

// Helper to merge authorizers, taking into account unblock directives
export const mergeAuthorizers = (
  parentAuthorizers: Authorizers | undefined,
  route: Route | RouteGroup
): { merged: Authorizers; errors: string[] } => {
  const errors = [];
  const parent = { ...parentAuthorizers };

  // If removed authorizers are specified, remove those authorizers before proceeding
  if ("_DEPRECATED_removedAuthorizers" in route && route._DEPRECATED_removedAuthorizers) {
    route._DEPRECATED_removedAuthorizers.forEach(key => {
      delete parent[key];
    });
  }

  const redundantAuthorizers = Object.keys(route._authorizers || {}).filter(
    k => parent?.[k] && !k.includes("Replacement")
  );

  if (redundantAuthorizers.length > 0) {
    errors.push(
      `Redundant authorizers declared in route and parent: ${redundantAuthorizers.join(", ")}`
    );
  }

  const merged = {
    ...parent,
    ...route._authorizers,
  };

  const authorizerClashes = _getMutuallyExclusiveAuthorizerNameObjects(merged);
  if (Object.keys(authorizerClashes).length > 0) {
    errors.push(
      `Mutually exclusive authorizers:\n- ${Object.keys(authorizerClashes)
        .map(authorizer => [authorizer, ...authorizerClashes[authorizer]].join(", "))
        .join("\n- ")}`
    );
  }

  return { merged, errors };
};
