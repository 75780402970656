import React from "react";

import { Grid, Paper } from "@mui/material";
import { Button } from "@unchained/component-library";
import PropTypes from "prop-types";

import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";

import { DEVICE_INTERACTION_TYPE_ENUM } from "../Support/SupportData";
import styles from "./DeviceInteractionStep.module.scss";

const displayContent = ({ content, interactionType }) => {
  if (content && content[interactionType]) {
    return content[interactionType];
  }
  if (content && content.default) {
    return content.default;
  }
  return content;
};

const SlideDeviceIssue = ({
  interactionType,
  title,
  content,
  buttonText = "Continue",
  onClick,
}) => {
  const issueContent = displayContent({ interactionType, content });

  return (
    <Paper className={styles.slideContent}>
      <WizardComponentWrapper classes={styles.issueWrapper}>
        <Grid container spacing={3} direction="column" alignItems="center">
          <Grid item>
            <h2>{title}</h2>
          </Grid>
          <Grid item>{issueContent}</Grid>
          <Grid item>
            <Button onClick={onClick}>{buttonText}</Button>
          </Grid>
        </Grid>
      </WizardComponentWrapper>
    </Paper>
  );
};

const propTypeShape = {};
propTypeShape[DEVICE_INTERACTION_TYPE_ENUM.CHECKING] = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
  PropTypes.func,
]);
propTypeShape[DEVICE_INTERACTION_TYPE_ENUM.SIGNING] = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
  PropTypes.func,
]);
propTypeShape[DEVICE_INTERACTION_TYPE_ENUM.CONFIRMING] = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.element,
  PropTypes.func,
]);

SlideDeviceIssue.propTypes = {
  interactionType: PropTypes.oneOf([
    DEVICE_INTERACTION_TYPE_ENUM.CONFIRMING,
    DEVICE_INTERACTION_TYPE_ENUM.CHECKING,
    DEVICE_INTERACTION_TYPE_ENUM.SIGNING,
  ]).isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.shape({
    default: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.func]),
    ...propTypeShape,
  }),
};

export { SlideDeviceIssue };
