import { ChangeEvent, useState, useContext } from "react";

import {
  RadioGroup,
  RadioButton,
  Button,
  WizardStepperContext,
} from "@unchained/component-library";

import { StepHeader } from "../components/StepHeader";
import { WithdrawalIraTypes } from "../types";

type SelectTypeStepProps = {
  selectedIraWithdrawalType: WithdrawalIraTypes;
  setSelectedIraWithdrawalType: (iraWithdrawalType: WithdrawalIraTypes) => void;
};
export const SelectTypeStep = ({
  selectedIraWithdrawalType,
  setSelectedIraWithdrawalType,
}: SelectTypeStepProps) => {
  const { goToNext } = useContext(WizardStepperContext);
  const [iraWithdrawalType, setIraWithdrawalType] = useState<undefined | WithdrawalIraTypes>(
    selectedIraWithdrawalType
  );

  const handleSelectedType = (event: ChangeEvent<HTMLInputElement>) => {
    setIraWithdrawalType(event.target.value as WithdrawalIraTypes);
  };
  const openLearnMore = (url: string) => {
    window.open(url, "_blank");
  };

  const navigateToNextStep = () => {
    setSelectedIraWithdrawalType(iraWithdrawalType);
    goToNext();
  };

  const Description = ({ content, url }: { content: string; url: string }) => {
    return (
      <div className="max-w-md px-4">
        <p className="mb-4 max-w-sm text-md font-reg">{content}</p>
        <Button onClick={() => openLearnMore(url)} variant="text">
          Learn more
        </Button>
      </div>
    );
  };

  return (
    <div className="p-3">
      <StepHeader title="Select type" isBackButtonShowing={false} className="mb-6" />
      <RadioGroup
        onChange={handleSelectedType}
        value={iraWithdrawalType}
        name="numbers"
        className="flex flex-col gap-3"
      >
        <RadioButton
          label="Distribution"
          className="mb-3 px-4 pb-4 pt-1"
          description={
            <Description
              content="Speak to your tax advisor before taking a distribution. Note: in-kind bitcoin distributions are allowed."
              url="https://help.unchained.com/how-do-i-take-a-distribution-from-my-unchained-ira"
            />
          }
          value={WithdrawalIraTypes.DISTRIBUTION}
        />
        <RadioButton
          label="Outbound transfer"
          className="mb-3 px-4 pb-4 pt-1"
          value={WithdrawalIraTypes.TRANSFER_OUT}
          description={
            <Description
              content="Transfer USD or BTC from your Unchained IRA into another IRA. Note: not all receiving custodians support in-kind BTC transfers."
              url="https://help.unchained.com/can-i-transfer-my-unchained-ira"
            />
          }
        />
      </RadioGroup>

      <Button
        disabled={!iraWithdrawalType}
        fullWidth
        onClick={navigateToNextStep}
        color="primary"
        className="mt-6"
      >
        Continue
      </Button>
    </div>
  );
};
