import { Link, LinkProps } from ".";
import { Icons } from "@unchained/component-library";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { navigationHistorySelector } from "Redux/selectors/navigation";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";

/**
 * Given the workspace user and the path history,
 * returns a path to navigate back to (if possible),
 * or returns to the given fallback route
 * or the appropriate home route for the user type.
 */
export const useBackRoute = (fallbackRoute?: string) => {
  const {
    data: { user },
  } = useWorkspaceData();
  const stack = useSelector(navigationHistorySelector);
  const previousPath = stack[stack.length - 2];
  const { pathname: currentPath } = useLocation();

  const homeRoute = (() => {
    if (user.canViewUnifiedIA) return "/accounts/all";
    if (user.isUnchainedAdmin) return "/settings";
    return "/home";
  })();

  return previousPath && previousPath !== currentPath ? previousPath : fallbackRoute || homeRoute;
};

/**
 * A link component that navigates back (if possible) or returns to the given fallback route
 * or the appropriate home route for the user type.
 */
export const LinkBack = withWorkspaceData<
  Omit<LinkProps, "to"> & { fallbackRoute?: string; hideArrow?: boolean }
>(({ user, children, fallbackRoute, hideArrow, ...props }) => {
  const to = useBackRoute(fallbackRoute);

  const content = hideArrow ? (
    children
  ) : (
    <span className="flex flex-row items-center gap-2 font-semi transition-colors group-hover:text-primary-800">
      <Icons.ArrowLeft />
      <span className="transition-all group-hover:ml-1">Back</span>
    </span>
  );

  return (
    <Link {...props} to={to} className="group flex flex-col gap-2 no-underline hover:no-underline">
      {content}
    </Link>
  );
});
