import React from "react";

import { Button } from "@unchained/component-library";
import { capitalize } from "lodash";
import { useDispatch } from "react-redux";

import { setSpendingSigRequestAction } from "Actions/transactionActions/spendingActions";
import {
  sigRequestApprovalStrings,
  useSigReqApprovalAction,
} from "Components/BtcTransactionRequests/utils";
import wizStyles from "Components/Shared/wizard/WizardComponent.module.scss";
import { WizardComponentWrapper } from "Components/Shared/wizard/WizardComponentWrapper";
import { useSigReqPermission } from "Permissions/hooks";
import { useSelectedSigRequest } from "Redux/selectors/spendingSelectors";
import { useBtcTxSigRequestMutation } from "Shared/api/hooks";
import { SigRequest } from "Specs/v1/getTransactionRequests/200";
import { arbiterInfo } from "Utils/arbiter";

import styles from "./NotRequiredForSigning.module.scss";

const AdminApprovalButton = ({ sigRequest }) => {
  const dispatch = useDispatch();
  const action = useSigReqApprovalAction(sigRequest);
  const btcTxSigRequestMutation = useBtcTxSigRequestMutation(action === "approve", sigRequest.uuid);
  const approvals = sigRequestApprovalStrings(sigRequest);

  return (
    <>
      <p>
        <em>
          {approvals.length
            ? `This signature request has been ${approvals.join(" and ")} approved.`
            : "This signature request has received no approvals."}
        </em>
      </p>
      {action ? (
        <div className="flex flex-col items-center justify-center">
          <Button
            color={action === "approve" ? "primary" : "destructive"}
            onClick={() =>
              btcTxSigRequestMutation.mutate(undefined, {
                onSuccess: resp => {
                  dispatch(setSpendingSigRequestAction(resp.btc_tx_sig_request));
                },
                onError: console.error,
              })
            }
            className="mt-4"
          >
            {capitalize(action)}
          </Button>
        </div>
      ) : null}
    </>
  );
};

export const WaitingForSigning: React.FC = () => {
  const selectedSigRequest: SigRequest = useSelectedSigRequest();
  const sigReqPermish = useSigReqPermission(selectedSigRequest);

  const content = () => {
    const { account_key } = selectedSigRequest;

    const conditionallyShownApproveButton = Boolean(sigReqPermish.approvalAction()) ? (
      <AdminApprovalButton sigRequest={selectedSigRequest} />
    ) : null;

    const text =
      {
        unchained: "Unchained typically completes transaction requests within 3 business days.",
        arbiter: `${arbiterInfo.name} typically completes transaction requests within 3 business days.`,
      }[account_key.role] || "Waiting for a signature from this key.";

    return (
      <div>
        <p className={styles.content}>{text}</p>
        {conditionallyShownApproveButton}
      </div>
    );
  };

  return (
    <WizardComponentWrapper size="sm">
      <h3 className={styles.smTitle}>Waiting for Signature</h3>

      <div className={wizStyles.response}>{content()}</div>
    </WizardComponentWrapper>
  );
};
