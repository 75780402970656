interface NavigationState {
  locationStack: string[];
}

const initialState: NavigationState = {
  locationStack: [],
};

export const PUSH_LOCATION = "navigation/pushLocation";

interface PushLocationAction {
  type: typeof PUSH_LOCATION;
  payload: string;
}

export const pushLocation = (path: string): PushLocationAction => ({
  type: PUSH_LOCATION,
  payload: path,
});

export default function navigationReducer(
  state = initialState,
  action: PushLocationAction
): NavigationState {
  switch (action.type) {
    case PUSH_LOCATION:
      if (state.locationStack[state.locationStack.length - 1] !== action.payload) {
        const newStack = [...state.locationStack, action.payload];
        // Keep last 5 locations
        return {
          locationStack: newStack.slice(-5),
        };
      }
      return state;
    default:
      return state;
  }
}
