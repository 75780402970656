import { ReactNode } from "react";

import { Icons } from "@unchained/component-library";
import classNames from "classnames";

import { Link } from "Components/Link";

import styles from "./ExploreBlockChain.module.scss";

export type ExploreBlockChainClasses = {
  div?: string;
  button?: string;
  icon?: string;
  text?: string;
};
export const ExploreBlockChain = ({
  item,
  url,
  showItem = true,
  classes = {},
}: {
  item: string | ReactNode;
  url: string;
  showItem: boolean;
  classes?: ExploreBlockChainClasses;
}) => {
  return (
    <div className={classes.div || styles.txid}>
      {showItem && <code className={classNames("break-words", classes.text)}>{item}</code>}
      <Link to={url} target="_blank" className="text-gray-700 hover:text-gray-700">
        <Icons.SearchMd className={classes.icon || styles.button} />
      </Link>
    </div>
  );
};
