import React, { ReactNode } from "react";

import styles from "./InputLabel.module.scss";

interface InputLabelProps {
  label: string;
  children?: ReactNode;
  className?: string;
}

const InputLabel = ({ label, children, className = styles.container }: InputLabelProps) => {
  return (
    <div className={className}>
      {label ? <span className="text-sm font-med text-gray-600">{label} </span> : null}
      {children}
    </div>
  );
};

export { InputLabel };
