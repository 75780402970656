import { SignIcon } from "Components/Shared/Icons/SignIcon";
import { btcOperationTypes } from "Utils/enums";
import { generateOperationVerbClause, operationVerbClauseMap } from "Utils/verbs";

const ApproveSignatureRequestNotification = ({
  operation_type,
  product_name,
  account_key_name,
}: {
  operation_type: keyof typeof operationVerbClauseMap;
  product_name: string;
  account_key_name: string;
}) => {
  const isSaleTransaction = operation_type === btcOperationTypes.vault_sale_transaction;
  const operationVerbClause = generateOperationVerbClause(operation_type);
  const title = isSaleTransaction
    ? `Approve pending sell transaction from ${product_name}`
    : `Approve signing with key ${account_key_name} for ${operationVerbClause} ${product_name}`;

  return {
    to: "/btc_transaction_requests",
    title: title,
    Icon: SignIcon,
  };
};

export { ApproveSignatureRequestNotification };
