import { ReactNode } from "react";

import { HeroIcon, HeroIconSize, Icons } from "@unchained/component-library";
import cn from "classnames";

import { ClientReportLink, useIsSignatureOrCO } from "Contexts/ClientReporter/ClientReportModal";

type ErrorPageProps = {
  message?: ReactNode;
  size?: HeroIconSize;
  classes?: {
    wrapper?: string;
    icon?: string;
    message?: string;
  };
  styles?: {
    wrapper?: React.CSSProperties;
    icon?: React.CSSProperties;
    message?: React.CSSProperties;
  };
};

export const ErrorPage = ({
  message = "Something went wrong.",
  size = "md",
  classes = {},
  styles = {},
}: ErrorPageProps) => {
  // It doesn't matter if the user has hit errors previously; they just did.
  // So show the client report link if they're sig or CO.
  const isSigOrCO = useIsSignatureOrCO();
  const messageIsString = typeof message === "string";

  return (
    <div
      className={cn(
        "flex h-full w-full flex-col items-center justify-center gap-4 py-8",
        classes.wrapper
      )}
      style={styles.wrapper}
    >
      <HeroIcon
        color="red"
        Icon={Icons.AlertCircle}
        size={size || "md"}
        className={classes.icon}
        style={styles.icon}
      />
      <div className={classes.message} style={styles.message}>
        {messageIsString ? (
          <span className="text-center">
            <h4 className="mb-2">{message}</h4>
            {isSigOrCO ? (
              <>
                <ClientReportLink>Share error details</ClientReportLink> with Unchained.
              </>
            ) : null}
          </span>
        ) : (
          message
        )}
      </div>
    </div>
  );
};
