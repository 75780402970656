import { useParams } from "react-router-dom";

import { useLocalStorageOrgUuid } from "Contexts/CurrentOrgUuidContext";
import { useMemoizedState } from "Redux/selectors/hooks";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { OrgItem } from "Specs/v2/components";

export const useCurrentOrgUuid = () => {
  const { accountId } = useParams();
  const context = useLocalStorageOrgUuid();

  return accountId || context.value;
};

/**
 * Grabs the current org, either the one specified in the URL (workspace org) or
 * a subset of fields from the org in the redux store.
 */
export const useLightweightCurrentOrg = ():
  | Pick<OrgItem, "id" | "state" | "accountType" | "type">
  | undefined => {
  const { accountId } = useParams();
  const workspaceData = useWorkspaceData();
  const reduxCurrentOrg = useMemoizedState("account.orgs.current") as CompleteOrg;

  if (accountId) {
    return workspaceData.data?.orgs?.find(o => o.id === accountId);
  }

  return {
    id: reduxCurrentOrg.uuid,
    state: reduxCurrentOrg.state,
    accountType: reduxCurrentOrg.account_type,
    type: reduxCurrentOrg.type,
  } as OrgItem;
};
