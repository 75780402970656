import React, { useCallback, useEffect } from "react";

import { BuyBitcoinModal, PreviewOrderModal, OrderSummaryModal, LoadingModal } from ".";

import { Wizard } from "Components/Shared/wizard/Wizard";
import {
  WEB_SOCKET_CLOSED,
  WEB_SOCKET_OPEN,
  clearTradingState,
  setModalStatus,
  WEB_SOCKET_FAILURE_MODAL_STATUS,
  TRADE_CONFIRMATION_REJECTED,
  TRADE_CONFIRMATION_NULL,
  TRADE_CONFIRMATION_REJECTED_MODAL_STATUS,
  setTradeConfirmationStatus,
  TRADE_CONFIRMATION_ACCEPTED,
} from "Contexts/BuyBitcoin";
import {
  BuyBitcoinWebSocketProvider,
  useBuyBitcoinStore,
  useBuyBitcoinDispatch,
} from "Contexts/BuyBitcoin/buyBitcoinContext";
import { DestinationVault } from "Contexts/BuyBitcoin/types";

interface BuyBitcoinWizardProps {
  defaultVault: DestinationVault;
  setShowModalCloseButton: boolean;
}
/**
 * Component which displays a buybitcoin modal and allows navigation between related modals.
 * @param {string} defaultVault - Initial selected vault in the vault select drop down.
 * @param {Function} setShowModalCloseButton - Function to set Boolean value to show or hide the modal close button.
 * @returns {React.Component}
 */
const BuyBitcoinWizard = ({ defaultVault, setShowModalCloseButton }: BuyBitcoinWizardProps) => {
  const dispatch = useBuyBitcoinDispatch();

  const { webSocketStatus, tradeConfirmationStatus } = useBuyBitcoinStore();
  const isWebSocketOpen = webSocketStatus === WEB_SOCKET_OPEN;
  const isWebSocketClosed = webSocketStatus === WEB_SOCKET_CLOSED;

  /**
   * If the web socket was closed and the trade did not get accepted
   * something must have went wrong, therefore clear the trading state and set the modalStatus
   * to WEB_SOCKET_FAILURE_MODAL_STATUS, which will notify the BuyBitcoinBaseModal to show an ErrorModal.
   */

  useEffect(() => {
    if (isWebSocketClosed && tradeConfirmationStatus !== TRADE_CONFIRMATION_ACCEPTED) {
      dispatch(clearTradingState());
      dispatch(setModalStatus(WEB_SOCKET_FAILURE_MODAL_STATUS));
    }
  }, [isWebSocketClosed, tradeConfirmationStatus, dispatch]);

  /**
   * If the trade has been rejected, clear the current state and
   * set the modal status to TRADE_CONFIRMATION_REJECTED_MODAL_STATUS
   * which will notify the BuyBitcoinBaseModal to show an ErrorModal.
   */
  useEffect(() => {
    if (tradeConfirmationStatus === TRADE_CONFIRMATION_REJECTED) {
      dispatch(setModalStatus(TRADE_CONFIRMATION_REJECTED_MODAL_STATUS));
      dispatch(clearTradingState());
    }
  }, [dispatch, tradeConfirmationStatus]);

  /**
   * The BuyBitcoinWizard is unmounting and therefore the
   * trading state should be set to it's initial null state.
   */
  useEffect(() => {
    return () => {
      dispatch(setTradeConfirmationStatus(TRADE_CONFIRMATION_NULL));
    };
  }, [dispatch]);

  /**
   * If the web socket is still not open after 14 seconds we can assume
   * something has gone wrong, and set the modal status to WEB_SOCKET_FAILURE_MODAL_STATUS,
   * which will notify the BuyBitcoinBaseModal to show an ErrorModal.
   */
  const socketLoadingTimeout = useCallback(() => {
    return setTimeout(() => {
      dispatch(setModalStatus(WEB_SOCKET_FAILURE_MODAL_STATUS));
    }, 14000);
  }, [dispatch]);

  const selectModalFromWebSocketStatus = useCallback(() => {
    const manifest = [
      {
        component: BuyBitcoinModal,
      },
      {
        component: PreviewOrderModal,
      },
      {
        component: OrderSummaryModal,
      },
    ];

    const navigateToPreviousModal = (data: { nextIndex: number; componentData: any }) => {
      return data.nextIndex;
    };

    let displayModal: JSX.Element;
    // If a web socket is open the user should be able
    // to access the trade modals.
    // Or if a trade has been accepted we are not concerned that the
    // web socket is closed and the user is on the OrderSummaryModal or WireInstructionsModal.
    if (isWebSocketOpen || tradeConfirmationStatus === TRADE_CONFIRMATION_ACCEPTED) {
      displayModal = (
        <Wizard
          childProps={{ defaultVault, setShowModalCloseButton }}
          manifest={manifest}
          onBackComponent={navigateToPreviousModal}
        />
      );
    }
    // If a web socket is neither open or closed, and no trade has been accepted or rejected
    // we are in a loading state.
    else {
      displayModal = (
        <LoadingModal
          timeoutHandler={socketLoadingTimeout}
          waitingText="Trading session initiating"
        />
      );
    }
    return displayModal;
  }, [
    defaultVault,
    isWebSocketOpen,
    tradeConfirmationStatus,
    socketLoadingTimeout,
    setShowModalCloseButton,
  ]);

  return (
    <BuyBitcoinWebSocketProvider>{selectModalFromWebSocketStatus()}</BuyBitcoinWebSocketProvider>
  );
};

export { BuyBitcoinWizard };
