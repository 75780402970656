import { createContext, ReactNode, useContext, useEffect, useMemo } from "react";

import { GetSetObj, useMakeGetSetObj } from "Routes/onboard/(utils)";

type LoadingContextType = {
  loading?: GetSetObj<boolean>;
};

export const LoadingContext = createContext<LoadingContextType>({
  loading: undefined,
});

export const useLoadingContext = () => {
  const { loading } = useContext(LoadingContext);
  return loading;
};

export const LoadingContextProvider = ({
  loading: externalLoadingVal,
  children,
}: {
  loading?: boolean;
  children: ReactNode;
}) => {
  const loading = useMakeGetSetObj(externalLoadingVal || false);

  // Changes to the incoming external loading value should update the context
  useEffect(() => {
    loading.set(externalLoadingVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [externalLoadingVal]);

  const value = useMemo(() => ({ loading }), [loading]);

  return <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>;
};

export const withLoadingContext =
  (Component: React.ComponentType) =>
  (props: any = {}) =>
    (
      <LoadingContextProvider>
        <Component {...props} />
      </LoadingContextProvider>
    );
