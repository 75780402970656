import { UseQueryResult } from "react-query";

import { GetOrgProducts200 } from "Specs/v2";

export const amountsFromProductQuery = (
  query: UseQueryResult<GetOrgProducts200 & { rollup?: boolean }>,
  bitcoinPrice: number
) => {
  let bitcoin = 0;
  let cash = 0;

  if (query.data && !query.isLoading && query.data.rollup !== false) {
    const { vaults = [], pockets = [], cashBalances = [] } = query.data;
    vaults.forEach(vault => {
      bitcoin += parseFloat(vault.balance || "0");
    });
    pockets.forEach(pocket => {
      bitcoin += parseFloat(pocket.balance || "0");
    });
    cashBalances.forEach(cashBalance => {
      cash += parseFloat(cashBalance.cashBalance || "0");
    });
    // Loans do not have "balances" in the same way as other products
  }

  return {
    bitcoin,
    cash,
    total: cash + bitcoin * bitcoinPrice,
  };
};
