import { createBrowserRouter } from "react-router-dom";

import { RootAuthHandler } from "./RootAuthHandler";
import { RouteGroup } from "./RouteGroup";
import { createReactRouterObject } from "./createReactRouterObject";

/**
 * Generates a complete/final router from our route config.
 * This is the main entry point for our route configuration.
 * It also performs final validation on the route config,
 * and reports errors if something is wrong with the config.
 */
export const generateRouter = (routes: RouteGroup) => {
  try {
    const appRoutes = createReactRouterObject("/", routes);

    // Create the router from our configuration
    return createBrowserRouter([
      {
        Component: RootAuthHandler,
        path: "/",
        children: appRoutes.children,
      },
    ]);
  } catch (error) {
    console.error(error);
    // Create a simple error router that displays the validation error
    // if anything is wrong with the config when spinning up the app.
    return createBrowserRouter([
      {
        path: "*",
        element: (
          <div className="flex h-screen w-full flex-col items-center justify-center bg-red-100 p-2">
            <h1 className="mb-4">Route Configuration Error</h1>
            <pre>{error.message}</pre>
          </div>
        ),
      },
    ]);
  }
};
