import React, { useEffect, useState } from "react";

import { useToast, Toast, Modal } from "@unchained/component-library";
import { useSelector } from "react-redux";

import { useNavigate } from "Components/Link";
import { useBuyBitcoinDispatch, useBuyBitcoinStore } from "Contexts/BuyBitcoin";
import { setOutstandingTradeIds } from "Contexts/BuyBitcoin/buyBitcoinActions";
import { TradingAPI } from "Shared/api";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { useCurrentOrgUuid } from "Utils/hooks/useCurrentOrg";

const WIRE_DUE_TOAST_SESSION = "WIRE_DUE_TOAST_SESSION";

const TradeWirePaymentDueToast = () => {
  const { enqueueToast, closeToast } = useToast();
  const [hasEnqueuedToast, setHasEnqueuedToast] = useState(false);
  const { showWireDueToast } = useBuyBitcoinStore();

  const workspaceData = useWorkspaceData();
  const isUnifiedIA = workspaceData?.data?.user?.canViewUnifiedIA;

  const orgUuid: string = useCurrentOrgUuid();
  const navigation = useNavigate();
  const dispatch = useBuyBitcoinDispatch();

  const getOutstandingTradeIds = React.useCallback(async () => {
    try {
      if (orgUuid) {
        const { jwt } = await TradingAPI.StartTradingSession(orgUuid);

        const tradingData = await TradingAPI.GetTradingInfo(jwt);
        const outstandingTradeIds = tradingData?.offlineStatus?.tradeIds;

        dispatch(setOutstandingTradeIds(outstandingTradeIds));
      }
    } catch (err) {
      dispatch(setOutstandingTradeIds([]));
    }
  }, [dispatch, orgUuid]);

  /**
   *  When the close button is clicked, close the toast and set the current session to local storage
   *  in order to track which session the Toast has already been shown and closed for.
   */
  const closeWireToast = React.useCallback(
    key => {
      window.localStorage.setItem(WIRE_DUE_TOAST_SESSION, showWireDueToast.session);
      closeToast(key);
    },
    [closeToast, showWireDueToast]
  );

  const enqueWireAmountDueToast = React.useCallback(() => {
    if (orgUuid) {
      enqueueToast("Wire amount due", {
        persist: true,
        content: key => (
          <Toast
            type="warning"
            title={"Outstanding amount due"}
            description={
              "You have one or more unsettled trades for which payment is yet to be received."
            }
            onClose={() => closeWireToast(key)}
            actions={[
              {
                label: "View payment instructions",
                action: () => {
                  navigation(
                    isUnifiedIA
                      ? `/accounts/${orgUuid}/cash-balance?slideout=deposit`
                      : "/cash-balance?slideout=deposit"
                  );
                  closeWireToast(key);
                },
              },
            ]}
          />
        ),
      });
    }
  }, [closeWireToast, enqueueToast, navigation, orgUuid, isUnifiedIA]);

  /**
   * A hook controlling the firing of the wire due toast.
   *
   * Show the toast if all are true, the
   * -  current session is not equal to the previous session the wire toast was shown,
   * -  the showWireDueToast.isShow is true because the user owes unchained a wire.
   * -  the toast has not been fired and closed yet this session,
   */
  useEffect(() => {
    const previousWireDueToastSession = window.localStorage.getItem(WIRE_DUE_TOAST_SESSION);

    const isNewWireToastSession = previousWireDueToastSession !== showWireDueToast.session;

    if (!hasEnqueuedToast && showWireDueToast.isShow && isNewWireToastSession) {
      getOutstandingTradeIds();
      setHasEnqueuedToast(true);
      enqueWireAmountDueToast();
    }
  }, [hasEnqueuedToast, enqueWireAmountDueToast, showWireDueToast, getOutstandingTradeIds]);
  return <></>;
};

export { TradeWirePaymentDueToast };
