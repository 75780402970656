/* eslint-disable-next-line no-restricted-imports */
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { Button, ButtonProps } from "@unchained/component-library";
import classnames from "classnames";

interface WizardNavButtonsProps {
  onNext?: () => void;
  onBack?: () => void;
  hideBack?: boolean;
  hideNext?: boolean;
  nextType?: "button" | "submit" | "reset";
  disableNext?: boolean;
  nextText?: string;
  nextIcon?: boolean;
  backText?: string;
  nextColor?: "primary" | "secondary" | "destructive";
  wrapperClass?: string;
  nextProps?: Partial<ButtonProps>;
  backProps?: Partial<ButtonProps>;
}

export const WizardNavButtons = ({
  onNext,
  onBack,
  hideBack = false,
  hideNext = false,
  nextType,
  disableNext = false,
  nextText = "Next",
  nextIcon = true,
  backText = "Back",
  nextColor = "primary",
  wrapperClass = "",
  nextProps = {},
  backProps = {},
}: WizardNavButtonsProps) => {
  return (
    <div
      className={classnames(
        wrapperClass,
        "mb-0 mt-6 flex w-full min-w-[250px] justify-around md:min-w-[380px]"
      )}
    >
      {hideBack ? null : (
        <Button onClick={onBack} {...backProps} startIcon={<ArrowBack />} color="secondary">
          {backText}
        </Button>
      )}
      {hideNext ? null : (
        <Button
          {...nextProps}
          type={nextType}
          onClick={onNext}
          color={nextColor}
          disabled={disableNext}
          endIcon={nextIcon && <ArrowForward />}
        >
          {nextText}
        </Button>
      )}
    </div>
  );
};
