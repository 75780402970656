import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { OrgAPI } from "Shared/api";
import { v2NotificationsQueryKeys } from "Shared/api/v2/hooks/notifications";
import { workspaceQueryKeys } from "Shared/api/v2/hooks/workspaces";
import { AppModalManager } from "Shared/components/Modals";
import { useEasyToasts } from "Utils/toasts";

export const BizMembershipInviteModal = ({ name, uuid }: { name: string; uuid: string }) => {
  const queryClient = useQueryClient();
  const { showErrorToast, showApiSuccessToast } = useEasyToasts();

  const onSettled = () => {
    queryClient.invalidateQueries({ queryKey: workspaceQueryKeys.get });
    AppModalManager.close();
  };

  const onSuccess = (verb: string) => () => {
    queryClient.invalidateQueries({ queryKey: v2NotificationsQueryKeys.aggregated });
    showApiSuccessToast({
      title: `Invitation ${verb}`,
      message: `You've ${verb} the invitation to join the organization.`,
    });
  };

  const acceptInvitation = useMutation(() => OrgAPI.AcceptInvitation(uuid), {
    onSuccess: () => {
      onSuccess("accepted");
    },
    onError: err => showErrorToast(err),
    onSettled,
  });
  const rejectInvitation = useMutation(() => OrgAPI.RejectInvitation(uuid), {
    onSuccess: () => {
      onSuccess("rejected");
    },
    onError: err => showErrorToast(err),
    onSettled,
  });

  return (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>You've been invited to join {name}</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <p>Accept or reject the invitation below.</p>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: "Accept",
            onClick: () => acceptInvitation.mutate(),
          },
          {
            children: "Reject",
            color: "destructive",
            onClick: () => rejectInvitation.mutate(),
          },
        ]}
      />
    </Modal>
  );
};
