import React, { useMemo, useState } from "react";

import { BCURDecoder } from "@caravan/wallets";
import { LinearProgress } from "@mui/material";
import { Button } from "@unchained/component-library";
import QrReader from "@unchained/react-qr-reader";
import PropTypes from "prop-types";

const QR_CODE_READER_DELAY = 100; // ms?

export const HermitQRCodeReader = ({ onSuccess, onError, onCancel, width = "640px" }) => {
  // From @caravan/wallets -- does all the work of gluing together
  // BC-UR parts and decoding the result.
  //
  // Use `useMemo` because we want the decoder instance to persist
  // over the lifecycle of this component.
  const decoder = useMemo(() => new BCURDecoder(), []);

  // To track our progress
  const [totalParts, setTotalParts] = useState(0);
  const [partsReceived, setPartsReceived] = useState(0);
  const [percentageReceived, setPercentageReceived] = useState(0);

  const handleScan = qrCodeString => {
    if (qrCodeString) {
      decoder.receivePart(qrCodeString);
      const progress = decoder.progress();
      setTotalParts(progress.totalParts);
      setPartsReceived(progress.partsReceived);

      const newPercentageReceived =
        progress.totalParts > 0 ? (progress.partsReceived / progress.totalParts) * 100 : 0;
      setPercentageReceived(newPercentageReceived);

      if (decoder.isComplete()) {
        if (decoder.isSuccess()) {
          const data = decoder.data();
          onSuccess(data);
        } else {
          const errorMessage = decoder.errorMessage();
          onError(errorMessage);
        }
      }
    }
  };

  return (
    <div style={{ padding: "3rem" }}>
      <QrReader
        delay={QR_CODE_READER_DELAY}
        onError={onError}
        onScan={handleScan}
        style={{ width, marginRight: "auto", marginLeft: "auto", marginBottom: "1rem" }}
        facingMode="user"
        showFeedback
        showViewFinder={false}
      />
      {percentageReceived === 0 ? (
        <div>
          <LinearProgress />
          <p>Waiting for first QR code...</p>
        </div>
      ) : (
        <div>
          <LinearProgress variant="determinate" value={percentageReceived} />
          <p>
            Scanned {partsReceived} of {totalParts} QR codes...
          </p>
        </div>
      )}
      <Button color="destructive" onClick={onCancel}>
        Cancel
      </Button>
    </div>
  );
};

HermitQRCodeReader.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  width: PropTypes.string,
};
