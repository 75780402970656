import { Button } from "@unchained/component-library";

import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";

import { SellHeader } from "../components/SellHeader";

type InsufficientVaultBalanceStep = {
  onClose: () => void;
};
export const InsufficientVaultBalanceStep = ({ onClose }: InsufficientVaultBalanceStep) => {
  const { offlineStatus } = useSellBitcoinStore();
  return (
    <div className="w-11/12 max-w-lg">
      <SellHeader title={offlineStatus.title} subTitle={offlineStatus.description} />
      <Button onClick={onClose} fullWidth={true} color={"secondary"}>
        Close
      </Button>
    </div>
  );
};
