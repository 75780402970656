import { useQuery, UseQueryOptions } from "react-query";

import { Config200, GetPublicConfig200 } from "Specs/v2";

import { ConfigsAPI } from "..";

export const configQueryKeys = {
  get: ["config"],
};

/** Requests the v2 config for the current user. */
export const useConfigData = () =>
  useQuery<Config200>(configQueryKeys.get, () => ConfigsAPI.GetConfigData());

/** Requests the v2 public config for the current session. */
export const usePublicConfigData = () =>
  useQuery<GetPublicConfig200>(configQueryKeys.get, () => ConfigsAPI.GetPublicConfigData());
