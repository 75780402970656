import { useEffect } from "react";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Loader } from "@unchained/component-library";
import { useIdleTimer } from "react-idle-timer";
import { useLocation } from "react-router-dom";

import GlobalErrorBoundary from "Components/ErrorBoundaries/GlobalErrorBoundary";
import Flash from "Components/Flash";
import { Layout } from "Components/Layout";
import { Overlay } from "Components/Layout/Overlay";
import { useNavigate } from "Components/Link";
import { TradeWirePaymentDueToast } from "Components/TradingDesk/components";
import { BuyBitcoinProvider } from "Contexts/BuyBitcoin";
import { ClientReporter } from "Contexts/ClientReporter/ClientReporter";
import { SellBitcoinProvider } from "Contexts/SellBitcoin/sellBitcoinContext";
import { useMemoizedState } from "Redux/selectors/hooks";
import { REQUEST_STATUS, useGetBtcPriceQuery } from "Shared/api";
import { API, API_V2 } from "Shared/api/api";
import { withAccount } from "Shared/api/hooks/account";
import { usePublicConfigData } from "Shared/api/v2/hooks/config";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { SlideoutContainer } from "Shared/components/SlideoutManager";
import { WorkspaceUser } from "Specs/v2/components";
import { setCsrfToken } from "Utils/ajax";
import { ACTIVE_TIMEOUT, isActive, setActive } from "Utils/user";

import { AppModalManager } from "../shared/components/Modals/AppModalManager";
import { ModalContainer } from "../shared/components/Modals/ModalContainer";
import { NavigationTracker } from "./NavigationTracker";

const { SUCCESS } = REQUEST_STATUS;

const UserAppBase = ({ workspaceUser }: { workspaceUser: WorkspaceUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const publicConfig = usePublicConfigData();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate(workspaceUser.canViewUnifiedIA ? "/accounts/all" : "/home");
    }
  }, [location, navigate, workspaceUser.canViewUnifiedIA]);

  // Log out after 15 minutes of inactivity.
  useIdleTimer({
    timeout: ACTIVE_TIMEOUT,
    onIdle() {
      // If there's a modal open, close it first before logging out
      if (AppModalManager) AppModalManager.close();
      navigate(`/logout?next=${encodeURIComponent(window.location.pathname)}`);
    },
    onAction() {
      setActive();
    },
    debounce: 500,
    // If multiple tabs are open, this ensures all are logged out.
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  // Log out if the user re-opens tab after 15 minutes of closing last tab.
  // The above `useIdleTimer` does not keep state after the last tab is closed,
  // so we store the last active timestamp in `localStorage` ourselves.
  useEffect(() => {
    if (isActive() === false) {
      navigate(`/logout?next=${encodeURIComponent(window.location.pathname)}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (publicConfig.data === undefined) return;
    let csrfToken = publicConfig.data.csrfToken;
    API.setCsrfToken(csrfToken);
    API_V2.setCsrfToken(csrfToken);
    setCsrfToken(csrfToken);
  }, [publicConfig]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <BuyBitcoinProvider>
        <SellBitcoinProvider>
          <ClientReporter>
            <GlobalErrorBoundary>
              <NavigationTracker />
              <Layout />
            </GlobalErrorBoundary>
          </ClientReporter>
          {/* Items which need to render outside of the main layout */}
          <Flash userAuthenticated={true} />
          <Overlay />
          <SlideoutContainer />
          <ModalContainer />
          <TradeWirePaymentDueToast />
        </SellBitcoinProvider>
      </BuyBitcoinProvider>
    </LocalizationProvider>
  );
};

const UserAppWithAccount = withAccount<{ workspaceUser: WorkspaceUser }>(({ workspaceUser }) => {
  useGetBtcPriceQuery();

  // Downstream components require the account data in state to be hydrated
  // Wait til it is before rendering the app
  const userStatus = useMemoizedState("account.user.status");
  const orgStatus = useMemoizedState("account.orgs.status");

  if ([userStatus, orgStatus].some(status => status !== SUCCESS))
    return <Loader className="h-screen" />;

  return <UserAppBase workspaceUser={workspaceUser} />;
});

export default () => {
  const navigate = useNavigate();
  const workspace = useWorkspaceData();

  useEffect(() => {
    const next = encodeURIComponent(window.location.pathname);

    if (window.TREFOIL_USER === undefined) return navigate(`/login?next=${next}`);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (workspace.isLoading) return <Loader className="h-screen" />;

  const { user } = workspace.data || {};

  if (user.canViewUnifiedIA) {
    return <UserAppBase workspaceUser={user} />;
  } else {
    return <UserAppWithAccount workspaceUser={user} />;
  }
};
