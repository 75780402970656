import cloneDeep from "lodash/cloneDeep";
import PropTypes from "prop-types";

import { fiatWithUnit } from "Utils/currency";
import { getLoanCtpRatio } from "Utils/loanUtils";
import { humanState } from "Utils/states";
import { formatCurrency, formatPercentage } from "Utils/strings";
import { parseTimestamp, readableDate, readableTime } from "Utils/time";

export function loanListingProptypes(admin) {
  if (admin) {
    return {
      ...loanListingProptypes(),
      ...{
        note: PropTypes.string,
      },
    };
  } else {
    return {
      uuid: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      humanState: PropTypes.string,
      unit: PropTypes.string,
      loan_unit: PropTypes.string,
      collateral: PropTypes.number,
      collWithUnit: PropTypes.string,
      confirmedBitcoinBalance: PropTypes.number,
      principal: PropTypes.number,
      principalWithUnit: PropTypes.string,
      created_at: PropTypes.string,
      createdAt: PropTypes.object.isRequired,
      // readableCreatedAt: PropTypes.string.isRequired,
      product_type: PropTypes.string,
      productClass: PropTypes.string.isRequired,
    };
  }
}

export function LoanListing(d) {
  if (d == null) {
    return d;
  }
  let data = cloneDeep(d);
  data.productClass = "Loan";
  data.humanState = humanState(data);
  data.collWithUnit = data.collateral
    ? `${formatCurrency(data.collateral, 8)} ${data.unit || ""}`
    : null;
  data.principalWithUnit = fiatWithUnit(data.principal);
  data.orgCollWithUnit = data.original_collateral
    ? `${formatCurrency(data.original_collateral, 8)} ${data.unit}`
    : null;
  data.orgPrincipalWithUnit = fiatWithUnit(data.original_principal);
  data.priceOfDefaultWithUnit = data.price_of_default
    ? `${fiatWithUnit(data.price_of_default)} ${
        data.loan_unit && data.unit ? data.loan_unit + "/" + data.unit : ""
      }`
    : null;
  data.orgAprWithUnit = data.original_apr ? formatPercentage(data.original_apr) : null;
  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  data.readableCreatedOn = data.createdAt ? readableDate(data.createdAt) : null;

  if (data.slice && data.slice.balance !== null) {
    data.balanceWithUnit = `${formatCurrency(data.slice.balance, 8)} ${data.unit}`;
    data.hasBalance = true;
  } else {
    data.hasBalance = false;
  }

  if (data.confirmed_bitcoin_balance !== null && data.confirmed_bitcoin_balance !== undefined) {
    data.confirmedBitcoinBalance = data.confirmed_bitcoin_balance;
  }

  data.hasPendingBalance = data.pending_balance !== 0;
  if (data.hasPendingBalance) {
    data.additional_balance = data.pending_balance;
    data.additionalPendingBalanceWithUnit = `${formatCurrency(data.additional_balance, 8)} ${
      data.unit
    }`;
  }

  data.collateralValue = (prices, currentCollateral) => {
    var collateral = typeof currentCollateral == "undefined" ? data.collateral : currentCollateral;
    if (
      typeof collateral != "undefined" &&
      prices &&
      prices[data.unit] &&
      prices[data.unit].value
    ) {
      return collateral * prices[data.unit].value;
    } else {
      return null;
    }
  };

  data.collateralValueWithUnit = (prices, currentCollateral) => {
    var collateralValue = data.collateralValue(prices, currentCollateral);
    if (typeof collateralValue != "undefined") {
      return `$${formatCurrency(collateralValue)}`;
    } else {
      return null;
    }
  };

  data.ctpRatio = (prices, currentCollateral) => {
    const collateral = currentCollateral ?? data.collateral;
    return (
      getLoanCtpRatio(prices && prices.BTC && prices.BTC.value, collateral, data.principal) || null
    );
  };

  data.ctpPercentage = (prices, currentCollateral) => {
    const ratio = data.ctpRatio(prices, currentCollateral);
    if (typeof ratio === "undefined" || ratio === null) {
      return "n/a";
    }
    return formatPercentage(ratio);
  };

  return data;
}
