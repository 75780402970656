import { TransactionIcon } from "Components/Shared/Icons/TransactionIcon";
import { SellBitcoinStepperWizard } from "Components/TradingDesk/sell/SellBitcoinStepperWizard";
import { AppModalManager } from "Shared/components/Modals";
import { btcOperationTypes } from "Utils/enums";
import { splitOperationTypePlural } from "Utils/operationTypes";
import { FullOperationType, generateOperationVerbClause } from "Utils/verbs";

const BroadcastTransactionNotification = ({
  operation_type,
  operation_uuid,
  product_name,
  product_uuid,
}: {
  operation_type: string;
  operation_uuid: string;
  product_name: string;
  product_uuid: string;
}) => {
  const isSaleTransaction = operation_type === btcOperationTypes.vault_sale_transaction;

  const [products, operations] = splitOperationTypePlural(operation_type);
  const link = isSaleTransaction
    ? ""
    : `/${products}/${product_uuid}/${operations}/${operation_uuid}`;

  const operationVerbClause = generateOperationVerbClause(operation_type as FullOperationType);

  const title = isSaleTransaction
    ? `Sell transaction from ${product_name} is ready to broadcast`
    : `Broadcast transaction for ${operationVerbClause} ${product_name}`;

  const openSellBitcoinFlow = () =>
    AppModalManager.open(() => {
      return <SellBitcoinStepperWizard />;
    });
  const additionalAction = isSaleTransaction ? openSellBitcoinFlow : undefined;

  return {
    to: link,
    title: title,
    Icon: TransactionIcon,
    additionalAction: additionalAction,
  };
};

export { BroadcastTransactionNotification };
