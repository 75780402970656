import { Button, Icons, Tooltip } from "@unchained/component-library";

import { Link } from "Components/Link";
import { useVerifyAddressViaEmail } from "Utils/hooks/useVerifyAddressViaEmail";

import { handleConfirmOnDeviceClick } from "./confirmOnDevice";

const TooltipContent = () => (
  <>
    We recommend confirming the address being shown on screen before sending bitcoin.{" "}
    <Link to="https://help.unchained.com/how-to-verify-receiving-addresses" target="_blank">
      Learn more
    </Link>
  </>
);

export const ConfirmAddress = ({
  address,
  productUuid,
  productType,
  displayConfirmOnDevice,
  displayConfirmViaEmail,
  inline = false,
}: {
  address: string;
  productUuid: string;
  productType: "vault" | "loan";
  displayConfirmOnDevice: boolean;
  displayConfirmViaEmail: boolean;
  inline?: boolean;
}) => {
  const { verifyAddress, isVerifying } = useVerifyAddressViaEmail(
    productUuid,
    address,
    productType
  );

  const tooltip = (
    <Tooltip placement="top" content={<TooltipContent />}>
      <div>
        <Icons.HelpCircle className="h-4 w-4 text-gray-400" />
      </div>
    </Tooltip>
  );
  return (
    <div className={`flex ${inline ? "flex-row" : "flex-col"} gap-1.5`}>
      <div className="flex flex-row items-center gap-1">
        <div className="text-sm font-semi text-gray-800">Confirm address:</div>
        {!inline && tooltip}
      </div>

      <div className="flex flex-row items-center gap-2">
        {displayConfirmOnDevice && (
          <>
            <Button variant="text" onClick={() => handleConfirmOnDeviceClick(address)}>
              On device
            </Button>
            <div className="h-4 border border-gray-400" />
          </>
        )}
        {displayConfirmViaEmail && (
          <Button variant="text" disabled={isVerifying} onClick={verifyAddress}>
            Via email
          </Button>
        )}
        {inline && tooltip}
      </div>
    </div>
  );
};
