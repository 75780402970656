import { Banner, Icons, Tooltip } from "@unchained/component-library";

import { Link } from "Components/Link";

const TooltipContent = ({ minimum }: { minimum: string }) => (
  <>
    Unchained vaults are optimized for securing large sums of bitcoin. We recommend depositing{" "}
    {minimum} BTC or more to save on future transaction fees.{" "}
    <Link
      to="http://help.unchained.com/what-is-the-recommended-minimum-deposit-size-for-my-vault"
      target="_blank"
    >
      Learn more
    </Link>
  </>
);

export const VaultMinimumDepositBanner = ({ minimum }: { minimum: string }) => {
  return (
    <Banner disableIcon type="warning" className="!w-full">
      <div className="flex flex-row items-center gap-1">
        <div className="text-sm font-reg text-gray-800">
          {`Suggested minimum deposit: ${minimum} BTC`}
        </div>
        <Tooltip placement="top" content={<TooltipContent minimum={minimum} />}>
          <div>
            <Icons.HelpCircle className="h-4 w-4 text-gray-400" />
          </div>
        </Tooltip>
      </div>
    </Banner>
  );
};
