import { FloatingIcon, Icons } from "@unchained/component-library";

import { PageTitle } from "Components/Layout/Header/Titles/PageTitle";

export default function NotFound({
  title = "Page not found",
  message = "Could not find the page you were looking for.",
}: {
  title?: string;
  message?: string;
}) {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center gap-4 pb-8">
        <FloatingIcon color="red" Icon={Icons.AlertCircle} size="md" shadow />
        <PageTitle>{title}</PageTitle>
        <h3>{message}</h3>
      </div>
    </div>
  );
}
