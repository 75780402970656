import { FC } from "react";

import { Authorizers } from "Routes/(utils)/Authorize/Authorize";

import { Remap } from "./Remap";

/**
 * Renders a component at a path.
 * Can additionally wrap that component in authorization logic to determine
 * whether it should render and how it might be replaced.
 *
 **/
export class Route {
  _name: string;
  _component: FC;
  _authorizers: Authorizers = {};
  _DEPRECATED_removedAuthorizers: (keyof Authorizers)[];

  /**
   * Creates a new Route object
   * @param comp.name - The name of the route
   * @param comp.component - The component to render at the route
   */
  constructor(comp: { name: string; component: FC }) {
    this._name = comp.name;
    this._component = comp.component;
  }

  /**
   * Returns a Route object
   * that redirects at this path to another path.
   * Any parameters in the current path will be passed along.
   **/
  static redirect(to: string) {
    return new Route({ name: `Redirect -> ${to}`, component: () => <Remap to={to} /> });
  }

  /** Applies authorizers to the route */
  authorizers(authorizers: Authorizers) {
    this._authorizers = authorizers;
    return this;
  }

  /**
   * Removes any inherited childAuthorizers applied to this route by a parent RouteGroup.
   * Deprecated, because it's a anti-pattern to pass down and then remove authorization logic.
   * */
  DEPRECATED_removeInheritedAuthorizers(authorizers: (keyof Authorizers)[]) {
    this._DEPRECATED_removedAuthorizers = authorizers;

    return this;
  }
}
