import { API_V2 } from "../api";
import { AdminChangeProposalsAPIV2Factory } from "./adminChangeProposals";
import { ClientReportsApiFactory } from "./clientReports";
import { ConfigsAPIFactory } from "./config";
import { InvoiceAPIFactory } from "./invoices";
import { IRAPlansAPIV2Factory } from "./iraPlans";
import { KeysAPIV2Factory } from "./keys";
import { OrgAPIV2Factory } from "./orgs";
import { PurchasablesAPIFactory } from "./purchasables";
import { SubscriptionAPIFactory } from "./subscriptions";
import { TradingAPIV2Factory } from "./trading";
import { VaultAPIFactory } from "./vaults";
import { WorkspacesAPIFactory } from "./workspaces";

export { BankAccountApiV2 } from "./bankAccounts";
export { ConnectionsAPI } from "./connections";
export { Fido2API } from "./fido2";
export { INVOICE_STATUS, SKUS, type Invoice, type PatchInvoice } from "./invoices";
export { RekeysAPI } from "./rekeys";

export const InvoiceAPI = InvoiceAPIFactory(API_V2);
export const SubscriptionAPI = SubscriptionAPIFactory(API_V2);
export const PurchasablesAPI = PurchasablesAPIFactory(API_V2);
export const OrgAPI = OrgAPIV2Factory(API_V2);
export const IRAPlansAPI = IRAPlansAPIV2Factory(API_V2);
export const TradingAPI = TradingAPIV2Factory(API_V2);
export const ClientReportsAPI = ClientReportsApiFactory(API_V2);
export const VaultsAPI = VaultAPIFactory(API_V2);
export const WorkspacesAPI = WorkspacesAPIFactory(API_V2);
export const ConfigsAPI = ConfigsAPIFactory(API_V2);
export const AdminChangeProposalsAPI = AdminChangeProposalsAPIV2Factory(API_V2);
export const KeysAPI = KeysAPIV2Factory(API_V2);
