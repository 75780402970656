import React, { ComponentType, ReactElement, useCallback, useEffect } from "react";

import { Loader } from "@unchained/component-library";
import { cloneDeep } from "lodash";
import { useQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { useParams } from "react-router-dom";
import { CamelCasedProperties, Merge } from "type-fest";

import {
  setCurrentUserAccountDataAction,
  updateAccountOrgsAction,
  updateAccountUserAction,
  updateAccountUserStatusAction,
} from "Actions/accountActions";
import {
  replaceAccountCurrentOrgAction,
  updateAccountCurrentOrgStatusAction,
} from "Actions/accountActions/orgActions";
import { useLocalStorageOrgUuid } from "Contexts/CurrentOrgUuidContext";
import { useCurrentOrgDeprecated as useReduxCurrentOrg } from "Redux/selectors/hooks";
import {
  AccountPersonalOrg,
  CompleteOrg,
  CompleteOrgAccountTypeEnum,
  CompleteOrgStateEnum,
  CompleteOrgTypeEnum,
  GetAccount200,
} from "Specs/v1/getAccount/200";
import { useActions } from "Utils/hooks";
import { hasCompletedBasicInfo } from "Utils/orgState";

import { useGetOrg, UserAPI } from "../";
import { REQUEST_STATUS } from "../api";
import { SIGNATURE_SKUS } from "../v2/invoices";

const { SUCCESS } = REQUEST_STATUS;

export const accountQueryKeys = {
  get: "account" as const,
};
const stateTier = [
  ["live", "ineligible"],
  ["pending_approval"],
  ["pending_payment", "pending_payment_renewal", "pending_basic_info", "pending_resubmission"],
  ["pending_activation"],
  ["denied", "failed"],
] as CompleteOrgStateEnum[][];

const typeTier = [["unchained", "arbiter", "delegate"], ["client"]] as CompleteOrgTypeEnum[][];

const accountTypeTier = [
  ["individual"],
  ["trust", "ira"],
  ["business"],
] as CompleteOrgAccountTypeEnum[][];

// Helper function to compare values based on tier arrays
const compareTiers = (aValue: string, bValue: string, tiers: string[][]): number => {
  const aTier = tiers.findIndex(tier => tier.includes(aValue));
  const bTier = tiers.findIndex(tier => tier.includes(bValue));
  if (aTier !== bTier) return aTier - bTier;

  // If in same tier, sort by position within flattened array
  if (aValue !== bValue) return tiers.flat().indexOf(aValue) - tiers.flat().indexOf(bValue);
  return 0;
};

export const _sortOrgs = (orgs: (AccountPersonalOrg | CompleteOrg)[]) => {
  return orgs.sort((a, b) => {
    // Compare state tiers
    const stateComparison = compareTiers(a.state, b.state, stateTier);
    if (stateComparison !== 0) return stateComparison;

    // Compare type tiers
    const typeComparison = compareTiers(a.type, b.type, typeTier);
    if (typeComparison !== 0) return typeComparison;

    // Compare account type tiers
    return compareTiers(a.account_type, b.account_type, accountTypeTier);
  });
};

/** Given account data, calculate currentOrg and whether user shouldOnboard. */
export const _extractCurrentOrgFromAccountResponse = (
  account: CamelCasedProperties<GetAccount200>,
  {
    currentOrgUuid,
    setCurrentOrgUuid,
  }: { currentOrgUuid: string; setCurrentOrgUuid: (uuid: string) => void }
): { currentOrg?: CompleteOrg | AccountPersonalOrg; shouldOnboard?: boolean } => {
  if (!account) return {};

  const { personalOrg, memberships } = account;

  const acceptedMemberships = memberships.filter(membership => membership.state === "accepted");
  const orgs: (AccountPersonalOrg | CompleteOrg)[] = [
    personalOrg,
    ...acceptedMemberships.map(m => m.org),
  ];

  let selectedOrg = orgs.find(o => o.uuid === currentOrgUuid);

  if (!selectedOrg) {
    const sortedOrgs = _sortOrgs(orgs);
    selectedOrg = sortedOrgs[0];
  }

  if (selectedOrg?.uuid !== currentOrgUuid) {
    setCurrentOrgUuid(selectedOrg.uuid);
  }

  return {
    currentOrg: selectedOrg,
    shouldOnboard:
      selectedOrg.account_type === "individual" ? !hasCompletedBasicInfo(selectedOrg) : false,
  };
};

export type GetAccount = CamelCasedProperties<GetAccount200> & {
  // The current org as set in localStorage
  currentOrg: CompleteOrg;
  shouldOnboard: boolean;
  isUnchainedAdmin: boolean;
  /**
   * If the current org response from the getAccount query has been replaced by a more
   * complete org fetched from the orgs API
   */
  currentOrgComplete?: boolean;
};

/** Returns whether the given personal org is subscribed to Signature */
export const hasIndividualSignature = (personalOrg: CompleteOrg) =>
  SIGNATURE_SKUS.some(s => s in (personalOrg?.["subscribed_skus"] || {}));

/**
 * Grabs account (personalOrg, memberships, user),
 * calculates currentOrg if applicable,
 * then loads full current org in background,
 * and dispatches results to Redux store.
 **/
export const useGetAccount = (queryOptions: UseQueryOptions = {}) => {
  const currentOrgInRedux = useReduxCurrentOrg();
  const {
    replaceAccountCurrentOrg,
    updateAccountUser,
    updateAccountOrgs,
    updateAccountUserStatus,
    updateAccountCurrentOrgStatus,
    setCurrentUserAccountData: setUserAccountData,
  } = useActions({
    replaceAccountCurrentOrgAction,
    updateAccountUserAction,
    updateAccountOrgsAction,
    updateAccountUserStatusAction,
    setCurrentUserAccountDataAction,
    updateAccountCurrentOrgStatusAction,
  });

  const { accountId: accountIdInParams } = useParams();
  const { value: currentOrgUuidFromLs, setValue } = useLocalStorageOrgUuid();
  const setCurrentOrgUuid = useCallback(
    (uuid: string) => {
      if (!location.pathname.includes("/accounts/all")) {
        setValue(uuid);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname]
  );

  const getAccount = useQuery<GetAccount200>(accountQueryKeys.get, UserAPI.GetAccount, {
    select: (data: GetAccount) => {
      const { memberships, personalOrg } = data;
      const orgs = [personalOrg, ...memberships.map(m => m.org)];

      return {
        ...data,
        ..._extractCurrentOrgFromAccountResponse(data, {
          // If we're on a UIA page with an explicit accountId, use that
          currentOrgUuid: accountIdInParams || currentOrgUuidFromLs,
          setCurrentOrgUuid,
        }),
        isUnchainedAdmin: orgs.some(o => o.type === "unchained"),
      };
    },
    onSuccess: (data: GetAccount) => {
      const { memberships, personalOrg, user } = data;
      updateAccountUser(user);
      updateAccountOrgs(personalOrg, memberships);
      updateAccountUserStatus(SUCCESS);

      if (!window.TREFOIL_USER) return;

      const simpleTrefoilUser = cloneDeep(window.TREFOIL_USER) as { [key: string]: unknown };
      delete simpleTrefoilUser.readableCreatedAt;
      delete simpleTrefoilUser.readableUpdatedAt;
      setUserAccountData(simpleTrefoilUser);
    },
    ...queryOptions,
  });

  const reduxOrgUuid = currentOrgInRedux?.uuid;

  const currentOrgUuidFromAcctQuery = (getAccount.data as GetAccount)?.currentOrg?.uuid;

  const fullOrgQuery = useGetOrg(currentOrgUuidFromAcctQuery, {
    enabled: !!currentOrgUuidFromAcctQuery,
  });
  const currentOrgComplete = !!fullOrgQuery.data?.uuid;
  const data = (getAccount.data || {}) as GetAccount;
  const currentOrg = currentOrgComplete ? fullOrgQuery.data : data.currentOrg;
  const account = {
    ...getAccount,
    data: {
      ...data,
      currentOrg,
      currentOrgComplete,
    },
  } as UseQueryResult<GetAccount>;

  /**
   * Watch the currentOrgUuid, and when it changes,
   * update Redux current user info
   **/
  useEffect(() => {
    if (!currentOrg?.uuid) return;

    if (reduxOrgUuid !== currentOrg?.uuid) {
      replaceAccountCurrentOrg(currentOrg);
      updateAccountCurrentOrgStatus(SUCCESS);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrg?.uuid, reduxOrgUuid]);

  // Attach account data to the window locally for debugging purposes
  if (process.env?.NODE_ENV === "development") {
    (window as unknown as { account: unknown }).account = account.data;
  }

  return account;
};

/** Fetches the account and the full current org, and shows a loader until both have returned */
export function withAccount<T = unknown>(
  Component: ComponentType<Merge<GetAccount, T>>,
  options: {
    /** If true, the provided/default loader will display until the GET org request has resolved the full org. */
    waitForFullCurrentOrg?: boolean;
    /** Loader to replace the default spinner */
    loader?: ReactElement | null | false;
  } & UseQueryOptions = {}
): React.FC<T> {
  const {
    waitForFullCurrentOrg = false,
    loader = <Loader className="h-screen" />,
    ...useGetAccountOptions
  } = options;

  return (props: T) => {
    const account = useGetAccount(useGetAccountOptions);

    if (account.isLoading && loader !== false) return loader;

    // Account membership orgs come back incomplete.
    // Because some pages expect more complete features,
    // we fetch the full current org explicitly, and optional wait until it's in the cache.
    if (waitForFullCurrentOrg && !account.data?.currentOrgComplete && loader !== false)
      return loader;

    const fullProps = { ...(account.data || {}), ...(props as T) } as Merge<GetAccount, T>;

    return <Component {...fullProps} />;
  };
}

export const useAccountCurrentOrg = (getAccountOptions: UseQueryOptions = {}) => {
  const account = useGetAccount(getAccountOptions);
  return account.data?.currentOrg || {};
};

export const useAccountPersonalOrg = (getAccountOptions: UseQueryOptions = {}) => {
  const account = useGetAccount(getAccountOptions);
  return account.data?.personalOrg || {};
};
