import { RouteObject } from "react-router-dom";

import { Authorize, Authorizers } from "../Authorize/Authorize";
import { Route } from "./Route";
import { RouteGroup } from "./RouteGroup";
import { mergeAuthorizers } from "./mergeAuthorizers";

const _clean = path =>
  path
    .replace(/\/+/g, "/") // Remove duplicate slashes
    .replace(/\/$/, ""); // Remove trailing slash

const _pathDefinition = (route: Route, path: string, parentAuthorizers?: Authorizers) => {
  const cleanPath = _clean(path);
  const { merged: mergedAuthorizers, errors: authorizerErrors } = mergeAuthorizers(
    parentAuthorizers,
    route
  );

  if (authorizerErrors.length > 0) {
    const error = `Error in routing for "${cleanPath}": \n\n${authorizerErrors.join("\n")}`;
    throw new Error(error);
  }

  const result: RouteObject = ["/", ""].includes(cleanPath) ? { index: true } : { path: cleanPath };

  const Component = route._component;
  result.element =
    Object.keys(mergedAuthorizers).length > 0 ? (
      <Authorize {...mergedAuthorizers} path={cleanPath}>
        <Component />
      </Authorize>
    ) : (
      <Component />
    );

  return result;
};

/**
 * Converts our router config into React Router's expected format,
 * wrapping components with authorizations as specified.
 */
export const createReactRouterObject = (
  path: string,
  route: Route | RouteGroup,
  parentAuthorizers?: Authorizers
): RouteObject => {
  // Leaf route
  if ("_component" in route) return _pathDefinition(route, path, parentAuthorizers);

  // Branch route
  const cleanPath = _clean(path);
  const result: RouteObject = { path: cleanPath };
  const { merged: mergedAuthorizers } = mergeAuthorizers(parentAuthorizers, route);

  const childRoutes = Object.entries(route._children);
  const children = childRoutes.map(([childPath, childRoute]) =>
    createReactRouterObject(_clean([path, childPath].join("/")), childRoute, mergedAuthorizers)
  );

  return { ...result, children } as RouteObject;
};
