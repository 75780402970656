import { useContext } from "react";

import { WizardStepperContext } from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { Formik } from "Components/FormFields";
import { countryStateData } from "Components/FormFields/FormikAddressForm/CountryStateData";
import {
  AddressSummaryTable,
  notCopyable,
  SummaryTable,
} from "Components/Shared/Elements/Summary/SummaryTable";
import { useLoadingContext } from "Contexts/LoadingContext";
import { ManifestStep, ReviewSectionHeader } from "Routes/onboard/(shared)";
import {
  docData,
  OnboardBasicContext,
  OnboardingGoal,
  OnboardingInfo,
  simplifiedOrgStates,
} from "Routes/onboard/(utils)";
import { addDollarSignsToMoneyRange } from "Routes/onboard/[uuid]/(individual)/individualOrgValsAndValidations";
import { employmentData } from "Routes/onboard/[uuid]/(ira)/steps/IraInfo/(utils)";
import { accountQueryKeys, orgQueryKeys, ProfileAPI } from "Shared/api";
import { fullName } from "Utils/person";
import { useEasyToasts } from "Utils/toasts";

const getReviewVals = ({ user, person }: Partial<OnboardingInfo>) => {
  const { phone } = user;
  const { state_id, passport } = person as OnboardingInfo["person"] & { passport: File };
  const file = passport ? passport : state_id;
  const document_type = passport ? "passport" : "state_id";
  return { ...person, phone, file, document_type };
};

export const ReviewProfile = () => {
  const { user, person, personalOrg } = useContext(OnboardBasicContext);
  const { state, uuid } = personalOrg;
  const { editable, resubmittable } = simplifiedOrgStates(personalOrg, OnboardingGoal.BASIC);
  const { advanced_profile_complete } = person;
  const { goToNext } = useContext(WizardStepperContext);
  const { showErrorToast } = useEasyToasts();
  const loading = useLoadingContext();
  const queryClient = useQueryClient();

  const submit = useMutation(() => ProfileAPI.SubmitPersonal(uuid), {
    onMutate: () => loading.set(true),
    onSuccess: () => {
      queryClient.refetchQueries(accountQueryKeys.get);
      queryClient.refetchQueries(orgQueryKeys.show(personalOrg.uuid));
      goToNext();
    },
    onError: err => {
      showErrorToast(err);
    },
    onSettled: () => loading.set(false),
  });

  const { dob, physical_address, state_id, passport } = person;

  let title = "Review profile information";
  let description = <div>Please take a moment to review and confirm your information.</div>;

  const nationality = countryStateData.getCountry(person.nationality)?.name;

  if (resubmittable) {
    title = "Resubmission requested";
    const deniedReason = (personalOrg as { denied_reason: string }).denied_reason;
    description = deniedReason ? (
      <div className="prose">
        <p>Your profile was not approved for the following reason:</p>
        <p className="font-bold">{deniedReason}</p>
        <p className="mt-1">Please review your profile for errors and resubmit.</p>
      </div>
    ) : (
      <div>Please review your profile for errors and resubmit.</div>
    );
  }

  return (
    <Formik
      initialValues={getReviewVals({ user, person, personalOrg })}
      onSubmit={() => submit.mutate(undefined)}
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="narrow"
            actions={[
              {
                onClick: handleSubmit,
                children: "Submit profile information",
                type: "submit",
                disabled: !editable,
              },
            ]}
            title={title}
            description={description}
          >
            <div className="flex flex-col gap-4">
              <ReviewSectionHeader stepName="Create your profile">
                Legal name and date of birth
              </ReviewSectionHeader>
              <SummaryTable
                rows={[
                  {
                    title: "Name",
                    value: fullName(person),
                  },
                  { title: "Date of birth", value: dob },
                ]}
              />
              <ReviewSectionHeader stepName="Create your profile">
                Place of residence
              </ReviewSectionHeader>
              <>
                <h4>Home address</h4>
                <AddressSummaryTable address={physical_address} />
              </>
              <ReviewSectionHeader stepName="Create your profile">Phone number</ReviewSectionHeader>
              <SummaryTable rows={[{ title: "Phone number", value: user.phone }]} />
              <ReviewSectionHeader stepName="Create your profile">
                User identification
              </ReviewSectionHeader>
              <SummaryTable
                rows={docData([state_id, passport].filter(Boolean), personalOrg.uuid)}
              />
            </div>

            {advanced_profile_complete && (
              <div>
                <ReviewSectionHeader stepName="Create your profile">
                  Social security number
                </ReviewSectionHeader>
                <SummaryTable
                  rows={notCopyable([
                    { value: nationality, title: "Nationality" },
                    { value: person.ssn, maskedValue: person.masked_ssn, title: "SSN" },
                  ])}
                />

                <ReviewSectionHeader stepName="Create your profile">
                  Employment status
                </ReviewSectionHeader>
                <SummaryTable rows={employmentData(personalOrg)} />

                <ReviewSectionHeader stepName="Create your profile">
                  Personal income
                </ReviewSectionHeader>
                <SummaryTable
                  rows={[
                    { value: addDollarSignsToMoneyRange(person.income), title: "Income range" },
                    { value: person.income_sources, title: "Sources" },
                  ]}
                />

                <ReviewSectionHeader stepName="Create your profile">
                  Wealth & digital assets
                </ReviewSectionHeader>
                <SummaryTable
                  className="mb-4 rounded-sm"
                  rows={[
                    {
                      value: addDollarSignsToMoneyRange(person.estimated_wealth),
                      title: "Wealth range",
                    },
                    { value: person.estimated_wealth_notes, title: "Wealth sources" },
                  ]}
                />
              </div>
            )}
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
