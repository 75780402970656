import { useContext, useEffect, useMemo, useState } from "react";

import { Button, Loader, Slideout, SlideoutHeader } from "@unchained/component-library";

import { useNavigate } from "Components/Link";
import { useGetVaultSummaryQuery } from "Shared/api/v2/hooks/vaults";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { useCurrentOrgUuid } from "Utils/hooks/useCurrentOrg";

import { Balance } from "./Balance";
import { DepositAddress } from "./DepositAddress";
import { defaultVaultQuickViewContextState, VaultQuickViewContext } from "./context";
import { IVaultQuickViewContext } from "./types";

const SlideoutContent = ({
  isLoading,
  depositedBalance,
  depositAddress,
  displayConfirmOnDevice,
  vaultUuid,
  suggestedMinimumDepositBtc,
  vaultType,
}: {
  isLoading: boolean;
  depositedBalance?: string;
  depositAddress?: string;
  displayConfirmOnDevice: boolean;
  vaultUuid: string;
  suggestedMinimumDepositBtc?: string;
  vaultType?: string;
}) => {
  return (
    <div className="flex flex-grow flex-col gap-6">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {depositedBalance && (
            <>
              <Balance balance={depositedBalance} />
              <hr />
            </>
          )}
          {depositAddress && vaultType !== "ira" && (
            <>
              <DepositAddress
                vaultUuid={vaultUuid}
                address={depositAddress}
                suggestedMinimumDepositBtc={suggestedMinimumDepositBtc}
                displayConfirmOnDevice={displayConfirmOnDevice}
              />
              <hr />
            </>
          )}
        </>
      )}
    </div>
  );
};

export const VaultQuickViewSlideout = withWorkspaceData(({ user }) => {
  const navigate = useNavigate();
  const currentOrgUuid = useCurrentOrgUuid();
  const { uuid, name, setVaultQuickViewContextState }: IVaultQuickViewContext =
    useContext(VaultQuickViewContext);
  const { data, isLoading } = useGetVaultSummaryQuery(uuid, { enabled: !!uuid });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (uuid) {
      setOpen(true);
    }
  }, [uuid, setOpen]);

  useEffect(() => {
    // Reset the context state whenever closing the slideout
    // Delaying the state change prevents the slideout from emptying before it
    // has closed.
    if (!open) {
      setTimeout(() => setVaultQuickViewContextState(defaultVaultQuickViewContextState), 300);
    }
  }, [open, setVaultQuickViewContextState]);

  const displayConfirmOnDevice = useMemo(
    () => data?.currentAccountKeys.some(key => key.owner.uuid === currentOrgUuid),
    [data, currentOrgUuid]
  );

  const onDismiss = () => {
    setOpen(false);
  };
  const depositedBalance =
    data?.allowedActions.includes("view") || data?.allowedActions.includes("view_balances")
      ? data?.depositedBalance
      : undefined;
  const depositAddress =
    data?.allowedActions.includes("view") || data?.allowedActions.includes("get_deposit_address")
      ? data?.depositAddress
      : undefined;

  return (
    <Slideout open={open} onDismiss={onDismiss} dismissOnEscape>
      <SlideoutHeader size="md" subheading={`Vault: ${uuid}`} separator>
        {name}
      </SlideoutHeader>
      <SlideoutContent
        isLoading={isLoading || !data}
        vaultUuid={uuid}
        vaultType={data?.vaultType}
        depositedBalance={depositedBalance}
        depositAddress={depositAddress}
        displayConfirmOnDevice={displayConfirmOnDevice}
        suggestedMinimumDepositBtc={data?.suggestedMinimumDepositBtc}
      />
      <div className="flex flex-col gap-3">
        {data?.allowedActions.includes("view") && (
          <Button
            color="primary"
            onClick={() =>
              navigate(
                user.canViewUnifiedIA
                  ? `/accounts/${currentOrgUuid}/vaults/${uuid}`
                  : `/vaults/${uuid}`
              )
            }
          >
            Go to vault
          </Button>
        )}
        <Button color="secondary" onClick={onDismiss} className="w-full">
          Close
        </Button>
      </div>
    </Slideout>
  );
});
