/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import { Tooltip } from "@unchained/component-library";
import Big from "big.js";
import cx from "classnames";

import { DeprecatedFormikTextField } from "Components/Shared/Elements/Fields";
import { DestinationLoan, DestinationVault } from "Contexts/BuyBitcoin/types";
import { formatNumber, formatPercentage } from "Utils/strings";

import styles from "./VaultSelectDropdown.module.scss";

interface VaultLabelProps {
  vault: DestinationVault;
}

const VaultLabel = ({ vault }: VaultLabelProps) => {
  const { id, name, balance, balanceUsd, hasPendingTx } = vault;

  return (
    <div className={styles.vaultLabel}>
      <div className="flew-row flex">
        <h4 className={styles.label}>
          Vault {id} {hasPendingTx ? `- Unavailable` : ""}
        </h4>
      </div>
      <div className={styles.selectOption}>
        <span className={styles.vaultName}>{name}</span>
        {!hasPendingTx ? (
          <span>
            {formatNumber(balance, 4)} BTC / ${formatNumber(balanceUsd)}
          </span>
        ) : (
          <span>(transaction in progress)</span>
        )}
      </div>
    </div>
  );
};

interface LoanLabelProps {
  loan: DestinationLoan;
}

const getCtpClassName = (ctpRatio: string) => {
  try {
    if (Big(ctpRatio).gt(3.0)) return "excellent";
    if (Big(ctpRatio).gt(2.0)) return "healthy";
    if (Big(ctpRatio).gt(1.5)) return "acceptable";
    if (Big(ctpRatio).gt(1.1)) return "marginCall";
    return "liquidation";
  } catch {
    return "";
  }
};

const LoanLabel = ({ loan }: LoanLabelProps) => {
  const { name, principalUsd, ctpRatio } = loan;

  return (
    <div className={styles.vaultLabel}>
      <h4 className={styles.label}>{name}</h4>
      <div className={styles.selectOption}>
        <span>Principal: ${formatNumber(principalUsd)}</span>
        <div className={styles.ctpInfo}>
          <span>CTP: {formatPercentage(ctpRatio, 0)}</span>
          <div
            className={cx(styles.ctpIndicator, styles[`ctpIndicator_${getCtpClassName(ctpRatio)}`])}
          />
        </div>
      </div>
    </div>
  );
};
type VaultSelectDropdownProps = {
  vaults: DestinationVault[];
  loans: DestinationLoan[];
  label?: string;
  tooltipText?: string;
  formikName?: string;
  isSelectable?: boolean;
};

export const VaultSelectDropdown = ({
  vaults,
  loans,
  label = "Choose destination",
  tooltipText,
  formikName = "destination",
  isSelectable = true,
}: VaultSelectDropdownProps) => {
  const renderTooltip = () => {
    if (tooltipText) {
      return (
        <div className="flex items-center pl-1">
          <Tooltip content={tooltipText} placement="top" arrow={true}>
            <HelpOutline fontSize="inherit" className="text-gray-400" />
          </Tooltip>
        </div>
      );
    }
  };
  const vaultMenuItems = vaults.map(vault => (
    // @ts-ignore materialUI incorrectly typed this property, throws error because of fullWidth, but fullWidth is needed
    <MenuItem
      disabled={vault.hasPendingTx}
      classes={{ root: styles.menuItem }}
      key={vault.id}
      value={vault.id}
    >
      <VaultLabel vault={vault} />
    </MenuItem>
  ));

  const loanMenuItems = loans.map(loan => (
    // @ts-ignore materialUI incorrectly typed this property, throws error because of fullWidth, but fullWidth is needed
    <MenuItem classes={{ root: styles.menuItem }} key={loan.id} value={loan.id}>
      <LoanLabel loan={loan} />
    </MenuItem>
  ));

  const notSelectableProps = {
    onclick: () => {},
    open: false,
    IconComponent: () => null,
  };

  return (
    <div className={styles.container}>
      <div className="flex items-center">
        <p className="text-sm font-med text-gray-600"> {label} </p>
        {renderTooltip()}
      </div>
      <DeprecatedFormikTextField
        name={formikName}
        select
        showError={false}
        InputProps={{
          classes: { root: styles.outlineRoot, input: styles.outLineInput },
        }}
        SelectProps={{
          classes: { select: styles.select },
          displayEmpty: true,
          renderValue: (value: string) => {
            const vault = vaults.find(v => v.id === value);
            if (vault) return <VaultLabel vault={vault} />;
            const loan = loans.find(l => l.id === value);
            if (loan) {
              return <LoanLabel loan={loan} />;
            }
            return "Select";
          },
          MenuProps: {
            classes: { paper: styles.menu },
            "data-testid": "vaultMenu",
          },
          SelectDisplayProps: {
            "data-testid": "vaultSelect",
          },
          ...(isSelectable ? {} : notSelectableProps),
        }}
        fullWidth
      >
        {vaultMenuItems}
        {loanMenuItems}
      </DeprecatedFormikTextField>
    </div>
  );
};
