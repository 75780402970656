import React from "react";

import { InputAdornment, TextField } from "@mui/material";
import cx from "classnames";
import PropTypes from "prop-types";
import { NumericFormat } from "react-number-format";

import styles from "./BitcoinAmountField.module.scss";

export const BitcoinAmountField = ({
  max,
  onChange,
  name,
  value,
  endAdornment,
  label,
  isSats,
  ...other
}) => {
  const handleChange = ({ value: newValue }) => {
    if (onChange) {
      onChange({ target: { value: newValue === "." ? "0." : newValue, name } });
    }
  };

  const handleClickMax = () => {
    if (onChange) {
      onChange({ target: { value: max, name } });
    }
  };

  let startAdornment;

  if (max) {
    const isMax = Number(value) === max;
    startAdornment = (
      <InputAdornment position="start" onClick={!isMax ? handleClickMax : undefined}>
        <span className={cx(styles.maxButton, { [styles.maxButton_enabled]: !isMax })}>Max</span>
      </InputAdornment>
    );
  }

  return (
    <NumericFormat
      name={name}
      type="text"
      allowNegative={false}
      thousandSeparator
      decimalScale={isSats ? 0 : 8}
      variant="outlined"
      placeholder={label || "Amount"}
      label={label || "Amount"}
      customInput={TextField}
      InputProps={{
        startAdornment,
        endAdornment: (
          <InputAdornment position="end">
            <span className={styles.unit}>{endAdornment || "BTC"}</span>
          </InputAdornment>
        ),
        classes: {
          root: cx(styles.muiInput, { [styles.muiInput_padded]: !max }),
        },
      }}
      inputProps={{
        className: styles.input,
      }}
      onValueChange={handleChange}
      value={value}
      {...other}
    />
  );
};

BitcoinAmountField.propTypes = {
  max: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
};
