import { ReactNode } from "react";

import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";

import { AppModalManager } from "Shared/components/Modals";
import { useErrorToast } from "Utils/toasts";

export interface ConfirmationModalProps {
  text?: ReactNode;
  // eslint-disable-next-line @typescript-eslint/ban-types
  onConfirm: Function;
  title?: string;
  subtitle?: string;
  confirmationText?: string;
  cancellationText?: string;
  onCancel?: () => void;
  onDismiss?: () => void;
  icon?: ReactNode;
}

export const ConfirmationModal = ({
  text,
  onConfirm,
  onCancel = AppModalManager.close,
  onDismiss = AppModalManager.close,
  confirmationText = "Yes",
  cancellationText = "Cancel",
  title = "Are you sure?",
  subtitle,
}: ConfirmationModalProps) => {
  const showErrorToast = useErrorToast();
  return (
    <Modal onDismiss={onDismiss} className="md:min-w-[26rem]">
      <ModalHeader>
        {title || subtitle ? <ModalTitle subtitle={subtitle}>{title}</ModalTitle> : null}
      </ModalHeader>
      <ModalContent>{text}</ModalContent>
      <ModalFooter
        actions={[
          {
            children: confirmationText,
            onClick: () => {
              try {
                const maybePromise = onConfirm();
                if (maybePromise && maybePromise.then) {
                  maybePromise.then(() => AppModalManager.close());
                } else {
                  AppModalManager.close();
                }
              } catch (e) {
                showErrorToast(e);
                AppModalManager.close();
              }
            },
          },
          { children: cancellationText, onClick: onCancel },
        ]}
      />
    </Modal>
  );
};

export const triggerConfirmationModal = (props: ConfirmationModalProps) => {
  AppModalManager.open(() => <ConfirmationModal {...props} />);
};
