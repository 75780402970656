import { Fragment } from "react";

import { estimateMultisigTransactionFeeRate } from "@caravan/bitcoin";
import { Grid, Table, TableBody, TableRow } from "@mui/material";
import { format } from "date-fns";
import { connect } from "react-redux";

import { CryptoAmountsCell } from "Components/Shared/Elements/Summary/CryptoAmountsCell";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import {
  spendingOperationSelector,
  spendingTransactionSelector,
} from "Redux/selectors/spendingSelectors";
import { cryptoToFiat } from "Utils/currency";
import { formatCurrency } from "Utils/strings";

import { FeeInfoBanner } from "../FeeInfoBanner";
import { OutputDetails } from "./OutputDetails";

const TransactionDetailsBase = ({
  accountType,
  accountUUID,
  accountName,
  transactionOutputs,
  transactionInputs,
  transactionFee,
  prices,
  operation,
  txRequestApprovals,
  showApprovals = false,
}) => {
  const totalInputAmount = transactionInputs.reduce((sum, input) => sum + input.amount, 0);
  const totalOutputAmount = transactionOutputs.reduce((sum, output) => sum + output.amount, 0);
  const approverNames =
    txRequestApprovals
      ?.map(approval => approval.approver?.name)
      .filter(name => name !== undefined)
      .join(", ") ?? "";
  const transactionFeeFiat = cryptoToFiat(transactionFee, "BTC", prices);

  const transactionFeeInSats = transactionFee * 1e8;
  const feeRate = Number(
    estimateMultisigTransactionFeeRate({
      addressType: "P2SH",
      numInputs: transactionInputs.length,
      numOutputs: transactionOutputs.length,
      m: 2,
      n: 3,
      feesInSatoshis: transactionFeeInSats,
    })
  ).toFixed(2);

  let date = operation?.btc_transaction_requests?.[0]?.created_at;
  if (date) {
    // Date comes back formatted like: "Fri, 15 Dec 2023 00:37:54 [GMT]"
    // Remove the square brackets around TZ if they exist and format properly.
    date = format(new Date(date.replace("[", "").replace("]", "")), "EEE, MMM dd 'at' h:mm a");
  }

  const totalInputsCount = transactionInputs.length;
  const totalOutputsCount = transactionOutputs.length;

  return (
    <Fragment>
      <FeeInfoBanner feeRate={feeRate} />
      <div>
        <span className="text-xs font-bold">Transaction created: </span>
        {date && <span className="text-xs">{date}</span>}
      </div>
      <Grid container item alignItems="flex-start" spacing={2}>
        <OutputDetails
          accountType={accountType}
          accountUUID={accountUUID}
          accountName={accountName}
          operation={operation}
          transactionOutputs={transactionOutputs}
        />
        <Grid item xs={12}>
          <Table>
            <TableBody>
              <TableRow>
                <SummaryTableCell component="th">Miner fees</SummaryTableCell>
                <CryptoAmountsCell
                  crypto={`${transactionFee} BTC`}
                  fiat={`$${formatCurrency(transactionFeeFiat, 2)}`}
                />
              </TableRow>
              <TableRow>
                <SummaryTableCell component="th">Total inputs</SummaryTableCell>
                <CryptoAmountsCell
                  crypto={`${formatCurrency(totalInputAmount, 8, false, false)} BTC`}
                  fiat={`$${formatCurrency(cryptoToFiat(totalInputAmount, "BTC", prices), 2)}`}
                  extra={`(${totalInputsCount} inputs)`}
                />
              </TableRow>
              <TableRow>
                <SummaryTableCell component="th">Total outputs</SummaryTableCell>
                <CryptoAmountsCell
                  crypto={`${formatCurrency(totalOutputAmount, 8, false, false)} BTC`}
                  fiat={`$${formatCurrency(cryptoToFiat(totalOutputAmount, "BTC", prices), 2)}`}
                  extra={`(${totalOutputsCount} outputs)`}
                />
              </TableRow>
            </TableBody>
          </Table>
        </Grid>
        {approverNames && showApprovals && (
          <Grid item xs={12}>
            <Table>
              <TableRow>
                <SummaryTableCell component="th">Organization approvals</SummaryTableCell>
                <SummaryTableCell align="right">{approverNames}</SummaryTableCell>
              </TableRow>
            </Table>
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    prices: state.crypto.prices.current,
    ...spendingOperationSelector(state),
    ...spendingTransactionSelector(state),
  };
};

export const TransactionDetails = connect(mapStateToProps, null)(TransactionDetailsBase);
