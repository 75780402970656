import { ToastProps, useToast } from "@unchained/component-library";
import { useDispatch } from "react-redux";

import { useNavigate } from "Components/Link";
import {
  openClientReportModal,
  useShowClientReports,
} from "Contexts/ClientReporter/ClientReportModal";
import { reportError } from "Reducers/clientReportReducer";

export type ErrorToastOptions = {
  redirectTo?: string;
  persist?: boolean;
  dismissable?: boolean;
  showClientReportLink?: boolean;
} & Partial<ToastProps>;
const defaultUseToastOptions: ErrorToastOptions = {
  title: "Something went wrong",
  description: "Please try again or let us know if the problem persists.",
  type: "error",
  persist: true,
  dismissable: true,
};

/** Useful when catching errors.
 * 1. Logs the passed error,
 * 2. renders an error toast (with configurable title/description), and
 * 3. optionally navigates.
 *
 * Usage:
 *   const showErrorToast = useErrorToast();
 *   somePromise().catch(showErrorToast);
 *   // or: somePromise().catch(err => showErrorToast(err, { title: 'Change me', redirectTo: '/home' }));
 **/
export const useErrorToast = () => {
  const { enqueueSimpleToast, closeToast } = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showReportLink = useShowClientReports();

  return (error?: unknown, passedOptions: ErrorToastOptions = {}): null => {
    let errorObj;
    try {
      errorObj = {
        message:
          (error instanceof Error ? error.message : (error as string)) ||
          "An error was caught in a toast",
        stack: error instanceof Error ? error.stack : "",
      };
    } catch (e) {
      console.error("Error converting error to string", e);
    }

    const { showClientReportLink, ...passed } = passedOptions;

    const { redirectTo, onClose, ...options } = {
      ...defaultUseToastOptions,
      actions:
        showClientReportLink ?? showReportLink
          ? [
              {
                label: "Report error details",
                action: () => {
                  openClientReportModal(errorObj);
                  closeToast?.();
                },
              },
            ]
          : [],
      ...passed,
    } as ToastProps & { redirectTo?: string };

    if (process.env.NODE_ENV !== "test" && error) {
      // No reason to log this in tests
      console.error(error);
      dispatch(reportError(errorObj));
    }

    enqueueSimpleToast(options);

    if (redirectTo) navigate(redirectTo);

    return null;
  };
};

export const useSuccessToast = () => {
  const { enqueueSimpleToast } = useToast();
  const navigate = useNavigate();

  return (data: { title: string; description: string }, redirectTo?: string) => {
    const title = data.title || "Operation successful";
    const description = data.description || "The API call completed successfully.";

    enqueueSimpleToast({
      type: "success",
      title: title,
      description,
      dismissable: true,
      persist: false,
    });

    if (redirectTo) navigate(redirectTo);

    return null;
  };
};

/**
 * Following the basic pattern whereby the API returns an object with a `message` on success,
 * this hook will render a toast with the message and optionally navigate to a new page.
 * By default, this toast will have a title of "Operation successful" and a description of
 * "The API call completed successfully.". Both of these can be overridden.
 *
 * * Usage:
 *   const showApiSuccessToast = useApiSuccessToast();
 *   someApiCall().then(showApiSuccessToast);
 *   // or: someApiCall().then(data => showApiSuccessToast(data, "/home"));
 *   // or: someApiCall().then(data => showApiSuccessToast({ title: 'You got it!', description: 'Loan found' }, `/loans/${data.loan.uuid}`));
 */
export const useApiSuccessToast = () => {
  const showSuccessToast = useSuccessToast();

  return (
    data: { message?: string; title?: string; description?: string } = {},
    redirectTo?: string
  ) =>
    showSuccessToast(
      { description: data.description || data.message, title: data.title },
      redirectTo
    );
};

/** Returns two typical toasts for handling API error and success states more cleanly */
export const useEasyToasts = () => {
  const showErrorToast = useErrorToast();
  const showApiSuccessToast = useApiSuccessToast();
  return { showErrorToast, showApiSuccessToast };
};
