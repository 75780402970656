import { useContext } from "react";

import { ExploreAddress } from "Components/Shared/Elements/Crypto/ExploreAddress";
import { AccountNameCell } from "Components/Shared/Elements/Summary/AccountNameCell";
import { SummaryTableCell } from "Components/Shared/Elements/Summary/SummaryTableCell";
import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";

export const ToDestinationsCell = () => {
  const {
    batchData,
    isBatchSpendFlow,
    isTransferFlow,
    transferDestination,
    singleDestinationAddress,
  } = useContext(WithdrawModalContext);

  if (isBatchSpendFlow && batchData.length === 1) {
    return (
      <SummaryTableCell>
        <ExploreAddress
          address={batchData[0].address.value}
          unit="BTC"
          classes={{
            div: "inline-flex items-center",
            icon: "h-4",
          }}
        />
      </SummaryTableCell>
    );
  }

  if (isBatchSpendFlow) {
    return <SummaryTableCell>{`${batchData.length} addresses`}</SummaryTableCell>;
  }

  if (isTransferFlow) {
    return (
      <AccountNameCell
        name={transferDestination.name}
        uuid={transferDestination.productUuid}
        product_type={transferDestination.productType}
      />
    );
  }

  return (
    <SummaryTableCell>
      <ExploreAddress
        address={singleDestinationAddress}
        unit="BTC"
        classes={{
          div: "inline-flex items-center",
          icon: "h-4",
        }}
      />
    </SummaryTableCell>
  );
};
