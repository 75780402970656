import React from "react";

import { TextField, MenuItem, SelectProps } from "@mui/material";
import cx from "classnames";

import { ReceivingAccountType } from "Contexts/SellBitcoin/types";

import { BankAccountInterface } from "../bankAccountUtils";
import styles from "./UpdatePaymentMethod.module.scss";

/**
 * @description - A component that allows the user to select
 * a specific bank account from a list of bank accounts.
 *
 * Legacy bank accounts are not selectable and should only be passed
 * to this component if the legacy account is still the active account
 * for the loan.
 *
 * @param {array} bankAccounts - Array of full bank account objects.
 * @param {string} label - Label text displayed in the MUI select/textbox.
 * @param {string} handleAccountSelect - Generally a set function from useState.
 * @param {string} selectedAccount - UUID of currently selected bank account.
 */

export const BankAccountSelect = ({
  bankAccounts,
  label = "",
  handleAccountSelect,
  selectedAccount,
  allowAddABank = true,
  hideLabel,
  containerStyles = styles.bankAccount,
  selectProps,
  addABankStyles,
  onClickAddABank = () => {
    window.open("/payment-methods?tab=bankAccounts", "_blank");
  },
  cashBalance,
  cashBalanceLabel = "Cash balance",
  isSelectable = true,
}: BankAccountSelectProps) => {
  const notSelectableProps = {
    onclick: () => {},
    open: false,
    IconComponent: () => null,
  };

  return (
    <div className={containerStyles}>
      <TextField
        fullWidth
        select
        variant="outlined"
        value={selectedAccount}
        label={label}
        className={styles.bankAccountSelect}
        onChange={e => {
          handleAccountSelect(e.target.value);
        }}
        InputLabelProps={hideLabel ? { shrink: false } : undefined}
        SelectProps={{
          ...(isSelectable ? {} : notSelectableProps),
          ...selectProps,
        }}
        inputProps={{
          "data-testid": "bankAccountSelect",
        }}
      >
        {!!cashBalance && (
          <MenuItem
            value={ReceivingAccountType.CASH_BALANCE}
            key={ReceivingAccountType.CASH_BALANCE}
          >
            <div data-testid="cash-balance-account" className={styles.selectOption}>
              <h4 className={cx(styles.accountName)}>{cashBalanceLabel}</h4>
              <div className={cx(styles.accountNumber)}>({cashBalance})</div>
            </div>
          </MenuItem>
        )}
        {!!bankAccounts &&
          bankAccounts.map(account => {
            const { uuid, name, account_number: accountNumber, isLegacy } = account;
            const shortAccountNumber = accountNumber?.substring(accountNumber.length - 4);
            const legacyActive = isLegacy && selectedAccount === uuid;
            return (
              <MenuItem value={uuid} key={uuid} disabled={isLegacy}>
                <div className={styles.selectOption}>
                  <h4
                    className={cx(styles.accountName, {
                      [styles.accountName_inactive]: legacyActive,
                    })}
                  >
                    {name}
                  </h4>
                  <div
                    className={cx(styles.accountNumber, {
                      [styles.accountNumber_inactive]: legacyActive,
                    })}
                  >
                    (ending {shortAccountNumber})
                  </div>
                </div>
              </MenuItem>
            );
          })}
      </TextField>

      {legacySelected({ bankAccounts, selectedAccount }) && (
        <div className={cx("t300", styles.helperTextContainer)}>
          We’ve updated our bank account verification process. This account can still be used for
          this loan, but new loans need to use a linked bank account.
        </div>
      )}
      {allowAddABank ? (
        <div className={cx("t300", styles.helperTextContainer)}>
          <a className={addABankStyles || styles.linkText} onClick={onClickAddABank}>
            Add a bank account
          </a>
        </div>
      ) : null}
    </div>
  );
};

interface BankAccountSelectProps {
  bankAccounts?: BankAccountInterface[];
  label?: string;
  handleAccountSelect?: (account: string) => void;
  selectedAccount: string;
  allowAddABank?: boolean;
  hideLabel?: boolean;
  containerStyles?: string;
  selectProps?: SelectProps;
  addABankStyles?: string;
  onClickAddABank?: () => void;
  cashBalance?: string;
  cashBalanceLabel?: string;
  isSelectable?: boolean;
}

const legacySelected = ({ bankAccounts, selectedAccount }) => {
  const legacyAccount = bankAccounts.find(account => {
    return account.uuid === selectedAccount && account.isLegacy;
  });

  return !!legacyAccount;
};
