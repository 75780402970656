import { GetUserWorkspaceData200, UpdateWorkspaceOrgMemberSettingsRequest } from "Specs/v2";

import { ApiType } from "../apiType";

export const WorkspacesAPIFactory = (API: ApiType) => ({
  GetUserWorkspaceData: async (blockOnError = false) => {
    try {
      return (await API.Get<GetUserWorkspaceData200>(`/workspace`)).data;
    } catch (error) {
      if (blockOnError) throw error;

      // This is generally a foundational query.
      // By default, we don't want to block anything if we error.
      // Just don't show the Unified IA.
      console.error("Failed fetching workspace. Providing empty placeholder", error);
      return {
        user: {
          id: "",
          canViewUnifiedIA: false,
          isSignature: false,
          isUnchainedAdmin: false,
        },
        orgs: [],
        unacceptedMemberships: [],
        orgMemberSettings: [],
      } as GetUserWorkspaceData200;
    }
  },

  UpdateOrgWorkspaceMemberSettings: async (
    workspaceId: string,
    orgId: string,
    settings: UpdateWorkspaceOrgMemberSettingsRequest
  ) =>
    await API.Put<UpdateWorkspaceOrgMemberSettingsRequest>(
      `/workspaces/${workspaceId}/org-members/${orgId}/settings`,
      settings
    ),
});
