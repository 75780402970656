import { useState } from "react";

import { Appearance, loadStripe, StripeElementsOptions } from "@stripe/stripe-js";

import { Invoice } from "Shared/api/v2/invoices";
import { getConfig } from "Utils/config";

const stripeStyles: Appearance = {
  theme: "stripe",
  labels: "floating",
  variables: {
    spacingGridRow: ".5rem",
  },
  rules: {
    ".Label--resting": {
      color: "#667085",
    },

    ".Tab:hover": {
      color: "var(--colorText)",
    },

    ".Tab--selected": {
      borderColor: "#E0E6EB",
      boxShadow:
        "0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(18, 42, 66, 0.02), 0 0 0 2px var(--colorPrimary)",
    },

    ".Input--invalid": {
      boxShadow: "0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 0 0 2px var(--colorDanger)",
    },
  },
};

export const createStripeElementOptions = (stripeClientSecret?: string) => {
  const stripeElementOptions: StripeElementsOptions = {
    clientSecret: stripeClientSecret || undefined,
    appearance: stripeStyles,
  };
  return stripeElementOptions;
};

// Countries we are willing to ship to.
//
// We have specifically left off the following countries
//  Iran
//  North Korea (DPRK)
//  Syria
//  Yemen
//  South Sudan
//  Congo, Democratic Republic
//  Ukraine
//  Lebanon
//  Libya
//  Zimbabwe
//  Iraq
//  Sudan
//  Belarus
//  Venezuela
//  Cuba
//  Somalia
//  Burundi
//  Central African Republic
//  Slovenia
//  Croatia
//  Bosnia and Herzegovina
//  Serbia
//  Montenegro
//  Albania
//  Macedonia
//  Greece
//  Bulgaria
//  Romania
//  Russia

// As of November 2024 Unchained does not support shipping outside of the US
export const stripeShippingCountryCodes = ["US"];

export const useStripeElementProps = (clientSecret?: string) => {
  const stripeApiPublishableKey = getConfig("stripe.public")["publishable_api_key"];

  const [stripePromise] = useState(() => loadStripe(stripeApiPublishableKey));
  const stripeOptions = createStripeElementOptions(clientSecret);

  return { stripe: stripePromise, options: stripeOptions };
};
