import {
  ConfirmStreamingQuoteResponse,
  InitiateStreamingQuoteResponse,
} from "@unchained/api-specs/cosimo/v1/webSocketSellBitcoin/101";

import { BankAccountInterface } from "Components/bank_accounts/bankAccountUtils";
import {
  SET_TRADING_JWT,
  WEB_SOCKET_CLOSED,
  WEB_SOCKET_ERROR,
  WEB_SOCKET_NULL,
  WEB_SOCKET_OPEN,
} from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { Fee } from "Contexts/BuyBitcoin/types";

import {
  DELETE_SELL_BITCOIN_IN_PROGRESS,
  SET_CLOSE_CONFIRMED_SALE,
  SET_CURRENT_SALE_UUID,
  SET_IS_SELL_BITCOIN_AVAILABLE,
  SET_IS_SHOW_CANCEL_MODAL,
  SET_SAVE_IN_PROGRESS_AND_CLOSE,
  SET_RECEIVING_ACCOUNT_TYPE,
  SET_SELECTED_BANK_ACCOUNT,
  SET_SELECTED_KEYS,
  SET_SELL_AMOUNT,
  SET_SELL_BITCOIN_DENIED,
  SET_SELL_BITCOIN_SOURCE,
  SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS,
  SET_SELL_BITCOIN_SUCCESS,
  SET_SELL_BITCOIN_WEB_SOCKET_STATUS,
  SET_SELL_INFO,
  SET_SELL_IN_PROGRESS_DATA,
  SET_STREAMING_QUOTE_SELL_RESPONSE,
} from "./SellBitcoinConstants";

export enum SellStatus {
  ONLINE = "online",
  OFFLINE = "offline",
  NO_SELL_POWER = "noBitcoinAvailable",
  PENDING_VAULT_TX = "pendingVaultTx",
  NO_VAULTS = "noVaults",
  NO_BANKS = "noBanks",
  SALE_TX_AWAITING_CONFIRMATION = "saleTxAwaitingConfirmation",
  TOO_MANY_VAULTS = "tooManyVaults",
}

export enum SellStreamingQuoteMessageTypes {
  STREAMING_QUOTE_SELL_RESPONSE = "STREAMING_QUOTE_RESPONSE",
  CONFIRM_SELL_RESPONSE = "TRADE_RESPONSE",
  STREAMING_QUOTE_CLOSE_REQUEST = "STREAMING_QUOTE_CLOSE_REQUEST",
}

export enum SellStreamingQuoteStatus {
  // No request has yet been made to initiate a streaming quote.
  STREAMING_QUOTE_NOT_INITIATED = "STREAMING_QUOTE_NOT_INITIATED",
  // The state in which a client has requested a streaming quote but has not recieved a response yet
  AWAITING_INITIAL_STREAMING_QUOTE = "AWAITING_INITIAL_STREAMING_QUOTE",

  // The state in which the new streaming quote has just arrived. This state is needed to create
  // a freeze state between transitioning from the old quote to the new one.
  LOADING_INCOMING_STREAMING_QUOTE = "LOADING_INCOMING_STREAMING_QUOTE",

  // The default state of a user actively receiving incoming streaming quotes.
  ACTIVE_STREAMING_QUOTE = "ACTIVE_STREAMING_QUOTE",

  // The state after a user has accepted a streaming quote but the server has yet to respond.
  CLIENT_ACCEPTED_STREAMING_QUOTE = "CLIENT_ACCEPTED_STREAMING_QUOTE",

  // The state after the server has confirmed that the quote has been accepted.
  SERVER_CONFIRMED_THE_ACCEPTED_STREAMING_QUOTE = "SERVER_CONFIRMED_THE_ACCEPTED_STREAMING_QUOTE",

  // The state after the server has denied the quote that the client tried to accept.
  SERVER_DENIED_THE_ACCEPTED_STREAMING_QUOTE = "SERVER_DENIED_THE_ACCEPTED_STREAMING_QUOTE",

  // The state after a client has sent a request to the server to stop sending streaming quotes.
  CLIENT_CLOSED_STREAMING_QUOTE = "CLIENT_CLOSED_STREAMING_QUOTE",
}

export enum streamingQuoteConfirmation {
  ACCEPTED = "accepted",
  DENIED = "denied",
}

export interface SourceVault {
  id?: string;
  name?: string;
  balance?: string;
  balanceUsd?: string;
  hasPendingTx?: boolean;
}

export type OfflineStatus = {
  description: string;
  title: string;
};

export enum ReceivingAccountType {
  CASH_BALANCE = "CASH_BALANCE",
  BANK = "BANK",
}

export type SellBitcoinInitialState = {
  isSellingFeatureAvailable: boolean;
  isSaleInProgress: boolean;
  sellAmount: string | null;
  sellStatus: SellStatus | null;
  sellBitcoinWebSocketStatus: SellBitcoinWebSocketStatus;
  tradingJwt: string | null;
  streamingQuoteStatus: SellStreamingQuoteStatus;
  streamingQuoteId: string | null;
  streamingQuoteVersion: number | null;
  bankName: string;
  bankLastFourDigits: string;
  bankAccounts: BankAccountInterface[];
  selectedBankAccount: BankAccountInterface | null;
  bitcoinPrice: string;
  saleAmountBTC: string;
  saleAmountUSD: string;
  amountUSDToBeSentToClient: string;
  feeAmountUSD: string;
  feeRates: Fee[];
  sellLimitBTC: string;
  minimumSaleAmount: SellAmountLimits | null;
  maximumSaleAmount: SellAmountLimits | null;
  availableSaleAmountBTC: string;
  sources: {
    vaults: SourceVault[];
  };
  transactionUuid: string;
  transactionFeeAmountUSD: string;
  transactionFeeAmountBTC: string;
  transactionFeeSatsVByte: string;
  selectedSource: SellSource;
  selectedKeys: SellKey[];
  allKeys: SellKey[];
  unchainedBTCReceiveAddress: string;
  customerBTCChangeAddress: string;
  isShowCancelModal: boolean;
  currentSaleUuid: string | null;
  offlineStatus: OfflineStatus;
  hasReviewedTx: boolean;
  txVideoVerification: { isVerificationNeeded: boolean; isVideoVerified: boolean };
  hasConfirmedSignatures: boolean;
  hasBroadcastTx: boolean;
  cashBalanceUsd: string;
  receivingAccountType?: ReceivingAccountType;
};

export type SellKey = {
  isUnchained: boolean;
  isDelegatedKey: boolean;
  logoSlug: string | null;
  name: string;
  uuid?: string;
};

export interface SellSource {
  id: string;
  type: "vault";
  name: string;
}

export type SellAmountLimits = {
  USD?: string;
  BTC?: string;
};

export type SellBitcoinWebSocketStatus =
  | typeof WEB_SOCKET_OPEN
  | typeof WEB_SOCKET_CLOSED
  | typeof WEB_SOCKET_NULL
  | typeof WEB_SOCKET_ERROR;

// actions
export type SetSellBitcoinAmount = {
  type: typeof SET_SELL_AMOUNT;
  payload: { amount: string };
};

export type SetSellBitcoinWebSocketStatus = {
  type: typeof SET_SELL_BITCOIN_WEB_SOCKET_STATUS;
  payload: { status: SellBitcoinWebSocketStatus };
};

export type SetTradingJwtAction = {
  type: typeof SET_TRADING_JWT;
  payload: { tradingJwt: string };
};
export type SetSellBitcoinStreamingQuoteStatus = {
  type: typeof SET_SELL_BITCOIN_STREAMING_QUOTE_STATUS;
  payload: { streamingQuoteStatus: SellStreamingQuoteStatus };
};

export type SetSellBitcoinOriginator = {
  type: typeof SET_SELL_BITCOIN_SOURCE;
  payload: { source: SellSource };
};

export type SetSelectedBankAccount = {
  type: typeof SET_SELECTED_BANK_ACCOUNT;
  payload: { selectedBankAccount: BankAccountInterface };
};

export type SetReceivingAccountType = {
  type: typeof SET_RECEIVING_ACCOUNT_TYPE;
  payload: { receivingAccountType: ReceivingAccountType };
};

export type SetSelectedKeys = {
  type: typeof SET_SELECTED_KEYS;
  payload: { selectedKeys: SellKey[] };
};
export type SetIsShowCancelModal = {
  type: typeof SET_IS_SHOW_CANCEL_MODAL;
  payload: { isShowCancelModal };
};

export type SetIsSellTradingFeatureAvailable = {
  type: typeof SET_IS_SELL_BITCOIN_AVAILABLE;
  payload: { isSellAvailable: boolean; isSaleInProgress: boolean };
};

export type SetSellInfo = {
  type: typeof SET_SELL_INFO;
  payload: {
    bitcoinPrice: string;
    sources: {
      vaults: SourceVault[];
    };
    sellStatus: SellStatus;
    minimumSaleAmount: SellAmountLimits;
    maximumSaleAmount: SellAmountLimits;
    availableSaleAmountBTC: string;
    feeRates: Fee[];
    bankAccounts: BankAccountInterface[];
    currentSaleUuid: string;
    offlineStatus: OfflineStatus;
    sellLimitBTC: string;
    jwt: string;
    cashBalanceUsd: string;
  };
};

export type SellInProgressDataPayload = {
  sellAmount: string;
  saleAmountBTC: string;
  saleAmountUSD: string;
  amountUSDTobeSentToClient: string;
  feeAmountUSD: string;
  transactionFeeSatsVByte: string;
  transactionFeeAmountUSD: string;
  transactionFeeAmountBTC: string;
  customerBTCChangeAddress: string;
  unchainedBTCReceiveAddress: string;
  bankName: string;
  bankLastFourDigits: string;
  selectedSource: SellSource;
  selectedKeys: SellKey[];
  allKeys: SellKey[];
  transactionUuid: string;
  hasReviewedTx: boolean;
  txVideoVerification: { isVerificationNeeded?: boolean; isVideoVerified?: boolean };
  hasConfirmedSignatures: boolean;
  hasBroadcastTx: boolean;
  receivingAccountType: ReceivingAccountType;
};
export type SetSellInProgressData = {
  type: typeof SET_SELL_IN_PROGRESS_DATA;
  payload: SellInProgressDataPayload;
};

export type SetCurrentSaleUuid = {
  type: typeof SET_CURRENT_SALE_UUID;
  payload: { currentSaleUuid: string };
};

export type DeleteSellBitcoinInProgress = {
  type: typeof DELETE_SELL_BITCOIN_IN_PROGRESS;
  payload: null;
};

export type SetStreamingQuoteSellResponse = {
  type: typeof SET_STREAMING_QUOTE_SELL_RESPONSE;
  payload: InitiateStreamingQuoteResponse;
};

export type SetSellBitcoinSuccess = {
  type: typeof SET_SELL_BITCOIN_SUCCESS;
  payload: ConfirmStreamingQuoteResponse;
};

export type SetSellBitcoinRejected = {
  type: typeof SET_SELL_BITCOIN_DENIED;
};

export type SetSaveProgressAndClose = {
  type: typeof SET_SAVE_IN_PROGRESS_AND_CLOSE;
  payload: null;
};
export type SetCloseConfirmedSale = {
  type: typeof SET_CLOSE_CONFIRMED_SALE;
  payload: null;
};

export type SellBitcoinActions =
  | SetIsShowCancelModal
  | SetSellBitcoinAmount
  | SetSellBitcoinWebSocketStatus
  | SetTradingJwtAction
  | SetSellBitcoinStreamingQuoteStatus
  | SetSellBitcoinOriginator
  | SetSelectedBankAccount
  | SetSelectedKeys
  | SetIsSellTradingFeatureAvailable
  | SetSellInfo
  | SetSellInProgressData
  | SetCurrentSaleUuid
  | DeleteSellBitcoinInProgress
  | SetStreamingQuoteSellResponse
  | SetSellBitcoinSuccess
  | SetSellBitcoinRejected
  | SetSaveProgressAndClose
  | SetCloseConfirmedSale
  | SetReceivingAccountType;
