import { useMutation, useQuery, useQueryClient } from "react-query";

import { AccountKey } from "Specs/v1/listVaults/200";

import { KeyAPI, OrgAPI } from "..";

export const keyRequestKeys = {
  all: ["keys"] as const,
  accountKeys: (orgUuid: string) => [...keyRequestKeys.all, "accountKeys", orgUuid],
  availableDelegateKeys: (uuid: string) => [...keyRequestKeys.all, "availableDelegateKeys", uuid],
  accountKey: (keyUuid: string) => [...keyRequestKeys.all, "accountKey", keyUuid],
  vagabond: (keyUuid: string) => [...keyRequestKeys.all, "vagabond", keyUuid],
};

export const useGetOrgKeys = (orgUuid: string) =>
  useQuery<AccountKey[], Error>(
    keyRequestKeys.accountKeys(orgUuid),
    () => KeyAPI.ListForOrg(orgUuid),
    {
      enabled: !!orgUuid,
    }
  );

export const useGetOrgKeysAsArray = (orgUUID: string) => {
  const { data, ...rest } = useQuery<AccountKey[], Error>(keyRequestKeys.accountKeys(orgUUID), () =>
    KeyAPI.ListForOrg(orgUUID)
  );
  const orgKeys = data ? Object.values(data) : [];
  return { data: orgKeys, ...rest };
};

export const useDelegateKeys = (orgUUID: string) => {
  const { data, isLoading, error } = useQuery(keyRequestKeys.availableDelegateKeys(orgUUID), () =>
    OrgAPI.GetAvailableDelegatedCustodyKeys(orgUUID)
  );
  return { data, isLoading, error };
};

export const useAllAvailableKeys = (orgUUID: string) => {
  const {
    data: orgKeysData,
    isLoading: orgKeysIsLoading,
    error: orgKeysError,
  } = useGetOrgKeysAsArray(orgUUID);
  const { data: delegateKeysData, isLoading: delegateKeysIsLoading } = useDelegateKeys(orgUUID);

  const allAvailableKeys = [...(orgKeysData || []), ...(delegateKeysData || [])];
  const isLoading = orgKeysIsLoading || delegateKeysIsLoading;

  return { data: allAvailableKeys, isLoading, error: orgKeysError };
};

export const useActivateDelegateKey = (keyUuid: string, orgUUID: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => KeyAPI.ActivateDelegateKey(keyUuid), {
    onSuccess: (data: { type: string; [key: string]: unknown }) => {
      queryClient.setQueryData(
        keyRequestKeys.accountKeys(orgUUID),
        (oldQueryData: Record<string, unknown>) => ({
          ...oldQueryData,
          [keyUuid]: data,
        })
      );
    },
  });
};

export const useGetAccountKey = (keyUuid: string) =>
  useQuery<AccountKey, Error>(keyRequestKeys.accountKey(keyUuid), () => KeyAPI.Get(keyUuid));

export const useEnableDeviceCheck = (keyUuid: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => KeyAPI.EnableCheck(keyUuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(keyRequestKeys.accountKey(keyUuid));
    },
  });
};

export const useDisableDeviceCheck = (keyUuid: string) => {
  const queryClient = useQueryClient();
  return useMutation(() => KeyAPI.DisableCheck(keyUuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(keyRequestKeys.accountKey(keyUuid));
    },
  });
};

export const useRenameKey = (keyUuid: string) => {
  const queryClient = useQueryClient();
  return useMutation((name: string) => KeyAPI.Rename(keyUuid, { name }), {
    onSuccess: () => {
      queryClient.invalidateQueries(keyRequestKeys.accountKey(keyUuid));
    },
  });
};
