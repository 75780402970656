import { Navigate, useParams } from "react-router-dom";

/**
 * Given a URL with dynamic parameters, fills them from params and navigates to the new URL
 *
 * Eg, redirects from `/orgs/:uuid/admin` to `/admin/orgs/:uuid`
 *
 * @example
 *
 * new RouteGroup().children({
 *   // Redirects from `/orgs/12345` to `/admin/orgs/12345`
 *   "/orgs/:uuid": <Remap to="/admin/orgs/:uuid" />
 * })
 */
export const Remap = ({ to }: { to: string }) => {
  const params = useParams();

  const filledPath = to.replace(/:[^/]+/g, match => params[match.slice(1)]);

  return <Navigate to={filledPath} />;
};
