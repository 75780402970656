import PropTypes from "prop-types";

import { parseTimestamp, readableTime } from "Utils/time";

import { Person, personProptypes } from "./persons";
import { verificationProptypes } from "./verifications";

export function userListingProptypes(admin) {
  if (admin) {
    return {
      ...userListingProptypes(),
      ...{
        ldap: PropTypes.bool.isRequired,
      },
    };
  } else {
    return {
      state: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
    };
  }
}

export function userProptypes(admin) {
  if (admin) {
    return {
      ...userProptypes(),
      ...{
        loans_funded_listings: PropTypes.array,
        is_deleteable: PropTypes.bool,
        note: PropTypes.string,
      },
    };
  } else {
    return {
      ...userListingProptypes(admin),
      ...{
        accepted_org_types: PropTypes.array.isRequired,
        phone: PropTypes.string.isRequired,
        has_otp: PropTypes.bool.isRequired,
        has_passkey: PropTypes.bool.isRequired,

        person: PropTypes.shape(personProptypes()),

        createdAt: PropTypes.object,
        updatedAt: PropTypes.object,

        readableCreatedAt: PropTypes.string,
        readableUpdatedAt: PropTypes.string,

        loan_listings: PropTypes.array,
        vault_listings: PropTypes.array,

        verification: PropTypes.shape(verificationProptypes()),
      },
    };
  }
}

export function UserListing(data) {
  if (data == null) {
    return data;
  }
  return data;
}

export function User(data) {
  if (data == null) {
    return data;
  }

  data = UserListing(data);
  data.createdAt = parseTimestamp(data.created_at);
  data.readableCreatedAt = readableTime(data.createdAt);
  data.updatedAt = parseTimestamp(data.updated_at);
  data.readableUpdatedAt = readableTime(data.updatedAt);

  data.loans = data.loan_listings || [];
  data.vaults = data.vault_listings || [];
  data.loans_funded = data.loans_funded_listings || [];

  if (data.person) {
    data.person = Person(data.person);
  }

  return data;
}
