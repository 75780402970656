import {
  UPDATE_USER_STATUS,
  UPDATE_USER_DATA,
  RESET_USER_DATA,
  UPDATE_USER_NOTE,
  UPDATE_USER_CODENAME,
} from "Actions/userActions/userActions";
import { REQUEST_STATUS } from "Shared/api/api";

const initialState = {
  status: REQUEST_STATUS.PENDING,
  state: "",
  phone: "",
  email: "",
  ldap: false,
  external_identifier: "",
  has_otp: false,
  has_passkey: false,
  accepted_org_types: [],
  person: {},
  is_deleteable: false,
  memberships: [],
  allowed_actions: [],
  name: "",
  uuid: "",
  codename: "",
  verification: {},
};

export const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_USER_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case UPDATE_USER_DATA: {
      return {
        ...state,
        ...payload,
      };
    }

    case UPDATE_USER_NOTE: {
      return {
        ...state,
        note: payload,
      };
    }

    case UPDATE_USER_CODENAME: {
      return {
        ...state,
        codename: payload,
      };
    }

    case RESET_USER_DATA: {
      return initialState;
    }

    default:
      return state;
  }
};
