import { useMutation, useQueryClient } from "react-query";

import { accountQueryKeys } from "Shared/api/hooks/account";
import { OrgAPI } from "Shared/api/v2";
import { workspaceQueryKeys } from "Shared/api/v2/hooks/workspaces";
import { triggerConfirmationModal } from "Shared/components/Modals";
import { useEasyToasts } from "Utils/toasts";

export const useDeleteOnboardingOrg = () => {
  const queryClient = useQueryClient();
  const { showErrorToast } = useEasyToasts();

  const closeAccount = useMutation(({ uuid }: { uuid: string }) => OrgAPI.CloseAccount(uuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(accountQueryKeys.get);
      queryClient.invalidateQueries(workspaceQueryKeys.get);
    },
    onError: error => showErrorToast(error),
  });

  return (uuid: string) =>
    triggerConfirmationModal({
      title: "Delete account?",
      subtitle:
        "Are you sure you want to delete this account? Please note this action cannot be undone.",
      confirmationText: "Yes, delete",
      onConfirm: () => closeAccount.mutate({ uuid }),
    });
};
