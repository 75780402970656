import { FC, ReactElement, ReactNode } from "react";

import { Loader } from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { ErrorPage } from "Components/ErrorBoundaries/ErrorPage";
import { useGetAccount, useIraOrg } from "Shared/api";
import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { CompleteOrg } from "Specs/v1/getOrg/200";
import { useQueryParams } from "Utils/uris";

import { NavigateWithToast } from "./NavigateWithToast";
import { AuthorizeProps, AuthorizerTemplate, authorizerTemplates } from "./authorizerTemplates";

/**
 * This module defines an `Authorize` component.
 *
 * Authorize is a component which wraps a child and can be passed an object of authorizers.
 * Each authorizer must be mapped to one of the templates defined in `./authorizerTemplates`.
 *
 * If the authorizer returns true, the child component will be blocked by the DefaultReplacement
 * defined in the list of `authorizerTemplates`, or by a custom replacement passed as a prop
 * to the Authorize component.
 */

/**
 * An AuthorizerReplacement can be either:
 * - A string (to redirect to)
 * - A function taking the required workspace/account/ira data (only what's fetched according to the template)
 *   and returning a string (for redirect) or React element.
 * - A React element
 */
type AuthorizerReplacement = string | ((args: AuthorizeProps) => string) | React.FC<AuthorizeProps>;

// Set to true locally to log more info of what's going on
// with route blocking.
const DEBUG = false;

export type AuthorizerName = (typeof authorizerTemplates)[number]["name"];

type InferReqType<T> = T extends (arg1: any, arg2: infer R) => any ? R : never;
type AuthorizerNameTemplateMap = {
  [K in AuthorizerName]: Extract<(typeof authorizerTemplates)[number], { name: K }>;
};
type AuthorizerNameReqMap = {
  [K in AuthorizerName]: InferReqType<AuthorizerNameTemplateMap[K]["shouldBlock"]>;
};

/** Given the name of a blocker template, extracts the type of its "req[uirements]" argument. */
type AuthorizerReq<Name extends AuthorizerName> = AuthorizerNameReqMap[Name];

type AuthorizerReplacementProps = {
  [K in AuthorizerName as `${K}Replacement`]?: AuthorizerReplacement;
};

/**
 * The type of the blockers object passed as a prop to the Block component.
 *
 * For each blocker in the templates, you can pass the appropriate props (req-specific or `true`),
 * and you can additionally pass a custom "blocker replacement" based on the blocker name
 * (eg `requireAdminReplacement` for the `requireAdmin` blocker).
 */
export type Authorizers = {
  [K in AuthorizerName]?: InferReqType<AuthorizerNameTemplateMap[K]["shouldBlock"]> extends never
    ? true
    : AuthorizerReq<K>;
} & AuthorizerReplacementProps;

/**
 * Because of the multiple formats of blocker replacements, we need a single component
 * to handle all of the options.
 */
const Replacement: FC<{
  passedReplacement: AuthorizerReplacement | undefined;
  template: AuthorizerNameTemplateMap[keyof AuthorizerNameTemplateMap];
  props: AuthorizeProps;
}> = ({ passedReplacement, template, props }) => {
  const params = useParams();
  // No replacement specified. Use the default replacement
  if (!passedReplacement) {
    return template.DefaultReplacement(props);
  }

  // Replacement is a string. Navigate to it
  if (typeof passedReplacement === "string") {
    // Remap any params in the string to the actual params
    const to = passedReplacement.replace(/:[^/]+/g, match => params[match.slice(1)]);
    return <NavigateWithToast to={to} />;
  }

  // Replacement is a function. Call it with the props to determine its return value
  const stringOrNode =
    typeof passedReplacement === "function" ? passedReplacement(props) : passedReplacement;

  // If the function returns a string, navigate to it
  if (typeof stringOrNode === "string") {
    return <NavigateWithToast to={stringOrNode} />;
  }

  // Otherwise, return the React element
  return stringOrNode;
};

const completeOrgToOrgItem = (org: CompleteOrg) => ({
  id: org?.uuid,
  accountType: org?.account_type,
  state: org?.state,
  type: org?.type,
  allowedActions: org?.allowed_actions,
});

/**
 * A hook for applying authorizations to any non-component context.
 *
 * If authorizing a component, you should typically use the Authorize component.
 **/
export const useAuthorizers = (authorizers: Authorizers, path?: string) => {
  const referencedTemplates = authorizerTemplates.filter(b => !!authorizers[b.name]);
  const needsWorkspace = referencedTemplates.some(b => b.requiredData.includes("workspace"));
  const needsAccount = referencedTemplates.some(b => b.requiredData.includes("account"));
  const needsIra = referencedTemplates.some(b => b.requiredData.includes("ira"));

  if (DEBUG) {
    console.log("AUTHORIZING", {
      referencedTemplates: referencedTemplates.map(b => b.name),
      needsWorkspace,
      needsAccount,
      needsIra,
      path,
    });
  }

  const params = useParams();
  const queryParams = useQueryParams();

  const accountQuery = useGetAccount({ enabled: needsAccount });
  const workspaceQuery = useWorkspaceData({ enabled: needsWorkspace });

  // Get an ID and account type regardless of which of workspace or account are used
  const currentOrgId = params.accountId || accountQuery.data?.currentOrg?.uuid;
  const workspaceOrg = workspaceQuery.data?.orgs.find(o => o.id === currentOrgId);
  const currentOrgAccountType =
    workspaceOrg?.accountType || accountQuery.data?.currentOrg?.account_type;

  const enableIra = needsIra && !!currentOrgId && currentOrgAccountType === "ira";
  const iraQuery = useIraOrg(currentOrgId, { enabled: enableIra });

  // Only watch for errors/loading in the queries we've enabled.
  const queries = [
    needsAccount && accountQuery,
    needsWorkspace && workspaceQuery,
    needsIra && iraQuery,
  ].filter(Boolean);

  const partialAuthorizerProps: AuthorizeProps = {
    params,
    queryParams,
    orgId: currentOrgId,
  };

  if (queries.some(q => q.isError)) {
    return {
      isLoading: false,
      isError: true,
      matchingAuthorizers: [],
      isAuthorized: undefined,
      firstFailedAuthorizer: null,
      firstFailedAuthorizerReplacement: null,
      authorizerProps: partialAuthorizerProps,
    };
  }

  if (queries.some(q => q.isLoading)) {
    return {
      isLoading: true,
      isError: false,
      matchingAuthorizers: [],
      isAuthorized: undefined,
      firstFailedAuthorizer: null,
      firstFailedAuthorizerReplacement: null,
      authorizerProps: partialAuthorizerProps,
    };
  }

  const account = accountQuery.data;
  const workspace = workspaceQuery.data;
  const ira = iraQuery.data?.org;

  const authorizerProps: AuthorizeProps = {
    account,
    workspace,
    ira,
    orgId: currentOrgId,
    org: workspaceOrg || completeOrgToOrgItem(account?.currentOrg),
    params,
    queryParams,
  };

  const authorizerNames = Object.keys(authorizers).filter(
    name => !name.endsWith("Replacement")
  ) as AuthorizerName[];

  const matchingTemplates = authorizerTemplates.filter(template =>
    authorizerNames.includes(template.name)
  );

  const matchingAuthorizers = (() => {
    const matching = [];
    for (const template of matchingTemplates) {
      const authorizerName = template.name;
      const authorizer = authorizers[authorizerName];

      type Req = AuthorizerReq<typeof authorizerName>;

      const authorizerTest = template?.shouldBlock as AuthorizerTemplate<Req>["shouldBlock"];

      if (!authorizerTest) {
        throw new Error(`Authorizer ${authorizerName} not found`);
      }

      const requirements = authorizer === true ? undefined : authorizer;
      const shouldBlock = authorizerTest(authorizerProps, requirements);

      if (shouldBlock) {
        const reason = (template?.blockBecause as AuthorizerTemplate<Req>["blockBecause"])(
          requirements
        );
        matching.push([authorizerName, template, reason]);
      }
    }
    return matching;
  })();

  const firstFailedAuthorizer = matchingAuthorizers[0];
  let firstFailedAuthorizerReplacement;
  if (firstFailedAuthorizer) {
    const [authorizerName, template, reason] = firstFailedAuthorizer;
    const passedReplacement = authorizerName
      ? authorizers[`${authorizerName}Replacement` as const]
      : null;

    if (DEBUG) {
      console.log(
        `AUTHORIZING: All matching blockers: ${matchingAuthorizers.map(b => b[0]).join(", ")}`
      );
      console.log(
        `AUTHORIZING: First matching blocker: "${authorizerName}", with reason: "${reason}"`
      );
    }

    firstFailedAuthorizerReplacement = (
      <Replacement
        passedReplacement={passedReplacement}
        template={template}
        props={authorizerProps}
      />
    );
  }

  return {
    isLoading: false,
    isError: false,
    isAuthorized: !firstFailedAuthorizer,
    firstFailedAuthorizer,
    firstFailedAuthorizerReplacement,
    matchingAuthorizers,
    authorizerProps,
  };
};

// This component separated out to avoid breaking hot reloading locally.
const Component: FC<{ children: ReactNode; path?: string } & Authorizers> = ({
  children,
  path,
  ...authorizers
}) => {
  const { isLoading, isError, firstFailedAuthorizer, firstFailedAuthorizerReplacement } =
    useAuthorizers(authorizers, path);

  if (isLoading) return <Loader className="h-screen" />;

  if (isError) return <ErrorPage />;

  if (firstFailedAuthorizer) return firstFailedAuthorizerReplacement;

  return children as ReactElement;
};

/**
 * Wraps a child, and blocks its rendering based on passed conditions.
 * Fetches only the data required to check the given blockers.
 * Can be provided a custom replacement for each blocker.
 * For more info on the blockers, see the `authorizerTemplates` variable above.
 *
 * Basic syntax:
 *
 * <Authorize
 *  requireAdmin
 *  requireAdminReplacement="/admin"
 *  requireBasicInfo
 *  requireBasicInfoReplacement={<span>Basic info is required</span>}
 *  requireUIA # Default replacement navigates back with toast
 * >
 *   <Component />
 * </Authorize>
 *
 * */
export const Authorize = (props: { children: ReactNode; path?: string } & Authorizers) => (
  <Component {...props} />
);
