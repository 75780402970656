import get from "lodash/get";

import { VaultAPI } from "Shared/api/vaultApi";

export const SET_VAULT_SHOW_VAULT_DATA = "SET_VAULT_SHOW_VAULT_DATA";
export const SET_VAULT_SHOW_STATUS = "SET_VAULT_SHOW_STATUS";
export const RESET_VAULT_SHOW_USER = "RESET_VAULT_SHOW_USER";
export const SET_VAULT_SHOW_NAME = "SET_VAULT_SHOW_NAME";

/**
 * Set Individual Vault action for the current user
 * @param userVault - retrieve user vault via an api call
 */

export const setVaultShowVaultDataAction = userVault => ({
  type: SET_VAULT_SHOW_VAULT_DATA,
  payload: userVault,
});

/**
 * Set Vault API request status action
 * @param status - set the api call status - action triggered based on current vault in store & new vault retrieved
 *
 */

export const setVaultShowStatusAction = status => ({
  type: SET_VAULT_SHOW_STATUS,
  payload: status,
});

export const setVaultShowNameAction = name => ({
  type: SET_VAULT_SHOW_NAME,
  payload: name,
});

export const resetVaultShowAction = () => ({
  type: RESET_VAULT_SHOW_USER,
  payload: null,
});

/**
 * Get Individual Vault action for the current user
 * @param uuid - specific uuid for vault being requested
 */

export const getVaultShowUserAction = uuid => {
  return async dispatch => {
    try {
      const vault = await VaultAPI.Get(uuid);
      dispatch(setVaultShowVaultDataAction(vault));
      dispatch(setVaultShowStatusAction("loaded"));
      return vault;
    } catch (e) {
      const status = get(e, "response.status", 0);
      switch (status) {
        case 403:
          dispatch(setVaultShowStatusAction("forbidden"));
          break;
        case 404:
          dispatch(setVaultShowStatusAction("not_found"));
          break;
        default:
          dispatch(setVaultShowStatusAction("error"));
          break;
      }
    }
  };
};
