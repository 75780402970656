import {
  CreateAdminManualChangeProposalGroup200,
  CreateAdminManualChangeProposalGroupRequest,
} from "Specs/v2";
import { AdminManualChangeTemplate } from "Specs/v2/components";

import { ApiType } from "../apiType";

export const AdminChangeProposalsAPIV2Factory = (API: ApiType) => ({
  GetOrgAdminManualChangeTemplate: async (orgUuid: string) =>
    (await API.Get<AdminManualChangeTemplate>(`/orgs/${orgUuid}/admin-manual-change-template`))
      .data,

  CreateManualChangeProposalGroup: async (
    orgUuid: string,
    changes: CreateAdminManualChangeProposalGroupRequest["changes"],
    reason?: string
  ) =>
    (
      await API.Post<CreateAdminManualChangeProposalGroup200>(
        "/admin-manual-change-proposal-groups",
        {
          orgUuid,
          changes,
          reason: reason || "Manual change",
        } as CreateAdminManualChangeProposalGroupRequest
      )
    ).data,
});
