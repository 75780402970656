/* global  UNCHAINED_LOG */
import $ from "jquery";

import { scrollToTop } from "Actions/common";
import { flash } from "Actions/flash";

import { editCommonMessages } from "./errors";

var log =
  process.env.REACT_APP_UNCHAINED_LOG !== undefined ? process.env.REACT_APP_UNCHAINED_LOG : false;

var defaultArgs = {
  method: "GET",
  contentType: "application/json",
  dataType: "json",
  cache: false,
  xhrFields: {
    withCredentials: true,
  },
};

var _csrfToken;

export function setCsrfToken(token) {
  _csrfToken = token;
}

export default args => {
  if (args.data && (args.processData == null || args.processData)) {
    args.data = JSON.stringify(args.data);
  }
  return $.ajax({
    ...defaultArgs,
    ...args,
    url: `${process.env.REACT_APP_API_LOCATION}${args.url}`,
    ...{
      beforeSend: function (xhr, settings) {
        if (log) {
          console.group(`${settings.method} ${settings.url}`);
        }
        xhr.setRequestHeader("X-CSRFToken", _csrfToken);
        xhr.withCredendials = true;
        if (log && settings.data) {
          console.info("Request Data:", settings.data);
        }
      },
      success: function (response, textStatus, xhr) {
        if (log) {
          console.info("Response Data:", response);
        }
        if (args.dispatch) {
          args.dispatch(flash("success", response.message));
        }
        if (args.success) {
          return args.success(response);
        }
        if (args.dispatch && args.scrollToTop) {
          args.dispatch(scrollToTop());
        }
        return response;
      },
      error: function (xhr, textStatus, errorMessage) {
        if (errorMessage === "UNAUTHORIZED") {
          window.location.href = "/login";
        }
        if (xhr.responseJSON) {
          var response = xhr.responseJSON;
          console.error(response.message);
          console.group("Errors:");
          var errors = response.errors || {};
          Object.keys(errors).forEach(function (field) {
            console.group(`${field}:`);
            (errors[field] || []).forEach(function (error) {
              console.error(error);
            });
            console.groupEnd();
          });
          console.groupEnd();
          const next = encodeURIComponent(window.location.pathname);
          response.message = editCommonMessages(response.message);
          if (args.dispatch) {
            args.dispatch(flash("error", response.message));
          }
          if (args.failure) {
            args.failure(response);
          }
          if (args.dispatch && args.scrollToTop) {
            args.dispatch(scrollToTop());
          }
          if (response.message === "Your session has ended. Please log in.") {
            window.location.href = `/login?next=${next}`;
          }
          if (response.message === "Your credentials do not match. Please log in.") {
            window.location.href = `/login?next=${next}`;
          }
          if (response.message === "Cannot connect to server.") {
            window.location.href = "/login";
          }
          if (response.message === "Please try again using https://") {
            window.location.href = `/login?next=${next}`;
          }
          if (response.message === "You are already logged in.") {
            window.location.href = "/";
          }
        } else {
          console.error(errorMessage);
          if (args.dispatch) {
            args.dispatch(flash("error", errorMessage));
          }
          if (args.error) {
            args.error(errorMessage);
          }
          if (args.dispatch && args.scrollToTop) {
            args.dispatch(scrollToTop());
          }
        }
      },
      complete: function (xhr, textStatus) {
        if (log) {
          console.groupEnd();
        }
      },
    },
  });
};
