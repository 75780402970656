import React from "react";

import { Button } from "@unchained/component-library";

import { useNavigate } from "Components/Link";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";

import { SellHeader } from "../components/SellHeader";

type PendingTxStepProps = {
  onClose: () => void;
};
export const PendingTxStep = ({ onClose }: PendingTxStepProps) => {
  const navigate = useNavigate();
  const { sources } = useSellBitcoinStore();

  // this step only appears when a user has atleast one vault
  // therefore it is safe to use the first vault
  // as the vault to navigate to.
  const vault = sources?.vaults[0];
  const SubTitle = () => {
    return (
      <div className="mt-2">
        You can only have one vault transaction in progress at a time. To sell bitcoin, you must
        first complete or cancel any existing vault transaction.
      </div>
    );
  };

  const navigateToVault = () => {
    if (vault) {
      navigate(`/vaults/${vault.id}`);
    }
    onClose();
  };

  return (
    <div className="w-10/12 max-w-lg">
      <SellHeader title={"Vault transaction in progress"} subTitle={<SubTitle />} />
      {vault && (
        <Button onClick={navigateToVault} fullWidth={true} color="primary">
          Go to vault
        </Button>
      )}
      <Button onClick={onClose} fullWidth={true} variant="text" className="mt-3">
        Close
      </Button>
    </div>
  );
};
