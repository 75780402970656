/* global TREFOIL_CONFIG TREFOIL_USER */
// The 'TREFOIL_CONFIG' variable is templated out into a separate JS
// file during the build process.
import get from "lodash/get";

export const TREFOIL_CONFIG_ERROR = "TREFOIL CONFIG ERROR";
// These type definitions enable type-safe access to nested configuration objects
// using dot notation (e.g. "some.nested.config.value")

// PathImpl recursively builds union type of all possible paths through an object
// For example, given { a: { b: string } }, PathImpl generates "a" | "a.b"
type PathImpl<T, K extends keyof T> = K extends string
  ? T[K] extends Record<string, any>
    ? K | `${K}.${PathImpl<T[K], keyof T[K]>}` // Recursively add nested paths
    : K // Base case - not an object, just return key
  : never;

// Path is the entry point - gets all possible path strings through type T
type Path<T> = PathImpl<T, keyof T>;

// PathValue gets the type of the value at a given path string
// For example, given { a: { b: string } } and path "a.b", returns string
type PathValue<T, P extends string> = P extends keyof T
  ? T[P] // Base case - direct property access
  : P extends `${infer K}.${infer R}` // Split path on first dot
  ? K extends keyof T
    ? PathValue<T[K], R> // Recursively get type of rest of path
    : never
  : never;

export function getConfig<P extends Path<typeof window.TREFOIL_CONFIG>>(
  key: P,
  defaultVal = null
): PathValue<typeof window.TREFOIL_CONFIG, P> {
  if (window.TREFOIL_CONFIG) {
    return get(window.TREFOIL_CONFIG, key, defaultVal);
  }

  throw Error(TREFOIL_CONFIG_ERROR);
}

export function minLength(field: keyof (typeof window.TREFOIL_CONFIG)["min_length"]) {
  return getConfig("min_length")[field];
}

export function maxLength(field: keyof (typeof window.TREFOIL_CONFIG)["max_length"]) {
  return getConfig("max_length")[field];
}

//
// Features
//

export function featureOn(name, user_or_org) {
  const features = user_or_org.all_features || {};
  return Boolean(Object.keys(features).includes(name) && features[name]);
}

export function featureOff(name, user_or_org) {
  const features = user_or_org.all_features || {};
  return !Object.keys(features).includes(name) || !features[name];
}
