import { ReactNode } from "react";

import { Logo, Skeleton, SkeletonText, Tooltip } from "@unchained/component-library";

import { Link } from "Components/Link";
import { useGetVaultsQuery } from "Shared/api/v2/hooks/vaults";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { OrgItem } from "Specs/v2/components";
import { relativePastDateString } from "Utils/dates";

import { orgIcon } from "../../OldSidebar/AccountSwitcher/accountHelpers";
import {
  getV1NotificationDetails,
  V1Notification as V1NotificationType,
} from "./getV1NotificationDetails";
import { V2Notification } from "./getV2NotificationDetails";

export type V1Notification = V1NotificationType & {
  org: OrgItem;
};

export type BaseNotification = {
  to?: string;
  onClick?: () => void;
  text: string;
  loading?: boolean;
};

export type NormalizedNotification = BaseNotification & {
  org?: OrgItem;
  timestamp: string;
  original: V1Notification | V2Notification;
};

const OrgIcon = ({ org, className }: { org: OrgItem; className?: string }) => {
  const type = org?.accountType || "all";
  const Icon = orgIcon(type);

  return (
    <Tooltip content={org?.title}>
      <span>
        <Icon variant="circle" size="lg" color={`account-${type}`} className={className} />
      </span>
    </Tooltip>
  );
};

const getNotificationIcon = (notification: NormalizedNotification) => {
  if (notification.org) {
    return <OrgIcon org={notification.org} />;
  }

  // V2 Notifications with "unchained" type are the only ones that don't have an org.
  // They should be rendered with the Unchained logo.
  return <Logo displayWordmark={false} height="36" width="36" />;
};

// Notification text is constructed wrapping text-to-be-bolded in triple asterisks.
// When we render it, we want to boldify the text within triple asterisks.
const boldify = (text: string) =>
  text.replace(/\*\*\*([^*]+)\*\*\*/g, "<span class='font-bold'>$1</span>");

const BaseNotificationRow = ({
  to,
  onClick,
  text,
  timestamp,
  icon,
  close,
  loading,
}: BaseNotification & {
  timestamp: string;
  icon: ReactNode;
  close: () => void;
  loading?: boolean;
}) => {
  return (
    <Link
      to={loading ? undefined : to}
      onClick={() => {
        if (loading) return;
        onClick?.();
        close();
      }}
      className="group flex cursor-pointer flex-row gap-2 px-4 py-2 transition-colors hover:bg-gray-100 hover:no-underline"
    >
      <div className="self-start">{icon}</div>
      <div className="flex flex-col justify-start">
        <p className="text-xs font-reg uppercase tracking-wide text-gray-500 group-hover:text-gray-700">
          {loading ? <LoadingText chars={10} /> : relativePastDateString(new Date(timestamp))}
        </p>

        {loading ? (
          <LoadingText chars={25} />
        ) : (
          <p
            className="text-sm font-reg text-gray-800 group-hover:text-gray-900"
            dangerouslySetInnerHTML={{ __html: boldify(text) }}
          />
        )}
      </div>
    </Link>
  );
};

// Query required for sign_transaction notifications
const useGetOwnedVaultIds = (orgId: string) => {
  const vaultsQuery = useGetVaultsQuery({ org: [orgId] }, { enabled: !!orgId });

  const vaults = vaultsQuery?.data?.data || [];
  const ownedVaultIds = vaults.reduce(
    (ids, vault) => (vault?.owner?.uuid === orgId ? [...ids, vault.uuid] : ids),
    []
  );

  return vaultsQuery.isSuccess ? ownedVaultIds : undefined;
};

// This, and only this, notification, requires additional queried data to fully render.
const SignTransactionNotificationRow = ({
  notification,
  close,
}: {
  notification: NormalizedNotification;
  close: () => void;
}) => {
  const ownedVaultIds = useGetOwnedVaultIds(notification.org.id);
  // We re-calculate the normalized notification given the specific data we need to render.
  const { text, to, onClick, loading } = getV1NotificationDetails(
    notification.org.id,
    notification.original as V1Notification,
    { ownedVaultIds }
  );

  const icon = getNotificationIcon(notification);

  return (
    <BaseNotificationRow
      loading={loading}
      close={close}
      to={to}
      onClick={onClick}
      text={text}
      timestamp={notification.timestamp}
      icon={icon}
    />
  );
};

const LoadingText = ({ chars = 25 }: { chars?: number }) => (
  <Skeleton>
    <SkeletonText numberOfCharacters={chars} />
  </Skeleton>
);

export const NotificationRow = withWorkspaceData<{
  notification: NormalizedNotification;
  close: () => void;
}>(({ notification, close }) => {
  const { to, text, onClick, timestamp, original } = notification;

  if (!text) return null;

  // Annoying special case
  // Requires custom query to get owned vault ids
  if ((original as V1Notification)?.actionType === "sign_transaction") {
    return <SignTransactionNotificationRow notification={notification} close={close} />;
  }

  const icon = getNotificationIcon(notification);

  return (
    <BaseNotificationRow
      close={close}
      to={to}
      onClick={onClick}
      text={text}
      timestamp={timestamp}
      icon={icon}
    />
  );
});
