import { useMutation } from "react-query";

import { Formik, FormikDropdown } from "Components/FormFields";
import { FormikCheckboxGroup } from "Components/FormFields/FormikCheckboxGroup";
import { Link } from "Components/Link";
import { ManifestStep } from "Routes/onboard/(shared)";
import { useWrapManifestMutation } from "Routes/onboard/(utils)";
import { ProfileAPI } from "Shared/api";
import {
  CompleteOrg,
  completeOrgPersonIncomeEnum,
  completeOrgPersonIncomeSourcesEnum,
} from "Specs/v1/getOrg/200";

import {
  addDollarSignsToMoneyRange,
  initIncome,
  validateIncome,
} from "../../individualOrgValsAndValidations";

export const PersonalIncome = ({ org }: { org: CompleteOrg }) => {
  const initVals = initIncome(org);
  const wrapManifestMutation = useWrapManifestMutation({
    orgUuid: org.uuid,
    isIra: "iraPlan" in org,
  });
  const putIncome = wrapManifestMutation(
    useMutation(({ income, income_sources }: typeof initVals) =>
      ProfileAPI.UpdateAdvanced(org.uuid, { income, income_sources: income_sources.join(", ") })
    )
  );

  const onSubmit = ({ income, income_sources }: typeof initVals) =>
    putIncome.mutate({ income, income_sources });

  return (
    <Formik<typeof initVals>
      initialValues={initVals}
      onSubmit={onSubmit}
      validationSchema={validateIncome}
      debugName="PersonalIncome"
    >
      {({ handleSubmit }) => {
        return (
          <ManifestStep
            width="narrow"
            actions={[
              {
                children: "Continue",
                onClick: handleSubmit,
                type: "submit",
              },
            ]}
            title="Personal income"
            description={
              <span>
                To adhere to federal regulations and to prevent the creation of fraudulent accounts,
                this information is required to verify your account. Read more{" "}
                <Link to="https://unchained.com/terms-of-service">here</Link>.
              </span>
            }
          >
            <div className="flex w-full flex-col">
              <h3 className="mb-4 text-sm font-med text-gray-700">Annual income range</h3>
              <FormikDropdown
                name="income"
                options={completeOrgPersonIncomeEnum.map(value => ({
                  label: addDollarSignsToMoneyRange(value),
                  value: value,
                }))}
                fullWidth
              />

              <h3 className="mb-4 mt-8 text-sm font-med text-gray-700">
                Source of income (select all that apply)
              </h3>
              <FormikCheckboxGroup
                showError
                options={completeOrgPersonIncomeSourcesEnum}
                name="income_sources"
                className="grid gap-4 sm:grid-cols-2"
              />
            </div>
          </ManifestStep>
        );
      }}
    </Formik>
  );
};
