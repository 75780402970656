import { ReactNode } from "react";

import { Icons } from "@unchained/component-library";
import cn from "classnames";
import { useLocation } from "react-router-dom";

import { Link } from "Components/Link";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";

export const UnifiedIADetailHeader = withWorkspaceData<{
  path?: string;
  pathText?: string;
  titleOverride?: ReactNode;
}>(({ org = {}, tabName, path, pathText, titleOverride }) => {
  const { pathname } = useLocation();

  const backToPath = path || pathname.split(tabName)[0] + tabName;

  const { title, subtitle, accountType } = org;

  const text = pathText || "All " + tabName;

  const textColorClass = {
    individual: "text-account-individual",
    business: "text-account-business",
    ira: "text-account-ira",
    trust: "text-account-trust",
  }[accountType];

  return (
    <Link to={backToPath} className="group flex flex-col gap-2 no-underline hover:no-underline">
      <span className="flex flex-row items-center gap-2 font-semi transition-colors group-hover:text-primary-800">
        <Icons.ArrowLeft />
        <span className="transition-all group-hover:ml-1">{text}</span>
      </span>
      {titleOverride || (
        <div className="flex flex-row items-end gap-2">
          <span className={cn("text-sm font-med uppercase tracking-widest", textColorClass)}>
            {title}
          </span>
          <span className="text-xs font-reg text-gray-400">{subtitle}</span>
        </div>
      )}
    </Link>
  );
});
