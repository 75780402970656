import { useQuery } from "react-query";

import { MFAResetsApi } from "../MFAResetsApi";

export const mfaResetKeys = {
  all: ["mfaResets"] as const,
  showAdmin: (token: string) => ["mfaResets", "showAdmin", token] as const,
};

export const useMFAResetQuery = (token: string) =>
  useQuery(mfaResetKeys.showAdmin(token), () => MFAResetsApi.GetMFAResetAdmin(token));
