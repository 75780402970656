import React, { useContext, useEffect, useState } from "react";

import { CircularProgress } from "@mui/material";
import {
  Button,
  LeadingCheckbox,
  useToast,
  WizardStepperContext,
} from "@unchained/component-library";
import { useSelector } from "react-redux";

import { Link } from "Components/Link";
import { sellBitcoinNetworkFailureToastDescription } from "Components/TradingDesk/helpers";
import {
  WEB_SOCKET_ERROR,
  WEB_SOCKET_NULL,
  WEB_SOCKET_OPEN,
} from "Contexts/BuyBitcoin/buyBitcoinConstants";
import { setSellBitcoinWebSocketStatus } from "Contexts/SellBitcoin/SellBitcoinActions";
import {
  SellBitcoinWebSocketProvider,
  useSellBitcoinDispatch,
  useSellBitcoinSocket,
  useSellBitcoinStore,
} from "Contexts/SellBitcoin/sellBitcoinContext";
import { SellStreamingQuoteStatus } from "Contexts/SellBitcoin/types";
import { getCurrentOrg } from "Redux/selectors/spendingSelectors";
import { usePrevious } from "Utils/hooks";

import { CancelModalWrapper } from "../components/CancelModalWrapper";
import { SaleSummaryCard } from "../components/SaleSummaryCard";
import { SaveProgressAndCloseButton } from "../components/SaveProgressAndCloseButton";
import { SellHeader } from "../components/SellHeader";
import { WebSocketFailureStep } from "./WebSocketFailureStep";

type FinalizeSaleStepProps = {
  onContinue: () => void;
  onClose: (isCancellingSale?: boolean) => void;
};
const FinalizeSaleStep = React.memo(({ onContinue, onClose }: FinalizeSaleStepProps) => {
  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg.account_type === "ira";

  const { goToNext } = useContext(WizardStepperContext);
  const { getStreamingQuote, acceptSellRequest, stopStreamingQuote } = useSellBitcoinSocket();
  const {
    sellAmount,
    bankName,
    bankLastFourDigits,
    streamingQuoteId,
    streamingQuoteStatus,
    streamingQuoteVersion,
    bitcoinPrice,
    saleAmountBTC,
    saleAmountUSD,
    amountUSDToBeSentToClient,
    feeAmountUSD,
    feeRates,
    unchainedBTCReceiveAddress,
    customerBTCChangeAddress,
    transactionFeeSatsVByte,
    transactionFeeAmountBTC,
    transactionFeeAmountUSD,
    sellBitcoinWebSocketStatus,
    selectedSource,
    receivingAccountType,
    cashBalanceUsd,
  } = useSellBitcoinStore();

  const dispatch = useSellBitcoinDispatch();

  const { enqueueSimpleToast } = useToast();

  /**
   * If the user has not received an active streaming quote yet after
   * 6 seconds then we can assume there is an issue with the
   * web socket communication, and the webSocketStatus can be set to
   * WEB_SOCKET_ERROR.
   */
  const initialStreamingQuoteLoadingTimeout = React.useCallback(() => {
    return setTimeout(() => {
      if (
        streamingQuoteStatus === SellStreamingQuoteStatus.AWAITING_INITIAL_STREAMING_QUOTE ||
        streamingQuoteStatus === SellStreamingQuoteStatus.STREAMING_QUOTE_NOT_INITIATED
      ) {
        dispatch(setSellBitcoinWebSocketStatus(WEB_SOCKET_ERROR));
      }
    }, 14000);
  }, [dispatch, streamingQuoteStatus]);

  /**
   * If the user has not yet initiated a streaming quote, send a request to the
   * server to kick off the process of receiving streaming quotes.
   */
  useEffect(() => {
    if (streamingQuoteStatus === SellStreamingQuoteStatus.STREAMING_QUOTE_NOT_INITIATED) {
      if (sellBitcoinWebSocketStatus === WEB_SOCKET_OPEN) {
        // only push a message through the web socket
        // if we know it is in a state of WEB_SOCKET_OPEN.
        getStreamingQuote(sellAmount);
      }

      const timeoutReference = initialStreamingQuoteLoadingTimeout();

      // if the component rerenders and there is an outstanding timeout
      // clear it.
      return () => {
        if (timeoutReference) {
          clearTimeout(timeoutReference);
        }
      };
    }
  }, [
    getStreamingQuote,
    streamingQuoteStatus,
    sellAmount,
    initialStreamingQuoteLoadingTimeout,
    sellBitcoinWebSocketStatus,
  ]);

  /**
   * If the server denys the accepted request, throw a toast.
   */
  useEffect(() => {
    if (
      streamingQuoteStatus === SellStreamingQuoteStatus.SERVER_DENIED_THE_ACCEPTED_STREAMING_QUOTE
    ) {
      enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
    }
  }, [streamingQuoteStatus, enqueueSimpleToast]);

  /**
   * When the server confirms that the trade has been accepted
   * navigate the user to the next step.
   */
  useEffect(() => {
    if (
      streamingQuoteStatus ===
      SellStreamingQuoteStatus.SERVER_CONFIRMED_THE_ACCEPTED_STREAMING_QUOTE
    ) {
      onContinue();

      goToNext();
    }
  }, [onContinue, goToNext, streamingQuoteStatus, stopStreamingQuote]);

  const streamingQuoteUniqueId = `${streamingQuoteId}${streamingQuoteVersion}`;
  const previousStreamingQuote: string = usePrevious(streamingQuoteId);
  const [isFirstStreamingQuote, setIsFirstStreamingQuote] = useState(false);
  const [areTermsAndConditionsAccepted, setAreTermsAndConditionsAccepted] = useState(false);

  /**
   * Set isFirstStreamingQuote to true when the user
   * receives their first streaming quote. If the user receives
   * another streaming quote, set isFirstStreamingQuote to false.
   */
  useEffect(() => {
    if (!!previousStreamingQuote) {
      setIsFirstStreamingQuote(false);
    } else {
      setIsFirstStreamingQuote(true);
    }
  }, [streamingQuoteUniqueId, previousStreamingQuote]);

  const handleAcceptedSell = () => {
    acceptSellRequest();
  };

  const hasInitialDataLoaded = bitcoinPrice && saleAmountUSD && feeAmountUSD;

  const isInLoadingState =
    streamingQuoteStatus === SellStreamingQuoteStatus.STREAMING_QUOTE_NOT_INITIATED ||
    streamingQuoteStatus === SellStreamingQuoteStatus.AWAITING_INITIAL_STREAMING_QUOTE ||
    (sellBitcoinWebSocketStatus !== WEB_SOCKET_OPEN && hasInitialDataLoaded);

  const handleClose = (isDelete = false) => {
    // only stop the streaming quote if we have already received a
    // streaming quote, a.k.a are out of the loadingState
    if (!isInLoadingState) {
      stopStreamingQuote();
    }
    onClose(isDelete);
  };

  return isInLoadingState ? (
    <CancelModalWrapper closeStepper={handleClose}>
      <CircularProgress data-testid="loading-streaming-quote-spinner" color="primary" size={53} />
    </CancelModalWrapper>
  ) : (
    <CancelModalWrapper closeStepper={handleClose}>
      <div className="mb-4 mt-8  max-h-screen w-[50vw] max-w-[700px]">
        <SellHeader
          title="Broadcast transaction"
          subTitle="Preview the values below, agree to the terms & conditions and broadcast when ready."
          titleToolTip="To broadcast means to submit a finalized transaction to the network for validation."
        />
        <SaleSummaryCard
          vaultName={selectedSource?.name}
          vaultId={selectedSource?.id}
          saleAmountBTC={saleAmountBTC}
          streamingQuoteUniqueId={streamingQuoteUniqueId}
          isFirstStreamingQuote={isFirstStreamingQuote}
          feeRates={feeRates}
          saleAmountUSD={saleAmountUSD}
          bitcoinPrice={bitcoinPrice}
          feeAmountUSD={feeAmountUSD}
          amountUSDToBeSentToClient={amountUSDToBeSentToClient}
          isSaleComplete={false}
          btcRecieveAddress={customerBTCChangeAddress}
          btcRecieveAddressUnchained={unchainedBTCReceiveAddress}
          transactionFeeAmountBTC={transactionFeeAmountBTC}
          transactionFeeAmountUSD={transactionFeeAmountUSD}
          bankAccountLastFourDigits={bankLastFourDigits}
          bankAccountName={bankName}
          transactionFeeSatsVByte={transactionFeeSatsVByte}
          receivingAccountType={receivingAccountType}
          cashBalanceUsd={cashBalanceUsd}
          isIraOrg={isIraOrg}
        />

        <LeadingCheckbox
          containerClassName="mt-4 "
          checked={areTermsAndConditionsAccepted}
          name="Terms & Conditions"
          onChange={() => setAreTermsAndConditionsAccepted(!areTermsAndConditionsAccepted)}
          label={
            <p className="ml-1 mr-3 text-xs text-gray-800">
              {isIraOrg
                ? "I consent to the transaction above and direct the IRA custodian to take action accordingly. "
                : ""}
              I agree that this transaction will be governed by
              <Link to="https://unchained.com/terms-of-service"> Unchained's Terms of Service</Link>
              .
            </p>
          }
        />

        <Button
          className="mt-7"
          fullWidth={true}
          disabled={
            !areTermsAndConditionsAccepted ||
            /* if the user already sent an accepted request, disabled the button so another request
            can not be sent by the user.
            Or if we just recieved a new streaming quote disabled the button until the new data
            is presented to the user, to allow for a grace period between transitioning streaming quotes.*/
            streamingQuoteStatus === SellStreamingQuoteStatus.CLIENT_ACCEPTED_STREAMING_QUOTE ||
            streamingQuoteStatus === SellStreamingQuoteStatus.LOADING_INCOMING_STREAMING_QUOTE
          }
          color="primary"
          onClick={handleAcceptedSell}
        >
          {/* show the loading spinner after the user has confirmed that quote but awaiting a response from the server. */}
          {streamingQuoteStatus === SellStreamingQuoteStatus.CLIENT_ACCEPTED_STREAMING_QUOTE ? (
            <CircularProgress
              data-testid="loading-streaming-quote-spinner"
              color="primary"
              size={16}
            />
          ) : (
            "Execute sale and broadcast transaction"
          )}
        </Button>
        <SaveProgressAndCloseButton onClose={handleClose} />
      </div>
    </CancelModalWrapper>
  );
});

type StreamingQuoteSellStepProps = {
  onContinue: () => void;
  onClose: (isCancellingSale?: boolean) => void;
};
export const StreamingQuoteSellStep = ({ onContinue, onClose }: StreamingQuoteSellStepProps) => {
  const { sellBitcoinWebSocketStatus } = useSellBitcoinStore();
  const dispatch = useSellBitcoinDispatch();

  const isWebSocketFailure = sellBitcoinWebSocketStatus === WEB_SOCKET_ERROR;

  const onTryAgain = React.useCallback(() => {
    dispatch(setSellBitcoinWebSocketStatus(WEB_SOCKET_NULL));
  }, [dispatch]);

  const handleOnClose = React.useCallback(
    (isCancellingSale?: boolean) => {
      onClose(isCancellingSale);
    },
    [onClose]
  );

  return isWebSocketFailure ? (
    <WebSocketFailureStep onClose={handleOnClose} onTryAgain={onTryAgain} />
  ) : (
    <SellBitcoinWebSocketProvider>
      <FinalizeSaleStep onClose={handleOnClose} onContinue={onContinue} />
    </SellBitcoinWebSocketProvider>
  );
};
