import { useIraOrg } from "Shared/api/hooks/orgs";
import { OrgItem } from "Specs/v2/components";

export type BlockerName = "loading" | "payment" | "iraConsent" | "resubmission";
export const useAccountBlocker = (account: OrgItem): BlockerName | null => {
  const blockedForRepayment = account?.state === "pending_payment_renewal";
  const resubmissionRequested = account?.state === "pending_resubmission";
  const isLiveIra = account?.accountType === "ira" && account?.state === "live";
  const iraQuery = useIraOrg(account?.id, {
    enabled: isLiveIra,
  });

  if (!account) return "loading";
  if (blockedForRepayment) return "payment";
  if (resubmissionRequested) return "resubmission";

  if (isLiveIra) {
    const org = iraQuery.data?.org;
    const plan = org?.iraPlan;
    if (iraQuery.isLoading || !org) return "loading";
    if (org && !plan) return null; // This is a weird state, but we should not block the user
    if (!plan.fortisAgreementAcceptedAt && !plan.fortisAgreementDeclinedAt) return "iraConsent";
  }

  return null;
};
