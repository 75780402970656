import { Banner } from "@unchained/component-library";
import { BannerAction } from "@unchained/component-library/types/src/stories/Components/Banner/Banner";

type TradeLimitExceededModalProps = {
  onAdjustValues: () => void;
  onCancelTrade: () => void;
};
export const TradeLimitExceededModal = ({
  onAdjustValues,
  onCancelTrade,
}: TradeLimitExceededModalProps) => {
  const buttons = [
    {
      text: "Adjust values to conform",
      onClick: onAdjustValues,
      variant: "outlined",
    },
    {
      text: "Cancel trade",
      onClick: onCancelTrade,
      variant: "text",
    },
  ] as [BannerAction, BannerAction];

  return (
    <Banner actions={buttons} title="Trade limit exceeded" type="warning" className="mt-4">
      Price changes have caused this trade request to exceed your maximum purchase amount.
    </Banner>
  );
};
