import {
  useIsBuyBitcoinFeatureEnabled,
  useTradingOnboardingCardStatus,
} from "Components/TradingDesk/hooks";
import { useOrgFeatureOn } from "Redux/selectors/useOrgFeature";
import { useAccountCurrentOrg } from "Shared/api";
import { useGetCashBalanceQuery } from "Shared/api/v2/hooks/cashBalance";

export const useCashBalanceEnabled = () => {
  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();

  const currentOrg = useAccountCurrentOrg();

  const orgUuid = currentOrg?.uuid;

  const getCashBalanceRequest = useGetCashBalanceQuery(orgUuid, {});

  const doesUserHaveAPositiveCashBalance = Number(getCashBalanceRequest.data?.cashBalance) > 0;

  const { showTradingOnboardingIncompleteCard: isTradingOnboardingInProgress } =
    useTradingOnboardingCardStatus(false, currentOrg);
  const isIraAccount = currentOrg?.account_type === "ira";

  const isCashBalanceAllowed =
    doesUserHaveAPositiveCashBalance || isTradingFeatureAvailable || isTradingOnboardingInProgress;

  const cashBalanceTitle = isIraAccount ? "IRA cash balance" : "Cash balance";

  return { isCashBalanceAllowed, cashBalanceTitle };
};
