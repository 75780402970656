import { useQuery, UseQueryOptions } from "react-query";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  setSpendingDataAction,
  setSpendingRequestStatusAction,
} from "Actions/transactionActions/spendingActions";
import { useNavigate } from "Components/Link";
import { LoanAPI, VaultAPI } from "Shared/api";
import { REQUEST_STATUS } from "Shared/api/api";
import { splitOperationType } from "Utils/operationTypes";

import { Operation } from "./OperationType";

export type UrlInfo = {
  accountId: string;
  productType: string;
  productUuid: string;
  productTypePlural: string;
  operationType: string;
  operationUuid: string;
  operationTypePlural: string;
  isUia: boolean;
};

export const getSpendingWizardUrlInfo = (pathname: string): UrlInfo => {
  let pathArray = pathname.split("/").filter(Boolean);
  let isUia = false;
  let accountId = null;
  // UIA - account in path - eg /accounts/:accountId/vaults/:vaultId/transactions/:transactionUuid
  if (pathArray[0] === "accounts") {
    accountId = pathArray[1];
    pathArray = pathArray.slice(2);
    isUia = true;
  }

  const [productTypePlural, productUuid, operationTypePlural, operationUuid] = pathArray;
  const [productType, operationType] = [productTypePlural, operationTypePlural].map(word =>
    word?.substring(0, word.length - 1)
  );

  return {
    accountId,
    productType,
    productTypePlural,
    /**
     * Note: The operationType in the URL is the "split" version, eg "transaction", not "vault_transaction".
     * Conversely, `operation.type` is the "full" version, eg "vault_transaction".
     * */
    operationType,
    operationTypePlural,
    productUuid,
    operationUuid,
    isUia,
  };
};

export const useGetAccountSpendingData = (
  options: Omit<UseQueryOptions<{ operation: Operation }>, "queryKey" | "queryFn"> = {},
  passedTransactionInfo?: {
    productType: string;
    productUuid: string;
    operationType: string;
    operationUuid: string;
    accountId?: string;
  }
) => {
  const location = useLocation();
  const urlInfo = getSpendingWizardUrlInfo(location.pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    productType: _productType,
    productUuid,
    operationType: _operationType,
    operationUuid,
    accountId,
  } = passedTransactionInfo || urlInfo;

  const isUia = !!accountId;
  const split = splitOperationType(_operationType);
  let productType;
  let operationType;

  if (split[0] && split[1]) {
    productType = split[0];
    operationType = split[1];
  } else {
    productType = _productType;
    operationType = _operationType;
  }

  const apiMethod = {
    vault_sale: VaultAPI.GetTransaction,
    vault_transaction: VaultAPI.GetTransaction,
    vault_sweep: VaultAPI.GetSweep,
    vault_settlement: VaultAPI.GetSettlement,
    loan_redemption: LoanAPI.GetRedemption,
    loan_liquidation: LoanAPI.GetLiquidation,
    loan_closing: LoanAPI.GetClosing,
    loan_sweep: LoanAPI.GetSweep,
  }[`${productType}_${operationType}`];

  if (!apiMethod) {
    dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.ERROR));
  }

  const query = useQuery({
    queryKey: ["getAccountSpendingData", productType, productUuid, operationType, operationUuid],
    queryFn: () => apiMethod(productUuid, operationUuid),
    ...options,
    enabled:
      !!productUuid &&
      !!operationUuid &&
      !!apiMethod &&
      (options.enabled === undefined ? true : options.enabled),
    onSuccess: data => {
      const operation = data.operation;
      if (operation) {
        dispatch(setSpendingDataAction(operation));
        dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.SUCCESS));
        options.onSuccess?.({ operation });
        return operation;
      } else {
        const basePath = `/${productType}s/${productUuid}`;
        navigate(isUia ? `/accounts/${accountId}${basePath}` : basePath);
        dispatch(setSpendingRequestStatusAction(REQUEST_STATUS.NOT_FOUND));
        return {};
      }
    },
  });

  return query;
};
