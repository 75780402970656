import React, { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { HelpOutline } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { IconButton, Tooltip } from "@mui/material";
import { Button } from "@unchained/component-library";
import cx from "classnames";

import {
  TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE,
  TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING,
} from "Contexts/BuyBitcoin";
import { OnboardingRequirementStatus } from "Contexts/BuyBitcoin/types";

import styles from "./TradingOnboardingItem.module.scss";

interface TradingOnboardingItemProps {
  completedStatus: boolean | OnboardingRequirementStatus;
  label: string;
  buttonText: ReactNode;
  onClick: () => void;
  incompletedItemTooltipText: string;
  awaitingCompletionToolTipText?: string;
  showAwaitingCompletetionToolTip?: boolean;
  isDisabled?: boolean;
}
/**
 * A component displaying the completed status and an action if not completed for a required trading feature step.
 * @returns {React.Component}
 */
const TradingOnboardingItem = ({
  completedStatus,
  label,
  buttonText,
  onClick,
  incompletedItemTooltipText,
  awaitingCompletionToolTipText,
  showAwaitingCompletetionToolTip = false,
  isDisabled,
}: TradingOnboardingItemProps) => {
  /**
   * Some requirements are a two part requirement process (user applies, unchained approves), this function
   * will check if a user has at least completed their part of the requirement.
   */
  const hasUserCompletedTheirPartOfTheRequirement = React.useCallback(() => {
    return (
      completedStatus === true ||
      completedStatus === TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE ||
      completedStatus === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING
    );
  }, [completedStatus]);

  const radioButton = React.useMemo(() => {
    return hasUserCompletedTheirPartOfTheRequirement() ? (
      <CheckCircleIcon
        classes={{
          root: cx(styles.completedIcon, { [styles.completedIcon_disabled]: isDisabled }),
        }}
      />
    ) : (
      <div
        className={cx(styles.emptyRadioButton, {
          [styles.emptyRadioButton_disabled]: isDisabled,
        })}
      >
        {" "}
      </div>
    );
  }, [hasUserCompletedTheirPartOfTheRequirement, isDisabled]);

  const wrapButtonInAwaitingCompletionToolTip = (ButtonComponent: ReactNode) => {
    return (
      <Tooltip
        classes={{ tooltip: cx(styles.tooltip, styles.tooltip_pending) }}
        key="awaitingCompletionToolTip"
        title={awaitingCompletionToolTipText}
        placement={"top"}
        data-testid={`awaitingCompletionToolTip-${label}`}
      >
        <IconButton classes={{ root: styles.awaitingCompletionButton }}>
          {ButtonComponent}
        </IconButton>
      </Tooltip>
    );
  };

  const displayOnboardingItemButton = () => {
    const buttonDisabled = hasUserCompletedTheirPartOfTheRequirement() || isDisabled;
    const OnboardingItemButton = (
      <span className={styles.buttonContainer}>
        <Button
          className={cx(
            styles.secondaryButtonRoot,
            buttonDisabled && {
              [styles.secondaryButtonRoot_disabled]: completedStatus === false,
              [styles.secondaryButtonRoot_pending]:
                completedStatus === TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING,
              [styles.secondaryButtonRoot_fullyCompleted]:
                completedStatus === TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE ||
                completedStatus === true,
            }
          )}
          disabled={buttonDisabled}
          onClick={onClick}
          variant="text"
        >
          {buttonText}
        </Button>
      </span>
    );
    if (showAwaitingCompletetionToolTip) {
      return wrapButtonInAwaitingCompletionToolTip(OnboardingItemButton);
    } else {
      return OnboardingItemButton;
    }
  };

  return (
    <div className={styles.container}>
      {radioButton}
      <span className={cx("break-anywhere", styles.label, { [styles.label_disabled]: isDisabled })}>
        {label}
      </span>
      {!hasUserCompletedTheirPartOfTheRequirement() ? (
        <Tooltip
          classes={{ tooltip: styles.tooltip }}
          key="infoIcon"
          title={incompletedItemTooltipText}
          placement={"top"}
          data-testid={`onboardingToolTip-${label}`}
        >
          <HelpOutline color="inherit" classes={{ root: styles.infoIcon }} />
        </Tooltip>
      ) : null}
      {displayOnboardingItemButton()}
    </div>
  );
};

export { TradingOnboardingItem };
