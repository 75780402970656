import { useState } from "react";

import { FormHelperText } from "@mui/material";
import { Button, Icons } from "@unchained/component-library";
import { useQueryClient } from "react-query";

import { Formik, FormikTextField } from "Components/FormFields";
import { OrgAPI } from "Shared/api";
import { orgQueryKeys } from "Shared/api/hooks";
import { useEasyToasts } from "Utils/toasts";

type SetEditingFunction = (editing: boolean) => void;

const EditName = ({
  uuid,
  name,
  setEditing,
}: {
  uuid: string;
  name: string;
  setEditing: SetEditingFunction;
}) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState(null);
  const { showApiSuccessToast } = useEasyToasts();

  const handleSubmit = async values => {
    setApiError(null);
    if (values.name == name) {
      setEditing(false);
    } else {
      try {
        await OrgAPI.ChangeName(uuid, values.name);
        showApiSuccessToast({ message: "Account name updated." });
        queryClient.invalidateQueries(orgQueryKeys.show(uuid));
        setEditing(false);
      } catch (e) {
        if (e?.response?.data?.errors?.name[0]) {
          setApiError(e.response.data.errors.name[0]);
        } else if (e?.response?.data?.message) {
          setApiError(e.response.data.message);
        } else {
          setApiError("There was an error");
        }
      }
    }
  };

  return (
    <Formik initialValues={{ name }} onSubmit={handleSubmit}>
      {props => (
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-4">
            <FormikTextField hideLabel type="text" name="name" />
            <Button onClick={props.handleSubmit} color="primary">
              Save
            </Button>
          </div>
          {apiError && <FormHelperText error>{apiError}</FormHelperText>}
        </div>
      )}
    </Formik>
  );
};

const OrgManageName = ({
  uuid,
  name,
  canEdit,
}: {
  uuid: string;
  name: string;
  canEdit: boolean;
}) => {
  const [editing, setEditing] = useState(false);
  return (
    <>
      <h3 className="mb-4">Account nickname</h3>
      {editing ? (
        <EditName uuid={uuid} name={name} setEditing={setEditing} />
      ) : (
        <>
          <div className="flex items-center gap-4">
            {name}
            {canEdit && (
              <Button color="secondary" onClick={() => setEditing(true)} endIcon={<Icons.Pencil />}>
                Edit
              </Button>
            )}
          </div>
        </>
      )}
    </>
  );
};

export { OrgManageName };
