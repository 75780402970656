import { ProvideAggregatedNotifications200 } from "Specs/v2";

import { BaseNotification } from "./NotificationRow";

export type V2Notification = ProvideAggregatedNotifications200["notifications"][number];

export const getV2NotificationDetails = (
  v2Notification: V2Notification
): BaseNotification | null => {
  const { targetType, targetUuid, notification } = v2Notification;
  const base = targetType === "org" ? `/accounts/${targetUuid}` : "";

  switch (notification.notificationType) {
    // TODO: Add other V2 notifications? I didn't see many others we wanted
    case "org_change_proposal_group_created_notify": {
      return {
        to: `${base}/change-proposals/${notification.objectUuid}`,
        text: `Approval requested for a change to your account's approval quorum`,
      };
    }
    default:
      return null;
  }
};
