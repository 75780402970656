import React from "react";

import { Button } from "@unchained/component-library";

import { Link } from "Components/Link";
import { TradingServiceDisclosure } from "Components/TradingDesk/components/TradingServiceDisclosure";

import { SellHeader } from "../components/SellHeader";

type TooManyVaultsStepProps = {
  onClose: () => void;
};
export const TooManyVaultsStep = ({ onClose }: TooManyVaultsStepProps) => {
  const SubTitle = () => {
    return (
      <div className="mt-2">
        IRA accounts are limited to a single active vault. To enable trading please close any
        additional vaults.{" "}
        <Link
          target="_blank"
          to={"https://help.unchained.com/is-there-a-way-to-hide-or-delete-old-vaults-or-keys"}
          className="ml-1"
        >
          Learn more
        </Link>
      </div>
    );
  };

  return (
    <div className="max-h-screen w-7/12">
      <SellHeader title={"Trading disabled"} subTitle={<SubTitle />} />
      <Button onClick={onClose} fullWidth={true} color="secondary">
        Close
      </Button>
      <TradingServiceDisclosure />
    </div>
  );
};
