import { Navigate, useLocation } from "react-router-dom";

import { Authorizers } from "../Authorize/Authorize";

/**
 * Component that redirects to the same URL, prepended with `/accounts/${orgId}`.
 * If, for some reason, the orgId is not available, it will redirect to `/accounts/all`.
 **/
export const PrependWithOrgId = ({ orgId }: { orgId: string }) => {
  const { pathname } = useLocation();
  const path = orgId ? `/accounts/${orgId}${pathname}` : `/accounts/all`;
  return <Navigate to={path} />;
};

/** Shorthand for a common pattern: Block UIA users, and send them to the equivalent path with the orgId prepended. */
export const sendToUIA = { blockUIA: true, blockUIAReplacement: PrependWithOrgId } as Authorizers;
