import { FC, memo, ReactElement } from "react";

import { Loader } from "@unchained/component-library";
import { useParams } from "react-router-dom";

import { useWorkspaceData } from "Shared/api/v2/hooks/workspaces";
import { OrgItem } from "Specs/v2/components";
import { GetUserWorkspaceData200 } from "Specs/v2/getUserWorkspaceData/200";
import { useCurrentOrgUuid } from "Utils/hooks/useCurrentOrg";

type BaseInjectedProps = GetUserWorkspaceData200 & {
  org?: OrgItem;
  accountId?: string;
  itemId?: string;
  tabName?: string;
};

type HOCOptions = {
  loader?: React.ReactNode;
};

type WorkspaceWrapperProps<DirectProps> = { props: DirectProps } & {
  Component: FC<BaseInjectedProps & DirectProps>;
  options: HOCOptions;
};

// Define the base wrapper component at the top level and memoize it
const WorkspaceWrapper = memo(function WorkspaceWrapper<DirectProps>({
  Component,
  options,
  props,
}: WorkspaceWrapperProps<DirectProps>) {
  const { accountId: accountIdInParams, tabName, itemId } = useParams();
  const currentOrgUuid = useCurrentOrgUuid();
  const workspaceQuery = useWorkspaceData();

  const opts = {
    loader: <Loader className="h-screen w-full" />,
    ...options,
  };

  /**
   * Workspace data is occasionally used in contexts without an accountId (eg in old components).
   * This fallback is applied for seamless consistency.
   **/
  const accountId = accountIdInParams || currentOrgUuid;

  if (workspaceQuery.isLoading) {
    return opts.loader as ReactElement;
  }

  const injectedProps = {
    ...workspaceQuery.data,
    org: workspaceQuery.data?.orgs?.find(o => o?.id && o.id === accountId),
    accountId,
    tabName,
    itemId,
  };

  return (<Component {...injectedProps} {...(props as DirectProps)} />) as ReactElement;
}) as <DirectProps>(props: WorkspaceWrapperProps<DirectProps>) => ReactElement;

export function withWorkspaceData<DirectProps>(
  Component: FC<BaseInjectedProps & DirectProps>,
  options: HOCOptions = {}
): FC<DirectProps> {
  // Create a stable component that just forwards props to the memoized wrapper
  return function WithWorkspaceData(props: DirectProps) {
    return <WorkspaceWrapper<DirectProps> Component={Component} options={options} props={props} />;
  };
}
