import l from "@loadable/component";
import { Loader } from "@unchained/component-library";

import { Authorizers } from "../Authorize/Authorize";
import { Route } from "./Route";

const NotFound = l(() => import("Components/errors/NotFound"), {
  fallback: <Loader className="h-screen" />,
});

export type Path = `/${string}`;

/**
 * Defines a group of routes (usually as a child at a particular path),
 * and optionally applies authorizers to all the children routes in the group.
 */
export class RouteGroup {
  _children: Record<Path, Route | RouteGroup>;
  _authorizers: Authorizers = {};

  children(children: Record<Path, Route | RouteGroup>) {
    const childrenWith404s = { ...children };

    // Add a 404 for the index route if it's not defined
    if (!children["/"]) {
      childrenWith404s["/"] = new Route({ name: "NotFound", component: NotFound });
    }

    // Add a 404 for the wildcard route if it's not defined
    if (
      !children["/*"] &&
      // A single route parameter (/:param) is basically a wildcard for the route
      // and so obviates the need for an explicit wildcard route.
      !Object.keys(children).find(k => k.startsWith("/:") && !k.slice(1).includes("/"))
    ) {
      childrenWith404s["/*"] = new Route({ name: "NotFound", component: NotFound });
    }

    this._children = childrenWith404s;

    return this;
  }

  /** Applies authorizers to all child routes, recursively */
  childAuthorizers(authorizers: Authorizers) {
    this._authorizers = { ...this._authorizers, ...authorizers };
    return this;
  }

  /**
   * An alternative way to define a child route for a routeGroup.
   * The more common way is simply to define a child path with "/":
   *
   *   "/": new Route({ name: "Index", component: () => null })
   **/
  indexRoute(route: Route) {
    this._children["/"] = route;
  }
}
