import { AnyAction } from "redux";
import { ValueOf } from "type-fest";

import {
  SET_ACCOUNT_USER_2FA_STATUS,
  SET_ACCOUNT_USER_CONFIG,
  UPDATE_ACCOUNT_USER_DATA,
  UPDATE_ACCOUNT_USER_EMAIL,
  UPDATE_ACCOUNT_USER_NAME,
  UPDATE_ACCOUNT_USER_PERSON,
  UPDATE_ACCOUNT_USER_STATUS,
} from "Actions/accountActions/userActions";
import { REQUEST_STATUS } from "Shared/api/api";
import { AccountUser } from "Specs/v1/getAccount/200";
import { CompleteOrg } from "Specs/v1/getOrg/200";

type User = AccountUser & {
  status: ValueOf<typeof REQUEST_STATUS>;
  state: string;
  non_personal_orgs: Partial<CompleteOrg>[];
};

const initialState: User = {
  username: "",
  status: REQUEST_STATUS.PENDING,
  state: "",
  phone: "",
  email: "",
  has_otp: false,
  has_passkey: false,
  accepted_org_types: [],
  non_personal_orgs: [],
  person: {},
  name: "",
  uuid: "",
};

export const userReducer = (state: User = initialState, action: AnyAction): User => {
  const { type, payload } = action;

  switch (type) {
    case SET_ACCOUNT_USER_CONFIG: {
      return {
        ...state,
        ...payload,
      };
    }

    case UPDATE_ACCOUNT_USER_STATUS: {
      return {
        ...state,
        status: payload,
      };
    }

    case UPDATE_ACCOUNT_USER_DATA: {
      return {
        ...state,
        ...payload,
      };
    }

    case UPDATE_ACCOUNT_USER_EMAIL: {
      return {
        ...state,
        email: payload,
      };
    }

    case UPDATE_ACCOUNT_USER_NAME: {
      return {
        ...state,
        name: payload,
      };
    }

    case SET_ACCOUNT_USER_2FA_STATUS: {
      return {
        ...state,
        has_otp: payload,
      };
    }

    case UPDATE_ACCOUNT_USER_PERSON: {
      return {
        ...state,
        person: {
          ...state.person,
          ...payload,
        },
      };
    }

    default:
      return state;
  }
};
