// This is the one place we import Link and useNavigate from React Router
import React, { ReactNode, Ref, useMemo } from "react";

import {
  isAbsoluteURL,
  isMailto,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import cn from "classnames";

/* eslint-disable no-restricted-imports */
import {
  Link as ReactRouterLink,
  useLocation,
  useNavigate as reactRouterUseNavigate,
  type NavigateFunction,
} from "react-router-dom";

import { AppModalManager } from "Shared/components/Modals";

// Extend basic anchor props
export type LinkProps = Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "href"> & {
  activeClassName?: string;
  matchActiveExactly?: boolean;
  preserveQueryString?: boolean;
  to?: string;
};

type Block =
  | boolean
  | { title?: string; content?: ReactNode; continueText?: string; cancelText?: string };
/**
 * Creates a dedicated app-wide context for tracking whether links should currently be blocked.
 * Associated `useLinksBlocked` hook handles defaults, so that tests don't need to be aware of this context.
 **/
const LinksBlockedContext = React.createContext<{
  linksBlocked: Block;
  setLinksBlocked: (block: Block) => void;
}>({
  linksBlocked: false,
  setLinksBlocked: () => {},
});

export const LinksBlockedProvider = ({ children }: { children: ReactNode }) => {
  const [linksBlocked, setLinksBlocked] = React.useState<Block>(false);
  const value = useMemo(() => ({ linksBlocked, setLinksBlocked }), [linksBlocked]);
  return <LinksBlockedContext.Provider value={value}>{children}</LinksBlockedContext.Provider>;
};

export const useLinksBlocked = () => {
  return (
    React.useContext(LinksBlockedContext) || {
      linksBlocked: false,
      setLinksBlocked: () => {},
    }
  );
};

export const BlockLinkModal = ({
  onClick,
  text = {},
}: {
  onClick: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
  text?: { title?: string; content?: ReactNode; continueText?: string; cancelText?: string };
}) => (
  <Modal onDismiss={AppModalManager.close}>
    <ModalHeader>
      <ModalTitle
        subtitle={<div className="!mt-4">{text.content || "Your changes will be lost."}</div>}
      >
        {text.title || "Are you sure you want to leave?"}
      </ModalTitle>
    </ModalHeader>
    <ModalFooter
      actions={[
        { children: text.continueText || "Yes, continue", onClick },
        { children: text.cancelText || "No, don't leave", onClick: AppModalManager.close },
      ]}
    />
  </Modal>
);

/**
 *
 * ### A universally functional link component
 *
 * - Can accept `onClick` functionality in addition to `to`
 * - Handles both internal and external links (including mailto)
 * - Opens external links in a new tab by default (can be overridden using `target`)
 * - Allows for activeClassName functionality
 * - Gives option to preserveQueryString when linking internally
 * - Ties into global blockLinks Redux value, which can be used to block all links on the page
 *   (except those which open in a new tab)
 *
 */
export const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(function LinkNoRef(
  props,
  ref: Ref<unknown>
) {
  const {
    className,
    target: passedTarget,
    activeClassName,
    matchActiveExactly,
    preserveQueryString,
    to,
    onClick,
    children,
    ...other
  } = props;

  const location = useLocation();
  const { linksBlocked, setLinksBlocked } = useLinksBlocked();
  const navigate = reactRouterUseNavigate();

  // Default to an empty string to indicate no navigation if no `href` was passed (onClick-only case).
  // In this case, we don't want to add the `active` class or add querystring either.
  let finalTo = "";
  let isActive = false;

  if (to) {
    const query = to.includes("?")
      ? [location.search, to.split("?")[1]].join("&")
      : location.search;

    finalTo = preserveQueryString ? `${to.split("?")[0]}${query}` : to;

    isActive = matchActiveExactly
      ? [location.pathname, location.search].filter(s => s).join("") === to
      : location.pathname.split("?")[0] === to.split("?")[0];
  }

  const isExternal = isAbsoluteURL(finalTo) || isMailto(finalTo);

  const target = passedTarget || (isExternal ? "_blank" : undefined);
  const blockThisLink = linksBlocked && target !== "_blank";

  // If we're blocking links, we want to open the modal and then navigate programmatically.
  // Goes to the finalTo destination
  const programmaticallyNavigate = () => {
    if (isExternal) {
      window.open(finalTo, target || "_blank");
    } else {
      navigate(finalTo);
    }
  };

  const fullOnClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const handleClick = () => {
      // Allow for just-onclick buttons, by blocking navigation if no `to` was passed.
      if (!to) event.preventDefault();
      if (onClick) onClick(event);
    };

    if (blockThisLink) {
      event.preventDefault();
      const modalOnClick = ((e: React.MouseEvent<HTMLAnchorElement>) => {
        onClick && onClick(e);
        setLinksBlocked(false);
        programmaticallyNavigate(); // to finalTo destination
        AppModalManager.close();
      }) as unknown as () => void;

      return AppModalManager.open(() => (
        <BlockLinkModal
          text={typeof linksBlocked === "object" ? linksBlocked : {}}
          onClick={modalOnClick}
        />
      ));
    } else {
      handleClick();
    }
  };

  const shared = {
    className: cn(className, isActive && activeClassName),
    rel: target === "_blank" ? "noreferrer" : undefined,
    target,
    ...other,
    ref: ref as Ref<HTMLAnchorElement>,
    onClick: fullOnClick,
  };

  if (isExternal) {
    return (
      <a {...shared} href={blockThisLink ? "" : finalTo}>
        {children}
      </a>
    );
  } else {
    return (
      <ReactRouterLink {...shared} to={blockThisLink ? "" : finalTo}>
        {children}
      </ReactRouterLink>
    );
  }
});

/**
 * A slightly-wrapped version of React Router's useNavigate function,
 * which handles the global blockLinks state. If blockLinks is true,
 * it will open a modal and then navigate programmatically if the user confirms.
 **/
export const useNavigate = () => {
  const navigate = reactRouterUseNavigate();
  const { linksBlocked, setLinksBlocked } = useLinksBlocked();

  return ((to: string) => {
    if (linksBlocked) {
      const modalOnClick = () => {
        setLinksBlocked(false);
        navigate(to);
        AppModalManager.close();
      };

      return AppModalManager.open(() => (
        <BlockLinkModal
          text={typeof linksBlocked === "object" ? linksBlocked : {}}
          onClick={modalOnClick}
        />
      ));
    } else {
      navigate(to);
    }
  }) as NavigateFunction;
};
