import { Button, Icons, Logo } from "@unchained/component-library";
import cn from "classnames";

import { Link } from "Components/Link";
import { useNavigationDispatch } from "Contexts/Navigation";
import { withWorkspaceData } from "Shared/components/HOCs/withWorkspaceData";
import { postOnboardingStates } from "Utils/orgState";

import { AccountLinkCard } from "./AccountLinkCard";
import BottomLinks from "./BottomLinks";
import { UIANotifications } from "./UIANotifications";

type Props = { mobile?: boolean; className?: string; id?: string };

export const NewSidebar = withWorkspaceData<Props>(
  ({ mobile, className, id, ...workspace }) => {
    const orgs = workspace.orgs;
    const { setDrawerOpen } = useNavigationDispatch();
    const closeMobileDrawer = () => setDrawerOpen(false);

    return (
      <div
        id={id}
        className={cn(
          className,
          "relative flex h-screen min-w-nav flex-col items-start justify-between border-r border-r-gray-300 bg-gray-50 py-5 transition-transform duration-700"
        )}
        data-testid={mobile ? "mobile-sidebar" : "regular-sidebar"}
      >
        <div className="flex w-full flex-col justify-start">
          <div className="relative flex w-full flex-col items-start justify-between">
            <Link className="mb-6 w-[13rem] px-3" to="/accounts/all" onClick={closeMobileDrawer}>
              <Logo />
            </Link>
            <UIANotifications />
          </div>

          {/* Account cards section */}
          <div className="flex w-full flex-col items-start gap-3" data-testid="account-links">
            {/* All Accounts card */}
            <AccountLinkCard />
            {orgs.map(
              org =>
                !(
                  org?.accountType == "individual" && !postOnboardingStates.includes(org.state)
                ) && <AccountLinkCard key={org.id} org={org} />
            )}
            <Button
              onClick={closeMobileDrawer}
              variant="text"
              to="/onboard"
              className="ml-4"
              startIcon={<Icons.Plus width={18} />}
            >
              Add account
            </Button>
          </div>
        </div>

        {/* Bottom section */}
        <div className="flex w-full flex-col gap-3">
          <BottomLinks />
          <Button
            onClick={closeMobileDrawer}
            to="/logout"
            color="custom"
            className="mx-4 border-primary-600 bg-transparent text-primary-600 hover:bg-primary-100 hover:text-primary-700"
            endIcon={<Icons.Exit />}
          >
            Sign out
          </Button>
        </div>
      </div>
    );
  },
  { loader: null }
);
