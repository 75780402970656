import { VaultSquareIcon } from "@unchained/component-library";

import { AvatarRowLeft } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/AvatarRows";
import { orgDisplayInfo } from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { BuyDestination } from "Contexts/BuyBitcoin/types";
import { CompleteOrg } from "Specs/v1/getAccount/200";

type DepositToItemProps = {
  org: CompleteOrg;
  destination: BuyDestination;
  containerClassName?: string;
};
export const DepositToItem = ({ org, destination, containerClassName }: DepositToItemProps) => {
  const { Avatar: OrgAvatar, color } = orgDisplayInfo(org);
  return (
    <div className={containerClassName}>
      <div className="mt-2 flex flex-row items-center justify-between ">
        <AvatarRowLeft
          avatar={<OrgAvatar title={org.title} className={`bg-[${color}]`} />}
          title={org.title}
          subtitle={org.subtitle}
          className="-m-2 max-w-[45%] !py-0 !pl-2"
        />
        <div className="flex max-w-[50%] items-center gap-2">
          <VaultSquareIcon className="shrink-0" ownerType={"client"} />
          <div className="ml-2">
            <p className="max-w-[125px] text-sm font-med capitalize text-gray-600">
              {destination.name || destination.type}
            </p>
            <p className="text-xs text-gray-500">ID: {destination.id}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
