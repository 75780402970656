import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";

import { NotificationsAPIV2 } from "Shared/api";
import { NotificationStateEnum } from "Specs/v1/getNotifications/200";
import { ProvideAggregatedNotifications200 } from "Specs/v2";

export const v2NotificationsQueryKeys = {
  aggregated: "v2NotificationsAggregated",
  list: "v2NotificationsList",
};

export const useV2Notifications = (orgUuid = "") => {
  return useQuery(v2NotificationsQueryKeys.list, () =>
    NotificationsAPIV2.GetV2Notifications(orgUuid)
  );
};

export const useV2AggregatedNotifications = (
  options: UseQueryOptions<ProvideAggregatedNotifications200> = {}
) =>
  useQuery(
    v2NotificationsQueryKeys.aggregated,
    () => NotificationsAPIV2.ListAggregatedNotifications(),
    options
  );

export const useSetNotificationState = (
  uuid: string,
  state: NotificationStateEnum,
  options: UseMutationOptions<unknown> = {}
) => {
  return useMutation(() => NotificationsAPIV2.PutV2NotificationState(uuid, state), options);
};
