import get from "lodash/get";
import { createSelector } from "reselect";
import { RootState } from "src/reducers";
import { SpendingOperation } from "src/reducers/transactionReducers/spendingReducer";

import { useMemoizedState } from "Redux/selectors/hooks";
import { SigRequest, TransactionRequest } from "Specs/v1/getTransactionRequests/200";
import { findChangeOutput } from "Utils/transactionUtils";

const ownerSelector = (state: RootState) => get(state, "transaction.spending.owner", null);
export const getOperation = (state: RootState): SpendingOperation =>
  get(state, "transaction.spending.operation", {});
export const getFirstTxRequest = (state: RootState) =>
  get(state, "transaction.spending.operation.btc_transaction_requests[0]", {});

export const getOperationSourceWalletUuid = createSelector(
  getOperation,
  operation => operation.source_wallet_uuid
);

/**
 * This returns information about the account, operation, and transaction request.
 */
export const spendingOperationSelector = createSelector(
  getOperation,
  getFirstTxRequest,
  (operation, txRequest: TransactionRequest) => {
    let accountType, accountUUID, accountName;
    if (Boolean(operation.vault)) {
      accountType = "vault";
      accountUUID = get(operation, "vault.uuid");
      accountName = get(operation, "vault.name");
    }
    if (Boolean(operation.loan)) {
      accountType = "loan";
      accountUUID = get(operation, "loan.uuid");
      accountName = get(operation, "loan.name");
    }
    return {
      /* @deprecated terminology use productType instead */
      accountType,
      /* @deprecated terminology use productUuid instead */
      accountUUID,
      /* @deprecated terminology use productName instead */
      accountName,
      productType: accountType,
      productUuid: accountUUID,
      productName: accountName,
      operation,
      operationUuid: operation.uuid,
      operationUUID: operation.uuid,
      operationType: operation.type,
      operationState: operation.state,
      spending: txRequest,
      txRequestUUID: txRequest.uuid,
      txRequestState: txRequest.state,
      txRequestApprovals: txRequest.approvals,
      verification: operation.verification,
      verificationRequired: operation.verification_required,
      allowedActions: operation.allowed_actions,
      description: operation.description,
      unit: "BTC",
    };
  }
);

export const spendingTransactionSelector = (state: RootState) => {
  const operation = get(state, "transaction.spending.operation", {});
  const currentBtcTxRequest = get(operation, "btc_transaction_requests[0]", {});
  const transaction = get(currentBtcTxRequest, "transaction", {});
  const transactionInputs = get(transaction, "inputs", []);
  const transactionOutputs = get(transaction, "outputs", []);
  const transactionTxid = get(transaction, "txid", "");

  return {
    transactionState: operation.state,
    transactionFee: transaction.fee,
    transactionInputs,
    transactionOutputs,
    transactionTxid,
  };
};

export const getCurrentOrgId = (state: RootState) => get(state, "account.orgs.current.uuid");

export const getCurrentOrg = (state: RootState) => get(state, "account.orgs.current");

export const getCurrentShowingOrgId = (state: RootState) => get(state, "orgs.show.org.uuid");

export const getSigRequests = (state: RootState): SigRequest[] =>
  get(state, "transaction.spending.operation.btc_transaction_requests[0].signature_requests", []);

export const getSignableSigRequests = createSelector(getSigRequests, (sigRequests: SigRequest[]) =>
  sigRequests.filter(request =>
    get(request, "account_key.allowed_actions", []).includes("create_signature")
  )
);

export const getSigRequestsForOwnedKeys = createSelector(
  getCurrentOrgId,
  getSigRequests,
  (currentOrgUUID, sigRequests: SigRequest[]) =>
    currentOrgUUID
      ? sigRequests.filter(request => get(request, "account_key.owner.uuid", "") === currentOrgUUID)
      : []
);

export const getSigRequestsForViewableKeys = createSelector(
  getSigRequests,
  (sigRequests: SigRequest[]) =>
    sigRequests.filter(request =>
      get(request, "account_key.allowed_actions", []).includes("view_name")
    )
);

export const getOrderedSigRequests = createSelector(
  getSigRequests,
  getSignableSigRequests,
  getSigRequestsForOwnedKeys,
  getSigRequestsForViewableKeys,
  (sigRequests, signableSigRequests, sigRequestsForOwnedKeys, sigRequestsForViewableKeys) => {
    let orderedSigRequestUUIDs = signableSigRequests.map(request => request.uuid);
    if (orderedSigRequestUUIDs.length < sigRequests.length) {
      sigRequestsForOwnedKeys.forEach(request => {
        if (!orderedSigRequestUUIDs.includes(request.uuid)) {
          orderedSigRequestUUIDs.push(request.uuid);
        }
      });
    }
    if (orderedSigRequestUUIDs.length < sigRequests.length) {
      sigRequestsForViewableKeys.forEach(request => {
        if (!orderedSigRequestUUIDs.includes(request.uuid)) {
          orderedSigRequestUUIDs.push(request.uuid);
        }
      });
    }
    if (orderedSigRequestUUIDs.length < sigRequests.length) {
      sigRequests.forEach(request => {
        if (!orderedSigRequestUUIDs.includes(request.uuid)) {
          orderedSigRequestUUIDs.push(request.uuid);
        }
      });
    }
    return orderedSigRequestUUIDs.map(requestUUID =>
      sigRequests.find(request => request.uuid === requestUUID)
    );
  }
);

export const getSelectedSigRequestUUID = (state: RootState) =>
  state.transaction.spending.selectedSigRequestUUID;
export const getDeviceSpecificUnsignedPSBT = (state: RootState) =>
  state.transaction.spending.deviceSpecificUnsignedPSBT;
export const getSelectedSigRequestActive = (state: RootState) =>
  state.transaction.spending.selectedSigRequestActive;

export const getSelectedSigRequest = createSelector(
  getSigRequests,
  getSelectedSigRequestUUID,
  getSelectedSigRequestActive,
  getDeviceSpecificUnsignedPSBT,
  (sigRequests, selectedSigRequestUUID, selectedSigRequestActive, deviceSpecificUnsignedPSBT) => {
    let selectedSigRequest;
    if (Boolean(selectedSigRequestUUID)) {
      selectedSigRequest = {
        ...sigRequests.find(request => request.uuid === selectedSigRequestUUID),
      };
      // FIXME This is a bit of a hack. This ensures that any
      // components using this selector to get at the `unsigned_psbt`
      // value really get the device-specific PSBT value. We need to do this
      // for now because several components are hooked in to the store to get this information.
      // when we reduce this reliance we should be able to just call and set this value directly
      selectedSigRequest.unsigned_psbt = deviceSpecificUnsignedPSBT;
    }

    return {
      selectedSigRequest,
      selectedSigRequestUUID,
      selectedSigRequestActive,
    };
  }
);

export const spendingSigRequestsSelector = createSelector(
  getSignableSigRequests,
  getSigRequestsForOwnedKeys,
  getSigRequestsForViewableKeys,
  getOrderedSigRequests,
  getSelectedSigRequest,
  (
    signableSigRequests,
    sigRequestsForOwnedKeys,
    sigRequestsForViewableKeys,
    orderedSigRequests,
    selectedSigRequest
  ) => ({
    sigRequests: orderedSigRequests,
    signableSigRequests,
    sigRequestsForOwnedKeys,
    sigRequestsForViewableKeys,
    ...selectedSigRequest,
  })
);

export const changeOutputSelector = createSelector(
  getOperation,
  spendingTransactionSelector,
  (operation, spendingTransaction) => {
    const operationProduct = operation.vault || operation.loan;
    return findChangeOutput(spendingTransaction.transactionOutputs, operationProduct);
  }
);

/**
 * This hook specifically gets the selected sig request and nothing else.
 * The existing spendingSigRequestsSelector was not updating the component on data alteration.
 * */
export const useSelectedSigRequest = () => {
  const selectedSigRequestId = useMemoizedState(`transaction.spending.selectedSigRequestUUID`);
  const txRequests = useMemoizedState("transaction.spending.operation.btc_transaction_requests");

  const sigRequests = txRequests
    .map(req => req.signature_requests)
    .reduce((flatArray, array) => [...flatArray, ...array], []);

  return sigRequests.find(req => req.uuid === selectedSigRequestId);
};
