import { ReactNode } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { ArrowBack } from "@mui/icons-material";
import { Button } from "@unchained/component-library";
import cn from "classnames";

import { ErrorBoundary } from "Components/ErrorBoundaries/ErrorBoundary";
import { PageTitle } from "Components/Layout/Header/Titles/PageTitle";

type Props = {
  children: ReactNode;
  className?: string;
  title?: ReactNode;
  /** If true, max-width will be more narrow (768px), causing content to wrap earlier */
  narrow?: boolean;
  backLink?: { pathname: string; label: string };
};

/**
 * A typical page layout with (optional) title
 * - "Content well" is horizontally centered
 * - 3rem horizontal and 4rem top margins on large screens
 * - Margins collapse on mobile (to 1.5rem)
 * - Min-width of 768px (until below md)
 * - Max-width of 1400px
 * - Optional max-width of 768px (if `narrow` is passed)
 * */
export const _BasicPage = ({ children, className, title, narrow, backLink }: Props) => {
  return (
    <div className="relative flex w-full flex-col items-center p-6 pt-16 max-md:mt-12 md:px-12">
      <div
        className={cn(
          "flex h-full w-full flex-col overflow-auto lg:min-w-3xl",
          narrow ? "max-w-3xl" : "max-w-8xl",
          className
        )}
      >
        {backLink && (
          <Button
            to={backLink.pathname}
            className="mb-2 justify-start"
            variant="text"
            startIcon={<ArrowBack />}
          >
            {backLink.label}
          </Button>
        )}
        {title && <PageTitle className="mb-6">{title}</PageTitle>}
        {children}
      </div>
    </div>
  );
};

export const BasicPage = (props: Props) => (
  <ErrorBoundary classes={{ wrapper: "!h-screen" }} size="lg" boundaryName="BasicPage">
    <_BasicPage {...props} />
  </ErrorBoundary>
);
