/* global trackEvent ga */
import {
  ApproveBankAccount200,
  CreateBankAccount200,
  CreateBankAccountRequest,
  CreateNonPlaidBankAccount200,
  CreateNonPlaidBankAccountRequest,
  DenyBankAccount200,
  DenyBankAccountRequest,
  GetAchAgreement200,
  GetBankAccount200,
  GetOrgBankAccounts200,
  GetOrgBankAccountsAdmin200,
  GetPlaidLinkToken200,
  GetPlaidLinkTokenMicroDeposits200,
  GetPlaidLinkUpdateToken200,
  PatchPlaidLinkStatus200,
  VerifyMircoDepositBankAccount200,
} from "Specs/v1";
import { BankAccount } from "Specs/v1/getAccount/200";

import { API } from "./api";

export class BankAccountAPI {
  static CreateBankAccount = async (
    orgUuid: string,
    reqData: { account_id: string; public_token: string }
  ) => {
    const {
      data: { bank_account },
    } = await API.Post<CreateBankAccount200>(`/bank_accounts?org-uuid=${orgUuid}`, {
      plaidAccountId: reqData?.account_id,
      plaidPublicToken: reqData?.public_token,
    } as CreateBankAccountRequest);

    return bank_account;
  };

  static CreateNonPlaidBankAccount = async (
    orgUuid: string,
    reqData: CreateNonPlaidBankAccountRequest
  ) => {
    const {
      data: { bank_account },
    } = await API.Post<CreateNonPlaidBankAccount200>(
      `/orgs/${orgUuid}/bank_accounts/non-plaid`,
      reqData
    );

    return bank_account;
  };

  static CreateAccountHolder = async (orgUuid: string) => {
    const {
      data: { account_holder },
    }: { data: { account_holder: { orgUuid: string; userUuid: string } } } = await API.Put(
      `/orgs/${orgUuid}/account-holder`
    );

    return Boolean(account_holder);
  };

  static ApproveBankAccount = async (uuid: string) => {
    try {
      const {
        data: { bank_account, message },
      } = await API.Patch<ApproveBankAccount200>(`/bank_accounts/${uuid}/approve`);
      return { bank_account, message };
    } catch (e) {
      throw e.response.data.message;
    }
  };

  static DenyBankAccount = async (uuid: string, reason: string) => {
    try {
      const data: DenyBankAccountRequest = { reason: reason };
      const {
        data: { bank_account, message },
      } = await API.Patch<DenyBankAccount200>(`/bank_accounts/${uuid}/deny`, data);

      return { bank_account, message };
    } catch (e) {
      throw e.response.data.message;
    }
  };

  static GetOrgBankAccounts = async (orgUuid: string) => {
    const {
      data: { bank_accounts },
    } = await API.Get<GetOrgBankAccounts200>(`/orgs/${orgUuid}/bank_accounts`);

    return bank_accounts;
  };

  static GetOrgBankAccountsWithLegacy = async (orgUuid: string) => {
    const {
      data: { bank_accounts },
    } = await API.Get<GetOrgBankAccounts200>(
      `/orgs/${orgUuid}/bank_accounts?include-legacy-accounts=true`
    );

    return bank_accounts;
  };

  static GetOrgBankAccountsWithValidNonPlaid = async (orgUuid: string) => {
    const {
      data: { bank_accounts },
    } = await API.Get<GetOrgBankAccounts200>(
      `/orgs/${orgUuid}/bank_accounts?include-valid-non-plaid-accounts=true`
    );

    return bank_accounts;
  };

  static GetAllOrgBankAccounts = async (orgUuid: string) => {
    const {
      data: { bank_accounts },
    } = await API.Get<GetOrgBankAccounts200>(
      `/orgs/${orgUuid}/bank_accounts?include-legacy-accounts=true&include-valid-non-plaid-accounts=true`
    );

    return bank_accounts;
  };

  static GetOrgBankAccountsAdmin = async (orgUuid: string) => {
    const {
      data: { bank_accounts },
    } = await API.Get<GetOrgBankAccountsAdmin200>(
      `/admin/orgs/${orgUuid}/bank_accounts?include-legacy-accounts=true&include-valid-non-plaid-accounts=true&include-deleted-accounts=true`
    );

    return bank_accounts;
  };

  /**
   * @description Gets bank account with unmasked account and routing numbers.
   * This is an admin only route.
   * @param {string} accountUuid - bank account uuid
   */
  static GetBankAccountFullNumbersAdmin = async (accountUuid: string) => {
    const {
      data: { bank_account },
    } = await API.Get<GetBankAccount200>(
      `/bank_accounts/${accountUuid}?include-account-numbers=true`
    );

    return bank_account;
  };

  /**
   * @description Gets bank account with unmasked account and routing numbers.
   * This is a client facing route that requires the user to have update permissions
   * on the org.
   * @param {string} accountUuid - bank account uuid
   */
  static GetBankAccountFullNumbersClient = async ({
    orgUuid,
    bankAccountUuid,
  }: {
    orgUuid: string;
    bankAccountUuid: string;
  }) => {
    // TODO: This is using the GetBankAccount200, but should have it's
    // own spec. It wil be nearly identical to GetBankAccount200, so
    // using it for now.
    const {
      data: { bank_account },
    } = await API.Get<GetBankAccount200>(
      `/orgs/${orgUuid}/bank_account/${bankAccountUuid}?include-account-numbers=true`
    );

    return bank_account;
  };

  /**
   * @description Gets a list of owners for a bank account.
   * This is an admin only route.
   * @param {string} accountUuid - bank account uuid
   */
  static GetBankAccountOwnerNames = async (accountUuid: string) => {
    const {
      data: { bank_account },
    } = await API.Get<GetBankAccount200>(`/bank_accounts/${accountUuid}?include-owner-names=true`);

    return bank_account?.owner_names;
  };

  // Plaid related routes

  static GetPlaidLinkToken = async (orgUuid: string) => {
    const {
      data: { plaidLinkToken },
    } = await API.Get<GetPlaidLinkToken200>(`/orgs/${orgUuid}/plaid-link-token`);
    return plaidLinkToken;
  };

  static GetPlaidLinkUpdateToken = async (bankAccountUuid: string) => {
    const {
      data: { plaidLinkToken },
    } = await API.Get<GetPlaidLinkUpdateToken200>(
      `/bank_accounts/${bankAccountUuid}/plaid-link-update-token`
    );
    return plaidLinkToken;
  };

  static NotifyPlaidLinkUpdateSuccess = async (bankAccountUuid: string) => {
    const {
      data: { status },
    } = await API.Patch<PatchPlaidLinkStatus200>(
      `/bank_accounts/${bankAccountUuid}/plaid-link-update-success`
    );
    return status === "success";
  };

  // Micro-deposit routes

  static GetPlaidLinkTokenMicrodeposit = async (bankAccountUuid: string) => {
    const {
      data: { plaidLinkToken },
    } = await API.Get<GetPlaidLinkTokenMicroDeposits200>(
      `/bank_accounts/${bankAccountUuid}/micro-deposits-plaid-link-token`
    );
    return plaidLinkToken;
  };

  static VerifyMicrodeposit = async (bankAccountUuid: string) => {
    const {
      data: { bank_account },
    } = await API.Patch<VerifyMircoDepositBankAccount200>(
      `/bank_accounts/${bankAccountUuid}/verify-plaid-micro-deposits`
    );

    return bank_account;
  };

  // Loan bank account routes

  static GetLoanInterestAccount = async (loanUuid: string) => {
    const response = await API.Get(`/loans/${loanUuid}/interest-payment-account`);

    return response.data as BankAccount;
  };

  static GetLoanDisbursementAccount = async (loanUuid: string) => {
    const response = await API.Get(`/loans/${loanUuid}/disbursement-account`);

    return response.data as BankAccount;
  };

  static SetLoanInterestAccount = async (loanUuid: string, accountId: string, autoAch: boolean) => {
    try {
      await API.Put(`/loans/${loanUuid}/interest-payment-account`, {
        accountId,
        autoAch,
      });
    } catch (e) {
      throw new Error(e);
    }
  };

  static SetLoanDisbursementAccount = async (loanUuid: string, accountId: string) => {
    await API.Put(`/loans/${loanUuid}/disbursement-account`, {
      accountId,
    });
  };

  // Gets latest ACH agreement text

  static GetACHTermsOfService = async () => {
    const { data: agreement } = await API.Get<GetAchAgreement200>(`/bank_accounts/ach-agreement`);

    return agreement;
  };
}
