import { AccountKeySharingResponse, KeyListResponse } from "Specs/v2/components";

import { ApiType } from "../apiType";

export const KeysAPIV2Factory = (API: ApiType) => ({
  GetKeys: async () => (await API.Get<KeyListResponse>(`/keys`)).data,

  GetKeyWorkspaceSharing: async (keyId: string) =>
    (await API.Get<any>(`/account-keys/${keyId}/workspace-sharing`)).data,

  GetKeySharing: async (keyId: string) =>
    (await API.Get<AccountKeySharingResponse>(`/account-keys/${keyId}/sharing`)).data,

  UpdateKeyWorkspaceSharing: async (
    workspaceId: string,
    orgId: string,
    keyId: string,
    shared: boolean
  ) =>
    (
      await API.Put(`/workspaces/${workspaceId}/org-members/${orgId}/key-shares/${keyId}`, {
        sharedForUseByWorkspace: shared,
      })
    ).data,
});
