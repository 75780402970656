import { useEffect } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { CloudDownload } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Button, useToast } from "@unchained/component-library";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

import { sellBitcoinNetworkFailureToastDescription } from "Components/TradingDesk/helpers";
import { useSellBitcoinStore } from "Contexts/SellBitcoin/sellBitcoinContext";
import { getCurrentOrg, getCurrentOrgId } from "Redux/selectors/spendingSelectors";
import { useDownloadSellTradeStatementDocument } from "Shared/api/hooks/trading";

import { SaleSummaryCard } from "../components/SaleSummaryCard";
import { SellHeader } from "../components/SellHeader";

type ConfirmationStepProps = {
  onClose: () => void;
};
export const ConfirmationStep = ({ onClose }: ConfirmationStepProps) => {
  const {
    bankName,
    bankLastFourDigits,
    bitcoinPrice,
    saleAmountBTC,
    saleAmountUSD,
    amountUSDToBeSentToClient,
    feeAmountUSD,
    feeRates,
    unchainedBTCReceiveAddress,
    customerBTCChangeAddress,
    transactionFeeAmountUSD,
    transactionFeeAmountBTC,
    transactionFeeSatsVByte,
    currentSaleUuid,
    selectedSource,
    cashBalanceUsd,
    receivingAccountType,
  } = useSellBitcoinStore();

  const { enqueueSimpleToast } = useToast();
  const orgUuid: string = useSelector(getCurrentOrgId);
  const currentOrg = useSelector(getCurrentOrg);
  const isIraOrg = currentOrg.account_type === "ira";

  const queryClient = useQueryClient();

  useEffect(() => {
    // slightly delay the invalidation of the cash balance query to ensure the trade has been processed.
    setTimeout(() => {
      queryClient.invalidateQueries("getCashBalance");
    }, 1000);
  }, [queryClient]);

  const handleDownloadError = () => {
    enqueueSimpleToast(sellBitcoinNetworkFailureToastDescription);
  };

  const { mutate: downloadSellTradeStatement, isLoading: isDownloadSellTradeStatementLoading } =
    useDownloadSellTradeStatementDocument(currentSaleUuid, orgUuid, handleDownloadError);

  const downloadStatement = () => {
    downloadSellTradeStatement();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <div className="mb-4 mt-8 max-h-screen w-[50vw] max-w-[700px]">
      <SellHeader
        title="Summary"
        subTitle="Your sale is being processed. We will deposit the USD proceeds to your requested account upon completion."
      />
      <SaleSummaryCard
        vaultName={selectedSource?.name}
        vaultId={selectedSource?.id}
        saleAmountBTC={saleAmountBTC}
        feeRates={feeRates}
        saleAmountUSD={saleAmountUSD}
        bitcoinPrice={bitcoinPrice}
        feeAmountUSD={feeAmountUSD}
        amountUSDToBeSentToClient={amountUSDToBeSentToClient}
        isSaleComplete={true}
        isFirstStreamingQuote={true}
        btcRecieveAddress={customerBTCChangeAddress}
        btcRecieveAddressUnchained={unchainedBTCReceiveAddress}
        transactionFeeAmountBTC={transactionFeeAmountBTC}
        transactionFeeAmountUSD={transactionFeeAmountUSD}
        bankAccountLastFourDigits={bankLastFourDigits}
        bankAccountName={bankName}
        transactionFeeSatsVByte={transactionFeeSatsVByte}
        cashBalanceUsd={cashBalanceUsd}
        receivingAccountType={receivingAccountType}
        isIraOrg={isIraOrg}
      />
      <Button className="mb-3 mt-8" fullWidth={true} color="primary" onClick={downloadStatement}>
        {isDownloadSellTradeStatementLoading ? (
          <CircularProgress data-testid="button-loading-spinner" color="inherit" size={20} />
        ) : (
          <>
            <CloudDownload classes={{ root: "mr-3 text-lg" }} />
            Download statement
          </>
        )}
      </Button>
      <Button className="mb-4" fullWidth={true} variant="text" onClick={handleClose}>
        Close
      </Button>
    </div>
  );
};
