import { CompleteOrg } from "Specs/v1/getAccount/200";
import { OrgItem } from "Specs/v2/components";
import { personalOrgBasicInfoCompleteStates, postOnboardingStates } from "Utils/orgState";

type Org =
  | Pick<CompleteOrg, "type" | "account_type" | "state">
  | Pick<OrgItem, "type" | "accountType" | "state">;

type NormalizedOrg = {
  type: OrgItem["type"];
  accountType: OrgItem["accountType"];
  state: OrgItem["state"];
};

const normalize = (org: Org): NormalizedOrg => {
  return {
    type: org.type,
    accountType: (org as CompleteOrg).account_type || (org as OrgItem).accountType,
    state: org.state,
  };
};

const isPostOnboarding = (org: NormalizedOrg) => {
  if (org.accountType === "individual")
    return personalOrgBasicInfoCompleteStates.includes(org.state);
  return postOnboardingStates.includes(org.state);
};

/**
 * Determines if an org can be accessed by the user, given the context of all orgs
 * in the account/workspace.
 *
 * A final option can be passed determining whether to consider pre-onboarding orgs "accessible."
 *
 * @param org - The org to check accessibility for
 * @param allOrgs - All orgs in the account
 * @param allowOnboarding - Whether to allow access to orgs that are in the onboarding state
 * @returns Whether the org can be accessed
 */
export const isOrgAccessible = (org: Org, allOrgs: Org[], allowOnboarding = false) => {
  const o = normalize(org);

  const os = allOrgs.map(normalize);
  const isAdmin = os.some(o => o.type === "unchained");

  // No denied org should be accessible
  if (o.state === "denied") return false;
  // An admin should be able to visit any non-individual org in their account
  // (which is to say their Unchained org)
  if (isAdmin) return o.accountType !== "individual";

  // Any arbiter/delegate orgs that are post-onboarding should be accessible
  // Even if they're bizzes and the user doesn't have basic info complete.
  // In cases where we display onboarding orgs, those are good too.
  if (["arbiter", "delegate"].includes(o.type) && (isPostOnboarding(o) || allowOnboarding))
    return true;

  // OK, this is a client org.
  // Accessible if it's post-onboarding or if we're allowing onboarding orgs.
  return isPostOnboarding(o) || allowOnboarding;
};
