import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";

export default function AcknowledgeQuorumEnablementModal({ onClose }) {
  return (
    <Modal>
      <ModalHeader>
        <ModalTitle>Quorum notification</ModalTitle>
      </ModalHeader>
      <ModalContent>
        <p>
          Adding additional users with approval permissions to this account will enable quorums by
          default. This means that transactions where the Unchained key or an institutional key
          agent is required, and other account changes, will require approval by your designated
          quorum.
        </p>
      </ModalContent>
      <ModalFooter>
        <Button color="primary" onClick={() => onClose(true)}>
          Proceed
        </Button>
        <Button variant="text" onClick={() => onClose(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}
