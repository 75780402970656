import React from "react";

import { Button } from "@unchained/component-library";

type SaveSellProgressAndCloseProps = {
  onClose: () => void;
};
export const SaveProgressAndCloseButton = ({ onClose }: SaveSellProgressAndCloseProps) => {
  return (
    <Button fullWidth={true} variant="text" className="mb-4 mt-3" onClick={onClose}>
      Save progress and close
    </Button>
  );
};
