import { useMutation, UseMutationOptions, useQuery, UseQueryOptions } from "react-query";

import { GetUserWorkspaceData200, UpdateWorkspaceOrgMemberSettingsRequest } from "Specs/v2";

import { WorkspacesAPI } from "..";

export const workspaceQueryKeys = {
  get: ["workspace"],
  getKeys: ["workspace", "keys"],
};

type GetWorkspaceOptions = UseQueryOptions<GetUserWorkspaceData200> & {
  blockOnError?: boolean;
};

/** Requests the workspace for the current user. */
export const useWorkspaceData = (options: GetWorkspaceOptions = {}) =>
  useQuery<GetUserWorkspaceData200>(
    workspaceQueryKeys.get,
    () => WorkspacesAPI.GetUserWorkspaceData(options.blockOnError),
    options
  );

type UpdateOrgWorkspaceMemberSettingsVars = {
  orgId: string;
} & UpdateWorkspaceOrgMemberSettingsRequest;

export const useUpdateWorkspaceMemberSettings = (
  workspaceId: string,
  options: UseMutationOptions<
    unknown,
    unknown,
    { settingsArray: UpdateOrgWorkspaceMemberSettingsVars[] }
  > = {}
) =>
  useMutation<unknown, unknown, { settingsArray: UpdateOrgWorkspaceMemberSettingsVars[] }>(
    ({ settingsArray }) =>
      Promise.all(
        settingsArray.map(({ orgId, ...settings }) =>
          WorkspacesAPI.UpdateOrgWorkspaceMemberSettings(workspaceId, orgId, settings)
        )
      ),
    options
  );
