import { Fragment } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { Business as OrgIcon, Person as UserIcon } from "@mui/icons-material";
import cn from "classnames";

import {
  orgDisplayInfo,
  orgIcon as orgIconHelper,
} from "Components/Layout/Nav/Sidebar/OldSidebar/AccountSwitcher/accountHelpers";
import { Link } from "Components/Link";
import { KeyIcon } from "Components/Shared/Icons/KeyIcon";
import { LoanIcon } from "Components/Shared/Icons/LoanIcon";
import { VaultIcon } from "Components/Shared/Icons/VaultIcon";
import { CompleteOrgAccountTypeEnum } from "Specs/v1/getAccount/200";
import { btcOperationTypes } from "Utils/enums";
import { orgAdminPath } from "Utils/paths";
import { pluralize } from "Utils/strings";

import { LoanLabel, OperationLabel, UserLabel, VaultLabel } from "./Labels";

//
// URLs
//

export const userURL = user => {
  if (user && Boolean(user.uuid)) {
    return `/admin/users/${user.uuid}`;
  } else {
    return null;
  }
};

export const orgURL = org => {
  if (org && Boolean(org.uuid)) {
    return `/orgs/${org.uuid}`;
  } else {
    return null;
  }
};

export const orgOrUserAdminURL = org => {
  if (org && org.personal && Boolean(org.owner) && Boolean(org.owner.uuid)) {
    return `/users/${org.owner.uuid}`;
  } else if (org && !org.personal && Boolean(org.uuid)) {
    return orgAdminPath(org.uuid);
  }
  return null;
};

export const accountKeyURL = account_key => {
  if (account_key && Boolean(account_key.uuid)) {
    return `/account_keys/${account_key.uuid}`;
  } else {
    return null;
  }
};

export const vaultURL = vault => {
  if (vault && Boolean(vault.uuid)) {
    return `/vaults/${vault.uuid}`;
  } else {
    return null;
  }
};

export const productURL = product => {
  if (product && Boolean(product.uuid)) {
    return `/${pluralize(product.product_type)}/${product.uuid}`;
  } else {
    return null;
  }
};

export const vaultAdminURL = vault => {
  if (vault && Boolean(vault.uuid)) {
    return vaultAdminUuidURL(vault.uuid);
  } else {
    return null;
  }
};

export const vaultAdminUuidURL = vaultUuid => {
  return `/vaults/${vaultUuid}/admin`;
};

export const vaultArbiterURL = vault => {
  if (vault && Boolean(vault.uuid)) {
    return `/arbiter/vaults/${vault.uuid}`;
  } else {
    return null;
  }
};

export const loanURL = loan => {
  if (loan && Boolean(loan.uuid)) {
    return `/loans/${loan.uuid}`;
  } else {
    return null;
  }
};

export const loanAdminURL = loan => {
  if (loan && Boolean(loan.uuid)) {
    return `/loans/${loan.uuid}/admin`;
  } else {
    return null;
  }
};

export const loanAdminUuidURL = loanUuid => `/loans/${loanUuid}/admin`;

export const loanArbiterURL = loan => {
  if (loan && Boolean(loan.uuid)) {
    return `/arbiter/loans/${loan.uuid}`;
  } else {
    return null;
  }
};

export const operationURL = operation => {
  if (operation && Boolean(operation.type)) {
    if (
      [
        btcOperationTypes.vault_transaction,
        btcOperationTypes.vault_sale_transaction,
        btcOperationTypes.vault_sweep,
        btcOperationTypes.batch_settlement,
      ].includes(operation.type)
    ) {
      const vault = operation.product || operation.vault;
      const vaultBaseURL = vaultURL(vault);
      if (Boolean(vaultBaseURL) && Boolean(operation.uuid)) {
        // Vault transactions
        if (operation.type === "vault_transaction") {
          return vaultBaseURL + `/transactions/${operation.uuid}`;
        }

        if (operation.type === "vault_sale_transaction") {
          return vaultBaseURL + `/sales/${operation.uuid}`;
        }

        // Vault sweeps
        if (operation.type === "vault_sweep") {
          return vaultBaseURL + `/sweeps/${operation.uuid}`;
        }

        // Vault batch settlement
        if (operation.type === "batch_settlement") {
          return vaultBaseURL + `/settlements/${operation.uuid}`;
        }
      }
    }

    // Loan operations
    if (
      ["loan_redemption", "loan_liquidation", "loan_closing", "loan_sweep"].includes(operation.type)
    ) {
      const loan = operation.product || operation.loan;
      const loanBaseURL = loanURL(loan);
      if (Boolean(loanBaseURL) && Boolean(operation.uuid)) {
        // Loan redemptions
        if (operation.type === "loan_redemption") {
          return loanBaseURL + `/redemptions/${operation.uuid}`;
        }

        // Loan liquidations
        if (operation.type === "loan_liquidation") {
          return loanBaseURL + `/liquidations/${operation.uuid}`;
        }

        // Loan closings
        if (operation.type === "loan_closing") {
          return loanBaseURL + `/closings/${operation.uuid}`;
        }

        // Loan sweeps
        if (operation.type === "loan_sweep") {
          return loanBaseURL + `/sweeps/${operation.uuid}`;
        }
      }
    }

    // Rekeys
    if (operation.type === "rekey" && Boolean(operation.old_account_key_uuid)) {
      return `/account_keys/${operation.old_account_key_uuid}/rekey`;
    }

    // Other operations don't have dedicated pages for now....
    return null;
  } else {
    // We can't tell what type the operation is
    return null;
  }
};

//
// Links
//

const BaseLink = ({ text, url, newTab = false, Icon = undefined }) => {
  const linkProps = newTab ? { target: "_blank" } : {};
  return (
    <Fragment>
      {Icon && (
        <span>
          <Icon /> &nbsp;{" "}
        </span>
      )}
      {Boolean(url) ? (
        <Link to={url} {...linkProps}>
          {text}
        </Link>
      ) : (
        text
      )}
    </Fragment>
  );
};

export const UserLink = ({ user, newTab = false, icon }) => {
  const Icon = icon ? UserIcon : null;
  return (
    <BaseLink url={userURL(user)} text={<UserLabel user={user} />} Icon={Icon} newTab={newTab} />
  );
};

export const orgIcon = ({ account_type }: { account_type: CompleteOrgAccountTypeEnum }) =>
  orgIconHelper(account_type) || OrgIcon;

const BaseOrgLink = ({ org, url, newTab, icon }) => {
  const { Avatar: OrgAvatar, title } = orgDisplayInfo(org);

  return (
    <Link to={url} className="flex items-center gap-2" target={newTab ? "_blank" : undefined}>
      {icon ? <OrgAvatar /> : null}
      {title}
    </Link>
  );
};

export const OrgAdminUuidLink = ({ orgUuid, text, newTab }) => {
  return <BaseLink url={orgAdminPath(orgUuid)} text={text} newTab={newTab} />;
};

export const OrgLink = ({ org, newTab, icon }) => {
  return <BaseOrgLink org={org} url={orgURL(org)} newTab={newTab} icon={icon} />;
};

export const OrgAdminLink = ({ org, newTab = false, icon = false }) => {
  return <BaseOrgLink org={org} url={orgAdminPath(org)} newTab={newTab} icon={icon} />;
};

export const OrgOrUserAdminLink = ({ org, newTab = false, icon = false }) => {
  return <BaseOrgLink org={org} url={orgOrUserAdminURL(org)} newTab={newTab} icon={icon} />;
};

export const OrgOrUserUuidLink = ({ userUuid, text, newTab }) => {
  return <BaseLink url={`/users/${userUuid}`} text={text} newTab={newTab} />;
};

export const AccountKeyLink = ({
  account_key,
  newTab = false,
  icon = false,
  abbreviate = false,
}) => {
  const Icon = icon ? KeyIcon : null;
  let label = "*No key name*";

  if (abbreviate && account_key && account_key.name && account_key.name.includes("Unchained")) {
    label = account_key.name.replace("Unchained", "UC");
  } else if (account_key && account_key.name) {
    label = account_key.name;
  }

  return <BaseLink url={accountKeyURL(account_key)} text={label} Icon={Icon} newTab={newTab} />;
};

const VaultBaseLink = ({ vault, url, newTab, icon }) => {
  const Icon = icon ? VaultIcon : null;
  return <BaseLink url={url} text={<VaultLabel vault={vault} />} Icon={Icon} newTab={newTab} />;
};

export const VaultAdminLink = ({ vault, newTab, icon }) => {
  return <VaultBaseLink vault={vault} url={vaultAdminURL(vault)} newTab={newTab} icon={icon} />;
};

export const VaultAdminUuidLink = ({ vaultUuid, newTab, prefix = "" }) => {
  const linkText = prefix ? `${prefix}: ${vaultUuid}` : vaultUuid;
  return <BaseLink url={vaultAdminUuidURL(vaultUuid)} newTab={newTab} text={linkText} />;
};

const LoanBaseLink = ({ loan, url, newTab, icon }) => {
  const Icon = icon ? LoanIcon : null;
  return <BaseLink url={url} text={<LoanLabel loan={loan} />} Icon={Icon} newTab={newTab} />;
};

export const LoanLink = ({ loan, newTab, icon }) => {
  return <LoanBaseLink loan={loan} url={loanURL(loan)} newTab={newTab} icon={icon} />;
};

export const LoanAdminLink = ({ loan, newTab, icon }) => {
  return <LoanBaseLink loan={loan} url={loanAdminURL(loan)} newTab={newTab} icon={icon} />;
};

export const LoanAdminUuidLink = ({ loanUuid, newTab, prefix = "" }) => {
  const linkText = prefix ? `${prefix}: ${loanUuid}` : loanUuid;
  return <BaseLink url={loanAdminUuidURL(loanUuid)} newTab={newTab} text={linkText} />;
};

export const LoanArbiterLink = ({ loan, newTab, icon }) => {
  return <LoanBaseLink loan={loan} url={loanArbiterURL(loan)} newTab={newTab} icon={icon} />;
};

export const OperationLink = ({ operation, newTab = false, icon = false }) => {
  return (
    <BaseLink
      url={operationURL(operation)}
      text={<OperationLabel operation={operation} />}
      Icon={icon}
      newTab={newTab}
    />
  );
};

export const SupportEmailLink = ({ text, className }: { text?: string; className?: string }) => {
  const anchorText = text || "support@unchained.com";

  return (
    <Link to="mailto:support@unchained.com" className={cn(className)}>
      {anchorText}
    </Link>
  );
};
