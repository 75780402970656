import React, { useState } from "react";

import { Grid } from "@mui/material";
import { Button } from "@unchained/component-library";
import PropTypes from "prop-types";
import QRCode from "qrcode.react";

import { useIncrementIndexTimer } from "Utils/hooks";

export const HermitQRCode = ({
  width = 640, // px
  index,
  parts,
  onCancel,
  onNext,
  nextText = "Scan",
  togglePlay,
}) => {
  return (
    <div style={{ padding: "3rem" }}>
      <h6>
        QR Code{" "}
        <span data-testid="quorum">
          {index + 1} of {parts.length}
        </span>
      </h6>
      <a data-testid="qr" href="#" onClick={togglePlay}>
        <QRCode size={width} value={parts[index]} level="L" />
      </a>

      <p>Scan the QR codes above into Hermit.</p>
      {onNext && <p>When you are done, hit the "{nextText}" button to continue.</p>}
      <Grid container spacing={2}>
        <Grid item xs>
          <Button color="destructive" onClick={onCancel}>
            Cancel
          </Button>
        </Grid>
        {onNext && (
          <Grid item xs>
            <Button onClick={onNext}>{nextText}</Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

HermitQRCode.propTypes = {
  width: PropTypes.number,
  index: PropTypes.number.isRequired,
  parts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  nextText: PropTypes.string,
  togglePlay: PropTypes.func.isRequired,
};

export const HermitQRCodeDisplayer = ({
  width = 640, // px
  rate = 200, // ms per QR code displayed
  parts,
  onCancel,
  onNext,
  nextText = "Scan",
}) => {
  const [paused, setPaused] = useState(false);

  const { activeIndex, clearTimer, setTimer } = useIncrementIndexTimer(parts.length, rate);
  const togglePlay = event => {
    event.preventDefault();
    paused ? setTimer() : clearTimer();
    setPaused(!paused);
  };

  return (
    <HermitQRCode
      width={width}
      index={activeIndex}
      parts={parts}
      onCancel={onCancel}
      onNext={onNext}
      nextText={nextText}
      togglePlay={togglePlay}
    />
  );
};

HermitQRCodeDisplayer.propTypes = {
  width: PropTypes.number,
  rate: PropTypes.number,
  parts: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onCancel: PropTypes.func.isRequired,
  onNext: PropTypes.func,
  nextText: PropTypes.string.isRequired,
};
