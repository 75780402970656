import { WithdrawalAdminTypeEnum } from "./types";

export const isValidWithdrawalAdminType = (
  validTypes: WithdrawalAdminTypeEnum[],
  adminTypes: WithdrawalAdminTypeEnum[]
) => {
  return validTypes.some(adminType => adminTypes.includes(adminType));
};

export enum withdrawalStatesEnum {
  requested = "requested",
  created = "created",
  escalated = "escalated",
  staged = "staged",
  reviewed = "reviewed",
  pending = "pending",
  confirmed = "confirmed",
  canceled = "canceled",
  completed = "completed",
  failed = "failed",
}

export const isValidStateTransition = ({
  currentState,
  newState,
  adminTypes,
}: {
  currentState: withdrawalStatesEnum;
  newState: withdrawalStatesEnum;
  adminTypes: WithdrawalAdminTypeEnum[];
}) => {
  switch (currentState) {
    case withdrawalStatesEnum.created:
      return [
        withdrawalStatesEnum.created,
        withdrawalStatesEnum.requested,
        withdrawalStatesEnum.canceled,
        withdrawalStatesEnum.escalated,
        withdrawalStatesEnum.staged,
      ].includes(newState);
    case withdrawalStatesEnum.requested:
      return [
        withdrawalStatesEnum.created,
        withdrawalStatesEnum.requested,
        withdrawalStatesEnum.canceled,
        withdrawalStatesEnum.escalated,
      ].includes(newState);
    case withdrawalStatesEnum.escalated:
      return [
        withdrawalStatesEnum.created,
        withdrawalStatesEnum.requested,
        withdrawalStatesEnum.canceled,
        withdrawalStatesEnum.escalated,
        withdrawalStatesEnum.staged,
      ].includes(newState);
    case withdrawalStatesEnum.staged:
      if (
        isValidWithdrawalAdminType(
          [WithdrawalAdminTypeEnum.confirmer, WithdrawalAdminTypeEnum.manager],
          adminTypes
        )
      ) {
        return [
          withdrawalStatesEnum.created,
          withdrawalStatesEnum.requested,
          withdrawalStatesEnum.canceled,
          withdrawalStatesEnum.escalated,
          withdrawalStatesEnum.reviewed,
          withdrawalStatesEnum.staged,
        ].includes(newState);
      }
      return [
        withdrawalStatesEnum.created,
        withdrawalStatesEnum.requested,
        withdrawalStatesEnum.canceled,
        withdrawalStatesEnum.escalated,
        withdrawalStatesEnum.staged,
      ].includes(newState);
    case withdrawalStatesEnum.reviewed:
      if (
        isValidWithdrawalAdminType(
          [WithdrawalAdminTypeEnum.confirmer, WithdrawalAdminTypeEnum.manager],
          adminTypes
        )
      ) {
        return [
          withdrawalStatesEnum.created,
          withdrawalStatesEnum.requested,
          withdrawalStatesEnum.canceled,
          withdrawalStatesEnum.escalated,
          withdrawalStatesEnum.staged,
          withdrawalStatesEnum.confirmed,
          withdrawalStatesEnum.reviewed,
        ].includes(newState);
      }
      return [
        withdrawalStatesEnum.created,
        withdrawalStatesEnum.requested,
        withdrawalStatesEnum.canceled,
        withdrawalStatesEnum.escalated,
        withdrawalStatesEnum.staged,
        withdrawalStatesEnum.reviewed,
      ].includes(newState);
    case withdrawalStatesEnum.completed:
      if (isValidWithdrawalAdminType([WithdrawalAdminTypeEnum.manager], adminTypes)) {
        return [
          withdrawalStatesEnum.created,
          withdrawalStatesEnum.requested,
          withdrawalStatesEnum.canceled,
          withdrawalStatesEnum.escalated,
          withdrawalStatesEnum.staged,
          withdrawalStatesEnum.confirmed,
          withdrawalStatesEnum.completed,
          withdrawalStatesEnum.reviewed,
        ].includes(newState);
      }
      return [withdrawalStatesEnum.completed].includes(newState);
    case withdrawalStatesEnum.canceled:
      return [withdrawalStatesEnum.canceled].includes(newState);
    default:
      return false;
  }
};
