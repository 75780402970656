import React, { useState } from "react";

/* eslint-disable-next-line no-restricted-imports */
import { KeyboardArrowDown } from "@mui/icons-material";
import { Popover } from "@mui/material";
import { Button } from "@unchained/component-library";

import { useIsBuyBitcoinFeatureEnabled } from "Components/TradingDesk/hooks";
import { AcceptedMember, ChildRole } from "Specs/v1/getOrg/200";
import { usePopover } from "Utils/hooks";

import { displayRoleName, ROLE_OPTIONS, tradingManagedRoles } from "../utils";
import styles from "./MemberActionsMenu.module.scss";

/**
 * This component displays a user role name, and, if permitted,
 * triggers a popover on click of that user role name, to allow for alteration of the user role */
export const MemberActionsMenu = ({
  member,
  changeUserRole,
  childRoles,
  disabled,
}: {
  member: AcceptedMember;
  changeUserRole: (uuid: string, managedRoles: string[]) => void;
  childRoles: ChildRole[];
  disabled: boolean;
}) => {
  const { triggerRef, isOpen, popoverRef, setOpen, setClosed } = usePopover();

  const { user } = member;

  const [userRoleDisplayName, setUserRoleDisplayName] = useState<string>(
    displayRoleName(user, "", childRoles)
  );

  const { isTradingFeatureAvailable } = useIsBuyBitcoinFeatureEnabled();

  // Called on click of a role in the menu, to switch member's role
  const setUserRole = (role: { label: string; value: string; managed: string[] }) => {
    const targetRoles = role.managed;
    if (role.value == "admin" && isTradingFeatureAvailable) {
      targetRoles.push(...tradingManagedRoles);
    }
    setUserRoleDisplayName(role.label);
    changeUserRole(user.uuid, targetRoles);
    setClosed();
  };

  const menuItems: React.ReactNode[] = ROLE_OPTIONS.map((role, index) => (
    <div key={`${role.label}-${index}`} className={styles.item} onClick={() => setUserRole(role)}>
      {role.label}
    </div>
  ));

  const Menu = () => (
    <div className={styles.menu} data-testid="MemberActionsMenu">
      {menuItems || null}
    </div>
  );

  // If there are any menu items, render them in a popver triggered by the user's role name.
  return (
    <>
      <Button
        variant="text"
        data-testid="role-menu-trigger"
        ref={triggerRef}
        disabled={disabled}
        onClick={setOpen}
        endIcon={<KeyboardArrowDown />}
      >
        {userRoleDisplayName}
      </Button>
      <Popover
        anchorOrigin={{ vertical: 30, horizontal: -10 }}
        open={isOpen}
        anchorEl={popoverRef}
        onClose={setClosed}
        aria-describedby={`member-actions-${user.uuid}`}
      >
        <Menu />
      </Popover>
    </>
  );
};
