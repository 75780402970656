import { Config200, GetPublicConfig200 } from "Specs/v2";

import { ApiType } from "../apiType";

export const ConfigsAPIFactory = (API: ApiType) => ({
  GetConfigData: async () => {
    return (await API.Get<Config200>(`/config`)).data;
  },
  GetPublicConfigData: async () => {
    return (await API.Get<GetPublicConfig200>(`/public-config`)).data;
  },
});
