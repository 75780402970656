import { AddressString } from "@unchained/component-library";
import QRCode from "qrcode.react";

import { Link } from "Components/Link";
import { ConfirmAddress } from "Components/Shared/wizard/ConfirmAddress";
import { VaultMinimumDepositBanner } from "Components/vaults/VaultMinimumDepositBanner";
import { bitcoinQRCodeURL } from "Utils/wallet";

import { SubsectionHeader } from "./SubsectionHeader";

export const DepositAddress = ({
  vaultUuid,
  address,
  suggestedMinimumDepositBtc,
  displayConfirmOnDevice,
}: {
  vaultUuid: string;
  address: string;
  suggestedMinimumDepositBtc: string;
  displayConfirmOnDevice: boolean;
}) => {
  const qrText = bitcoinQRCodeURL({
    address,
  });
  return (
    <div className="flex flex-col gap-4">
      <SubsectionHeader>Deposit address</SubsectionHeader>
      <VaultMinimumDepositBanner minimum={suggestedMinimumDepositBtc} />
      <div className="text-sm font-reg">
        You will receive a new address for each deposit.{" "}
        <Link
          to="https://help.unchained.com/how-do-i-deposit-bitcoin-to-my-vault-or-loan"
          target="_blank"
        >
          How do I deposit bitcoin?
        </Link>
      </div>
      <div className="flex flex-row justify-center">
        <QRCode size={148} value={qrText} level="L" />
      </div>
      <AddressString
        className="flex flex-row justify-center !text-gray-600"
        copyable
        copyOnClickProps={{ chipPosition: "inside-bottom-right" }}
        segmented
      >
        {address}
      </AddressString>
      <ConfirmAddress
        productUuid={vaultUuid}
        productType={"vault"}
        address={address}
        displayConfirmOnDevice={displayConfirmOnDevice}
        displayConfirmViaEmail={true}
      />
    </div>
  );
};
